import React, { Component } from 'react';
// import { NavLink } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react';
import { ToastContainer } from 'react-toastify';
// import isAuthenticate from './../modules/Secure/Secure'
import './Login.css'
import { Link, NavLink } from 'react-router-dom';
import * as IconlyPack from 'react-iconly';
import Header from '../Navs/Header';

export default class ModifierMotDePasse extends Component {
    constructor(props) {
        super(props)
        this.state = {
            
        }

    }



    render() {
        return (
            <div className="connexion-page">
            <Header />
                <div className="login-page-container">
                <section className="auth-page-content">
          <form>
            <div className="row auth-page-row">
              <div className="col-md-4 offset-md-4 auth-page-col">
                 
                <div className="row auth-form-row">
                  <h1 className="auth-form-title m-b-90">Modification de mot de passe</h1>
                  <div className="col-md-6 offset-md-3 m-b-20">
                      <p className="text-modifier-password">Créer un nouveau mot de passe</p>
                  </div>
                  <div className="col-md-12 auth-input-col m-b-20">
                    <div className="form-group auth-form-group position-relative">
                      <input  name="password" type="password" className="form-control auth-form-control pl-5" id="mail" placeholder="Entrer mot de passe" />
                    </div>
                  </div>
                  <div className="col-md-12 auth-input-col m-b-20">
                    <div className="form-group auth-form-group position-relative">
                      <input  name="password" type="password" className="form-control auth-form-control pl-5" id="mail" placeholder="Confirmer mot de passe" />
                    </div>
                  </div>
                  <div className="col-md-6 offset-md-3 d-flex justify-content-center auth-input-col m-b-5">
                        <NavLink to="/dashboard" className="btn btn-primary auth-submit-btn">ENVOYER</NavLink>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
         </div>

                {this.state.isSuccessLogin || this.state.isErrorLogin || this.state.isInvalidCredential ?
                    <ToastContainer /> : ''
                }
            </div>
        );
    }
}

