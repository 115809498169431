import React, { useContext, useEffect, useMemo } from "react";
import Header from "../../../Navs/Header";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import * as IconlyPack from "react-iconly";
import "./MonProfil.css";
import { Link, useHistory } from "react-router-dom";

import FeatherIcon from "feather-icons-react";
import Swal from "sweetalert2";
import DefaultAvatar from "../../../../images/others/default-profile-avatar.png";
import NavbarMobileV2 from "../../navbarMobileV2/navbarMobileV2";

import { DataContext } from "../../../../redux/GlobalState";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { result } from "lodash-es";
import ChangePassword from "./ChangePassword";
import User from "../../../Services/User";
import ChangePasswordModal from "./ChangePasswordModal";
import baseUrl from "../../../../Http/backend-base-url";
import { postData } from "../../../../utils/fetchData";
var MonProfil = () => {
  const { state, dispatch } = useContext(DataContext);
  const { auth } = state;
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);

  const history = useHistory();

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email("l'email n'est pas valide")
          .required("L'email est requis"),
        fullname: yup.string().required("Le nom est requis."),
        phone: yup.string().required("Le numéro de téléphone est requis."),
        adresse: yup.string().required("L'adresse est requise."),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    watch,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  useEffect(() => {
    if (auth?.user?.id) {
      register("fullname");
      setValue("fullname", auth?.user?.fullname);
      register("email");
      setValue("email", auth?.user?.email);
      register("phone");
      setValue("phone", auth?.user?.phone);
      register("adresse");
      setValue("adresse", auth?.user?.adresse);
    }
  }, [auth]);

  const editPrenom = (fullname) => {
    Swal.fire({
      title: "Modifer votre nom",
      input: "text",
      inputValue: fullname,
      inputLabel: "Nom complet",
      customClass: {
        inputLabel: "label-input pb-2",
      },
      inputPlaceholder: "Nom complet",
      inputAttributes: {
        autocapitalize: "off",
        autocorrect: "off",
      },
      confirmButtonText: "Valider",

      showLoaderOnConfirm: true,
      preConfirm: (value) => {
        if (!value) {
          Swal.showValidationMessage(`Le nom est requis.`);
        } else {
          var request = User.onEditUser(
            auth?.user?.id,
            { fullname: value },
            requestConfig
          );

          return request
            .then((resp) => {
              console.log("resp", resp);
              dispatch({
                type: "AUTH",
                payload: { token: auth?.token, user: resp },
              });
              return resp;
              // toast.success("Modification réussie!");
            })
            .catch((error) => {
              Swal.showValidationMessage(`Erreur: ${error}`);
            });
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "success",
          title: "Modification réussie!",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };

  const editAdresse = (adresse) => {
    Swal.fire({
      title: "Modifer votre adresse",
      input: "text",
      inputValue: adresse,
      inputLabel: "adresse",
      inputPlaceholder: "HLM Grand yoff",
      inputAttributes: {
        autocapitalize: "off",
        autocorrect: "off",
      },
      confirmButtonText: "Valider",

      showLoaderOnConfirm: true,
      preConfirm: (value) => {
        if (!value) {
          Swal.showValidationMessage(`L'adresse est requise.`);
        } else {
          var request = User.onEditUser(
            auth?.user?.id,
            { adresse: value },
            requestConfig
          );

          return request
            .then((resp) => {
              console.log("resp", resp);
              dispatch({
                type: "AUTH",
                payload: { token: auth?.token, user: resp },
              });
              return resp;
              // toast.success("Modification réussie!");
            })
            .catch((error) => {
              Swal.showValidationMessage(`Erreur: ${error}`);
            });
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "success",
          title: "Modification réussie!",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };
  const editEmail = (email) => {
    Swal.fire({
      title: "Modifer votre email",
      input: "email",
      inputValue: email,
      inputLabel: "email",
      inputPlaceholder: "Votre email",
      inputAttributes: {
        autocapitalize: "off",
        autocorrect: "off",
      },
      confirmButtonText: "Valider",

      showLoaderOnConfirm: true,
      preConfirm: (value) => {
        if (!value) {
          Swal.showValidationMessage(`L'email est requis.`);
        } else {
          var request = User.onEditUser(
            auth?.user?.id,
            { email: value },
            requestConfig
          );

          return request
            .then(async (resp) => {
              console.log("resp", resp);
              window.sessionStorage.setItem("userToken", resp?.token);
              dispatch({
                type: "AUTH",
                payload: { token: resp?.token, user: resp?.data },
              });
              return resp;
              // toast.success("Modification réussie!");
            })
            .catch((error) => {
              if (
                error?.response?.data?.email?.length &&
                error?.response?.data?.email[0] ===
                  "user with this email already exists."
              ) {
                Swal.showValidationMessage(
                  `Erreur: Cet email est déja associé à un compte`
                );
              } else {
                console.log("error", error?.response);
                Swal.showValidationMessage(`Erreur: ${error}`);
              }
            });
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "success",
          title: "Modification réussie!",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };
  const editTelephone = (phone) => {
    Swal.fire({
      title: "Modifer votre numéro de téléphone",
      input: "text",
      inputValue: phone,
      inputLabel: "téléphone",
      inputPlaceholder: "Votre numéro de téléphone",
      inputAttributes: {
        autocapitalize: "off",
        autocorrect: "off",
      },
      confirmButtonText: "Valider",

      showLoaderOnConfirm: true,
      preConfirm: (value) => {
        if (!value) {
          Swal.showValidationMessage(`Le numéro de téléphone est requis.`);
        } else {
          var request = User.onEditUser(
            auth?.user?.id,
            { phone: value },
            requestConfig
          );

          return request
            .then((resp) => {
              console.log("resp", resp);
              dispatch({
                type: "AUTH",
                payload: { token: auth?.token, user: resp },
              });
              return resp;
              // toast.success("Modification réussie!");
            })
            .catch((error) => {
              Swal.showValidationMessage(`Erreur: ${error}`);
            });
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "success",
          title: "Modification réussie!",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };

  const onSubmit = async (data, e) => {
    console.log("rrr", data);
    setIsSuccessfullySubmitted(true);
    var request = User.onEditUser(auth?.user?.id, data, requestConfig);

    await request
      .then((resp) => {
        // console.log("resp", resp);
        dispatch({
          type: "AUTH",
          payload: { token: auth?.token, user: resp },
        });
        // toast.success("Modification réussie!");
        Swal.fire({
          icon: "success",
          title: "Modification réussie!",
          showConfirmButton: false,
          timer: 2000,
        });
        setIsSuccessfullySubmitted(false);
      })
      .catch((error) => {
        console.error("error", error, error?.response);
        if (
          error?.response?.data?.email?.length &&
          error?.response?.data?.email[0] ===
            "user with this email already exists."
        ) {
          Swal.fire({
            icon: "error",
            title: "Cet email est déja associé à un compte.",
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (error?.response?.data?.status === 500) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée au serveur est survenue veuillez réessayer plus tard",
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (error?.response?.data?.status === 400) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée à la requête est survenue, veuillez contacter l'administrateur.",
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Veuillez vérifier votre connexion internet.",
            showConfirmButton: false,
            timer: 2000,
          });
        }

        setIsSuccessfullySubmitted(false);
      });
  };

  if (window.sessionStorage.getItem("profil") !== "true") {
    history.goBack();
    return null;
  }
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />
        <div className="row">
          {/* Navbar Mobile */}
          <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
            <NavbarMobileV2 />
          </div>
        </div>
        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 soinsy mb-3">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Mon profil</h1>
                  </div>
                </div>
                <div className="parent-table-container">
                  <div>
                    <div>
                      <div className="d-flex align-items-center flex-column py-3">
                        <div className="py-3">
                          <img
                            src={
                              auth?.user?.avatar &&
                              !auth?.user?.avatar?.includes("default.png")
                                ? baseUrl + auth?.user?.avatar
                                : DefaultAvatar
                            }
                            alt="Image profil utilisateur"
                            className="img-profil-app"
                          />
                        </div>
                        <div>
                          <h2 className="nom-profil-user pb-3">
                            {auth?.user?.fullname}
                          </h2>
                          <p className="text-profil-user">Admin</p>
                        </div>
                      </div>
                      <div className="py-3">
                        <p className="label-profil-user">
                          Informations personnelles
                        </p>
                      </div>
                      <form
                        className="row d-flex align-items-center mb-3"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <div className="col-xl-4 col-lg-4 col-md-4 py-3">
                          <div className="form-group position-relative">
                            <label className="label-input pb-2">
                              Nom complet
                            </label>
                            <input
                              readOnly
                              type="text"
                              name="fullname"
                              {...register("fullname")}
                              className="bloc-div form-control"
                              placeholder="Nom Complet"
                            />
                            <span className="icon-edit-profil">
                              <IconlyPack.Edit
                                set="light"
                                primaryColor="#AEAEAE"
                                onClick={() => editPrenom(auth?.user?.fullname)}
                              />
                            </span>
                          </div>
                          {formState?.errors && formState?.errors?.fullname && (
                            <div className="alert alert-danger epm-alert-danger closer m-t-10">
                              {formState?.errors?.fullname?.message}
                            </div>
                          )}
                        </div>
                        {/* <div className="col-xl-4 col-lg-4 col-md-4 py-3">
                          <div className="form-group">
                            <label className="label-input pb-2">Prénom</label>
                            <input
                            readOnly
                              type="text"
                              className="bloc-div form-control"
                              placeholder="Prénom"
                            />
                            <span className="icon-edit-profil">
                              <IconlyPack.Edit
                                set="light"
                                primaryColor="#AEAEAE"
                                // onClick={() => editPrenom()}
                              />
                            </span>
                          </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 py-3">
                          <div className="form-group">
                            <label className="label-input pb-2">
                              Date de naissance
                            </label>
                            <input
                            readOnly
                              type="text"
                              className="bloc-div form-control"
                              placeholder="Date de naissance"
                            />
                            <span className="icon-edit-profil">
                              <IconlyPack.Edit
                                set="light"
                                primaryColor="#AEAEAE"
                                // onClick={() => editDateNaissance()}
                              />
                            </span>
                          </div>
                        </div> */}
                        {/* <div className="col-xl-4 col-lg-4 col-md-4 py-3">
                          <div className="form-group">
                            <label className="label-input pb-2">
                              Lieu de naissance
                            </label>
                            <input
                            readOnly
                              type="text"
                              className="bloc-div form-control"
                              placeholder="Lieu de naissance"
                            />
                            <span className="icon-edit-profil">
                              <IconlyPack.Edit
                                set="light"
                                primaryColor="#AEAEAE"
                                // onClick={() => editLieuNaissance()}
                              />
                            </span>
                          </div>
                        </div> */}
                        <div className="col-xl-4 col-lg-4 col-md-4 py-3">
                          <div className="form-group">
                            <label className="label-input pb-2">Adresse</label>
                            <input
                              readOnly
                              type="text"
                              name="adresse"
                              {...register("adresse")}
                              className="bloc-div form-control"
                              placeholder="Adresse"
                            />
                            <span className="icon-edit-profil">
                              <IconlyPack.Edit
                                set="light"
                                primaryColor="#AEAEAE"
                                onClick={() => editAdresse(auth?.user?.adresse)}
                              />
                            </span>
                          </div>
                          {formState?.errors && formState?.errors?.adresse && (
                            <div className="alert alert-danger epm-alert-danger closer m-t-10">
                              {formState?.errors?.adresse?.message}
                            </div>
                          )}
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 py-3">
                          <div className="form-group">
                            <label className="label-input pb-2">Email</label>
                            <input
                              readOnly
                              type="email"
                              name="email"
                              {...register("email")}
                              className="bloc-div form-control"
                              placeholder="Email"
                            />
                            <span className="icon-edit-profil">
                              <IconlyPack.Edit
                                set="light"
                                primaryColor="#AEAEAE"
                                onClick={() => editEmail(auth?.user?.email)}
                              />
                            </span>
                          </div>
                          {formState?.errors && formState?.errors?.email && (
                            <div className="alert alert-danger epm-alert-danger closer m-t-10">
                              {formState?.errors?.email?.message}
                            </div>
                          )}
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 py-3">
                          <div className="form-group">
                            <label className="label-input pb-2">
                              Numéro de téléphone
                            </label>
                            <input
                              readOnly
                              type="text"
                              name="phone"
                              // readOnly
                              {...register("phone")}
                              className="bloc-div form-control"
                              placeholder="Numéro de téléphone"
                            />
                            <span className="icon-edit-profil">
                              <IconlyPack.Edit
                                set="light"
                                primaryColor="#AEAEAE"
                                onClick={() => editTelephone(auth?.user?.phone)}
                              />
                            </span>
                          </div>
                          {formState?.errors && formState?.errors?.phone && (
                            <div className="alert alert-danger epm-alert-danger closer m-t-10">
                              {formState?.errors?.phone?.message}
                            </div>
                          )}
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 py-3">
                          <div className="form-group">
                            <label className="label-input pb-2">
                              Mot de passe
                            </label>
                            <input
                              readOnly
                              defaultValue="password"
                              type="password"
                              className="bloc-div form-control"
                              placeholder="Mot de passe"
                            />
                            <span className="icon-edit-profil">
                              <IconlyPack.Edit
                                set="light"
                                primaryColor="#AEAEAE"
                                data-toggle="modal"
                                data-target=".change-password-modal"
                                // onClick={() => editPassword()}
                              />
                            </span>
                          </div>
                        </div>
                        {/* <div className="col-md-3 offset-md-9 crud-form-action-btn-container d-flex justify-content-end">
                          {isSuccessfullySubmitted && (
                            <button className="btn-theme-plus" disabled>
                              En cours &nbsp;
                              <i className="fas fa-spin fa-spinner"></i>
                            </button>
                          )}
                          {!isSuccessfullySubmitted && (
                            <button className="btn-theme-plus">MODIFIER</button>
                          )}
                        </div> */}
                      </form>
                    </div>
                  </div>
                  {/* Modal */}
                  <div
                    class="modal fade easypm-modal change-password-modal"
                    id="PasswordItem"
                    tabindex="-1"
                    aria-labelledby="PasswordItemLabel"
                    aria-hidden="true"
                  >
                    <ChangePasswordModal />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonProfil;
