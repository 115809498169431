import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { DataContext } from "../../../../../redux/GlobalState";
import { getData } from "../../../../../utils/fetchData";
import Transversale from "../../../../Services/Transversale";

function useTransversaleForm(item, modalId) {
  const { dispatch } = useContext(DataContext);

  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required("Le nom est requis"),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    watch,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: item?.name ?? "",
    },
  });

  const onSubmitForm = async (formValues, e) => {
    let token = window.sessionStorage.getItem("userToken");
    setIsSuccessfullySubmitted(true);

    // formValues["password"] = "passerty";
    // formValues["user_type"] = "admin";

    var request;
    if (item) request = Transversale.onEdit(item.id, formValues, requestConfig);
    else request = Transversale.onPost(formValues, requestConfig);
    await request
      .then(async (resp) => {
        console.log("resp user add", resp);
        await getData("transversale", token)
          .then((response) => {
            // console.log("adminuser", response);
            dispatch({
              type: "TRANSVERSALES",
              payload: {
                isLoading: false,
                data: response.results.sort((a, b) => b?.id - a?.id),
              },
            });
            if (item) {
              Swal.fire({
                icon: "success",
                title: "Modification réussi!",
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              Swal.fire({
                icon: "success",
                title: "Ajout réussie!",
                showConfirmButton: false,
                timer: 1500,
              });
              e.target.reset();
              reset();
            }

            setIsSuccessfullySubmitted(false);

            window.$(`#${modalId}`).modal("hide");
            setIsSuccess(true);

            setTimeout(() => {
              setIsSuccess(false);
            }, 5000);
          })
          .catch((error) => {
            console.log("error admins", error?.response);
          });
      })
      .catch((error) => {
        console.error("error", error?.response);
        Swal.fire({
          icon: "error",
          title:
            "Oups! une erreur liée à la requête est survenue, veuillez contacter l'administrateur.",
          showConfirmButton: false,
          timer: 2000,
        });

        setIsSuccessfullySubmitted(false);
      });
  };
  return {
    register,
    errors,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    watch,
    reset,
    setValue,
    onSubmit: handleSubmit(onSubmitForm),
    isSuccess,
  };
}

export default useTransversaleForm;
