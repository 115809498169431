import AddIcon from "@mui/icons-material/Add";
import React from "react";
import TransversaleModal from "../../../Modals/TransversaleModal";
import Header from "../../../Navs/Header";
import TransversaleTable from "../../../Tables/Admins/TransversaleTable";
import NavbarMobileV2 from "../../navbarMobileV2/navbarMobileV2";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import "./Parametre.css";

var GestionTransversales = () => {
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />
        <div className="row">
          {/* Navbar Mobile */}
          <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
            <NavbarMobileV2 />
          </div>
        </div>
        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 soinsy">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Liste des transversales</h1>
                  </div>
                </div>
                <div className="parent-table-container">
                  <div>
                    <div className="easypm-tabs-item">
                      <div className="row adding-row">
                        <div className="col-md-2 offset-md-10 adding-col-offseting">
                          <button
                            className="btn"
                            data-toggle="modal"
                            data-target="#AddTransversale"
                          >
                            <AddIcon className="icon-add-user" />
                            <span className="text-btn-add pl-2">
                              Ajouter une transversale
                            </span>
                          </button>
                        </div>
                      </div>
                      <TransversaleTable />
                    </div>

                    <div
                      class="modal fade easypm-modal"
                      id="AddTransversale"
                      tabIndex="-1"
                      data-backdrop="static"
                      aria-labelledby="AddTransversaleLabel"
                      aria-hidden="true"
                    >
                      <TransversaleModal modalId={"AddTransversale"} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionTransversales;
