import React from 'react'

var EmptyHeader = () => {
    return(
        <div className="container-fluid  empty-header-speero">
            <div className="header-empty-login">
                <div className="empty-header">
                    <div className="logo-item">
                        <span className="logo-firsty">SPEERO</span>&nbsp;
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmptyHeader;