import React, { useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
// import api from './../http/global-vars'
import { ToastContainer } from "react-toastify";
// import axios from 'axios'
import Footer from "../Backend/Footer/Footer";
import RequestPassword from "../Services/RequestPassword";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router";
import Swal from "sweetalert2";

function ResetPassword() {
  const [isInProgress, setIsInProgress] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const {
    register,
    handleSubmit,
    formState,
    clearErrors,
    setValue,
    watch,
    reset,
  } = useForm();

  useEffect(() => {
    if (location.email) {
      console.log("location", location);
      register("email");
      setValue("email", location.email);
    }
  }, [location]);

  React.useEffect(() => {
    //register('new_password')
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 5000);
    }
  }, [formState, formState?.errors, clearErrors, register]);

  const onReset = async (data, e) => {
    setIsInProgress(true);
    console.log("data", data);
    var passwordReset = RequestPassword.postResetPassword(data);

    await passwordReset
      .then((res) => {
        console.log(res);
        setIsInProgress(false);
        Swal.fire({
          icon: "success",
          title: "Votre mot de passe a été modifié avec succès",
          showConfirmButton: false,
          //   allowOutsideClick: false,
          timer: 1000,
        }).then((result) => {
          history.push("/");
          e.target.reset();
          reset();
        });
        //   toast.success(
        //     "Votre mot de passe a été modifié avec succès. Vous serez redirigé vers la page de connexion dans 5 secondes.",
        //     {
        //       duration: 10000,
        //       position: "top-center",
        //     }
        //   );
        // window.$(".open-reset-password-modal").modal("hide");
      })
      .catch((e) => {
        setIsInProgress(false);
        console.log(e?.response, e);
        if (e?.response?.data?.status === "failure") {
          //   toast.error(error?.response?.data);
          Swal.fire({
            icon: "error",
            title: e?.response?.data?.message,
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          // toast.error(
          //   "Oupsss! Une erreur est survenue. Veuillez réessayer plus tard."
          // );
          Swal.fire({
            icon: "error",
            title:
              "Oupsss! Une erreur est survenue. Veuillez réessayer plus tard.",
            showConfirmButton: false,
            timer: 3000,
          });
        }
      });
  };
  return (
    <div className="reset-password-component">
      <div className="p-t-100">
        <div className="dis-flex">
          <div className="col-xl-5 col-lg-6 col-md-7 col-sm-10 ml-auto mr-auto">
            <div className="card-connexion">
              <p className="titre-connexion">Modifiez votre mot de passe </p>
              <form id="reset-form" onSubmit={handleSubmit(onReset)}>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text span-input-icon"
                      id="basic-addon1"
                    >
                      <FeatherIcon className="icon-input" icon="hash" />
                    </span>
                  </div>
                  <input
                    autoComplete="off"
                    name="code"
                    id="code"
                    {...register("code", {
                      required: "le code est requis",
                    })}
                    type="text"
                    className="form-control input-with-icon"
                    placeholder="Le code reçu par email"
                  />
                  {formState.errors.code && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {" "}
                      {formState.errors.code.message}{" "}
                    </div>
                  )}
                </div>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text span-input-icon"
                      id="basic-addon1"
                    >
                      <FeatherIcon className="icon-input" icon="mail" />
                    </span>
                  </div>
                  <input
                    autoComplete="off"
                    name="email"
                    id="email"
                    {...register("email", {
                      required: {
                        value: true,
                        message: "Ce champ est obligatoire.",
                      },
                      pattern: {
                        value: new RegExp(
                          // /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
                          /^(?<user>[a-zA-Z0-9._%+'-]+)@(?<domain>[a-zA-Z0-9.'-]+\.[a-zA-Z]{2,4})$/
                        ),
                        message: "Votre email n'est pas valide",
                      },
                    })}
                    type="email"
                    className="form-control input-with-icon"
                    placeholder="Adresse mail"
                  />
                  {formState.errors.email && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {" "}
                      {formState.errors.email.message}{" "}
                    </div>
                  )}
                </div>

                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text span-input-icon"
                      id="basic-addon1"
                    >
                      <FeatherIcon className="icon-input" icon="lock" />
                    </span>
                  </div>
                  <input
                    autoComplete="new-password"
                    name="new_password"
                    id="new_password"
                    {...register("new_password", {
                      required: "Le nouveau mot de passe est requis.",
                    })}
                    type="password"
                    className="form-control input-with-icon"
                    placeholder="Votre nouveau mot de passe"
                  />
                  {formState.errors.new_password && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {" "}
                      {formState.errors.new_password.message}{" "}
                    </div>
                  )}
                </div>

                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text span-input-icon"
                      id="basic-addon1"
                    >
                      <FeatherIcon className="icon-input" icon="lock" />
                    </span>
                  </div>
                  <input
                    autoComplete="new-password"
                    name="new_password_confirm"
                    id="new_password_confirm"
                    type="password"
                    {...register("new_password_confirm", {
                      required: {
                        value: true,
                        message: "Ce champ est obligatoire.",
                      },
                      validate: (value) => {
                        return (
                          value === watch("new_password") ||
                          "Veuillez entrer deux mots de passe identiques."
                        );
                      },
                    })}
                    className="form-control input-with-icon"
                    placeholder="Confirmez votre nouveau mot de passe"
                  />
                  {formState.errors.new_password_confirm && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {" "}
                      {formState.errors.new_password_confirm.message}{" "}
                    </div>
                  )}
                </div>

                {isInProgress === false ? (
                  <button type="submit" className="btn-connexion">
                    Envoyer
                  </button>
                ) : (
                  <button className="btn-connexion in-progress-btn">
                    Envoi en cours &nbsp;
                    <i className="fas fa-spin fa-spinner"></i>
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>

        <div className="">
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
