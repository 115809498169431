import React from "react";
import ImgAdmin from "../../images/others/default-profile-avatar.png";

function DetailsAdmin({ admin }) {
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form">
              <div className="row position-relative">
                <div className="col-md-4 crud-col-item offset-md-4  d-flex justify-content-center">
                  <div className="form-group align-items-center bloc-prestataire-profil position-relative text-center">
                    <img
                      src={ImgAdmin}
                      alt="img prestataire"
                      className="img-prestataire"
                    />

                    <h3 className="profil-nom">{admin?.fullname}</h3>
                  </div>
                </div>
                <div className="col-md-2  position-relative">
                  <div className="bloc-profil-right"></div>
                </div>
              </div>
              <div className="pb-3">
                <h4 className="title-info-profil">Informations</h4>
              </div>
              <div className="row position-relative">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group group-text">
                    <p className="label-input pb-2">Prénom et Nom</p>
                    <p className="route-list-value">{admin?.fullname}</p>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group group-text">
                    <p className="label-input pb-2">Email</p>
                    <p className="route-list-value">{admin?.email}</p>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group group-text">
                    <p className="label-input pb-2">Adresse</p>
                    <p className="route-list-value">{admin?.adresse}</p>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group group-text">
                    <p className="label-input pb-2">Téléphone</p>
                    <p className="route-list-value">{admin?.phone}</p>
                  </div>
                </div>
                <div className="col-md-12 crud-col-item">
                  <div className="form-group crud-form-group group-text">
                    <p className="label-input pb-2">Permissions</p>
                    <div className="row">
                      {/* {admin.dashboard ? ( */}
                      <div className="col-md-4 route-list-value">
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            name="dashboard"
                            checked={!!admin.dashboard}
                          />
                          <label
                            className="custom-control-label "
                            for="customControlValidation1"
                            style={{ fontSize: "16px" }}
                          >
                            Accés au tableau de bord
                          </label>
                        </div>
                      </div>

                      <div className="col-md-4 route-list-value">
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            checked={!!admin.route}
                          />
                          <label
                            className="custom-control-label"
                            for="customControlValidation2"
                            style={{ fontSize: "16px" }}
                          >
                            Accés aux points d'intérêts
                          </label>
                        </div>
                      </div>

                      <div className="col-md-4 route-list-value">
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            name="prestataire"
                            checked={!!admin.direction}
                          />
                          <label
                            className="custom-control-label"
                            for="customControlValidation3"
                            style={{ fontSize: "16px" }}
                          >
                            Accés aux directions
                          </label>
                        </div>
                      </div>

                      <div className="col-md-4 route-list-value">
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            checked={!!admin.evenement}
                          />
                          <label
                            className="custom-control-label"
                            for="customControlValidation4"
                            style={{ fontSize: "16px" }}
                          >
                            Accés aux événements
                          </label>
                        </div>
                      </div>

                      <div className="col-md-4 route-list-value">
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            checked={!!admin.banner}
                          />
                          <label
                            className="custom-control-label"
                            style={{ fontSize: "16px" }}
                          >
                            Accés aux bannières
                          </label>
                        </div>
                      </div>

                      <div className="col-md-4 route-list-value">
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            checked={!!admin.list_users}
                          />
                          <label
                            className="custom-control-label"
                            style={{ fontSize: "16px" }}
                          >
                            Accés aux utilisateurs
                          </label>
                        </div>
                      </div>

                      <div className="col-md-4 route-list-value">
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            checked={!!admin.list_admins}
                          />
                          <label
                            className="custom-control-label"
                            style={{ fontSize: "16px" }}
                          >
                            Accés aux administrateurs
                          </label>
                        </div>
                      </div>

                      <div className="col-md-4 route-list-value">
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            checked={!!admin.contact}
                          />
                          <label
                            className="custom-control-label"
                            style={{ fontSize: "16px" }}
                          >
                            Accés aux messages
                          </label>
                        </div>
                      </div>

                      <div className="col-md-4 route-list-value">
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            checked={!!admin.profil}
                          />
                          <label
                            className="custom-control-label"
                            style={{ fontSize: "16px" }}
                          >
                            Accés à son profil
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsAdmin;
