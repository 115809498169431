import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import "./navbarContent.css";
// import FeatherIcon from 'feather-icons-react';
import * as IconlyPack from "react-iconly";
import { DataContext } from "../../../redux/GlobalState";

function NavbarContent() {
  const { state } = useContext(DataContext);
  const { auth } = state;
  return (
    <div className="hoverable">
      <ul className="nav nav-items-custom flex-column">
        {(window.sessionStorage.getItem("dashboard") === "true" ||
          auth?.user?.dashboard) && (
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/dashboard"
              activeClassName="actived"
            >
              <IconlyPack.Category
                set="bold"
                primaryColor="white"
                className="icon-navbar-vertical"
              />
              <span className="hiddenable-navlink-label">Dashboard</span>
            </NavLink>
          </li>
        )}

        {(window.sessionStorage.getItem("route") === "true" ||
          auth?.user?.route) && (
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/gestion-des-routes"
              activeClassName="actived"
            >
              <IconlyPack.Location
                set="light"
                primaryColor="white"
                className="icon-navbar-vertical"
              />
              <span className="hiddenable-navlink-label">Point d'interet</span>
            </NavLink>
          </li>
        )}

        {(window.sessionStorage.getItem("direction") === "true" ||
          auth?.user?.direction) && (
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/gestion-des-directions"
              activeClassName="actived"
            >
              <IconlyPack.Discovery
                set="light"
                primaryColor="white"
                className="icon-navbar-vertical"
              />
              <span className="hiddenable-navlink-label">Directions</span>
            </NavLink>
          </li>
        )}
        <li className="nav-item nav-item-vertical-custom">
          <NavLink
            className="nav-link nav-link-vertival-custom"
            to="/gestion-transversales"
            activeClassName="actived"
          >
            <IconlyPack.Location
              set="light"
              primaryColor="white"
              className="icon-navbar-vertical"
            />
            <span className="hiddenable-navlink-label">Transversales</span>
          </NavLink>
        </li>

        {(window.sessionStorage.getItem("evenement") === "true" ||
          auth?.user?.evenement) && (
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/gestion-des-evenement"
              activeClassName="actived"
            >
              <IconlyPack.Calendar
                set="light"
                primaryColor="white"
                className="icon-navbar-vertical"
              />
              <span className="hiddenable-navlink-label">Événements</span>
            </NavLink>
          </li>
        )}

        {/* {(window.sessionStorage.getItem("banner") === "true" ||
          auth?.user?.banner) && (
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/gestion-des-banniers"
              activeClassName="actived"
            >
              <IconlyPack.Image
                set="light"
                primaryColor="white"
                className="icon-navbar-vertical"
              />
              <span className="hiddenable-navlink-label">Bannières</span>
            </NavLink>
          </li>
        )} */}

        {(window.sessionStorage.getItem("list_users") === "true" ||
          auth?.user?.list_users) && (
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/gestion-des-utilisateurs"
              activeClassName="actived"
            >
              <IconlyPack.People
                set="light"
                primaryColor="white"
                className="icon-navbar-vertical"
              />
              <span className="hiddenable-navlink-label">
                Liste des utilisateurs
              </span>
            </NavLink>
          </li>
        )}

        {(window.sessionStorage.getItem("list_admins") === "true" ||
          auth?.user?.list_admins) && (
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/parametre"
              activeClassName="actived"
            >
              <IconlyPack.Setting
                set="light"
                primaryColor="white"
                className="icon-navbar-vertical"
              />
              <span className="hiddenable-navlink-label">Paramètre</span>
            </NavLink>
          </li>
        )}

        {(window.sessionStorage.getItem("notif") === "true" ||
          auth?.user?.notif) && (
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/gestion-marketing"
              activeClassName="actived"
            >
              <IconlyPack.Message
                set="light"
                primaryColor="white"
                className="icon-navbar-vertical"
              />
              <span className="hiddenable-navlink-label">Notifications</span>
            </NavLink>
          </li>
        )}

        {(window.sessionStorage.getItem("contact") === "true" ||
          auth?.user?.contact) && (
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/gestion-des-contacts"
              activeClassName="actived"
            >
              <IconlyPack.InfoSquare
                set="light"
                primaryColor="white"
                className="icon-navbar-vertical"
              />
              <span className="hiddenable-navlink-label">Contacts</span>
            </NavLink>
          </li>
        )}

        {(window.sessionStorage.getItem("profil") === "true" ||
          auth?.user?.profil) && (
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/profil"
              activeClassName="actived"
            >
              <IconlyPack.User
                set="light"
                primaryColor="white"
                className="icon-navbar-vertical"
              />
              <span className="hiddenable-navlink-label">Mon profil</span>
            </NavLink>
          </li>
        )}
      </ul>
    </div>
  );
}

export default NavbarContent;
