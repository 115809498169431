import React, { useContext, useEffect, useState } from "react";
import * as IconlyPack from "react-iconly";
import "./GestionBanner.css";
import Header from "../../../Navs/Header";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import NavbarMobileV2 from "../../navbarMobileV2/navbarMobileV2";
import { DataContext } from "../../../../redux/GlobalState";
import { Message } from "rsuite";
import baseUrl from "../../../../Http/backend-base-url";
import BannerSkeleton from "./BannerSkeleton";
import PaginationComponent from "../../../Modules/Pagination/Pagination";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit";
import FeatherIcon from "feather-icons-react";
import { NavLink, useHistory } from "react-router-dom";
import Banner from "../../../Services/Banner";
import { getData } from "../../../../utils/fetchData";
import Swal from "sweetalert2";

function GestionBanner() {
  const { state, dispatch } = useContext(DataContext);
  const { banners } = state;
  const [per_page, setPer_page] = useState(4);
  const history = useHistory();
  //   const [searchTerm, setSearchTerm] = React.useState('')
  //   const [searchResults, setSearchResults] = React.useState([])
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };
  const [current_page, setCurrent_page] = useState(1);
  const [total_data, setTotal_data] = useState(1);
  const [currentList, setCurrentList] = useState([]);
  let token = window.sessionStorage.getItem("userToken");

  const onDelete = (id) => {
    Swal.fire({
      title: "Êtes vous sûr de vouloir supprimer cette bannière ?",
      //   text: 'You will not be able to recover this imaginary file!',
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        var deleteRequest = Banner.onDeleteBanner(id, requestConfig);
        return deleteRequest
          .then((response) => {
            getData(`banner/`, token).then((res) => {
              // console.log('banners', res)
              dispatch({
                type: "LIST_BANNERS",
                payload: {
                  isLoading: false,
                  data: res.results.sort((a, b) => b?.id - a?.id),
                },
              });
            });
          })
          .catch((error) => {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "success",
          title: "Bannière supprimée avec succès.",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };

  useEffect(() => {
    if (!banners.isLoading) {
      formatData(banners.data);
    }
  }, [banners, current_page]);

  const formatData = (Array) => {
    setTotal_data(Array.length);
    const indexOfLastPost = current_page * per_page;
    const indexOfFirstPage = indexOfLastPost - per_page;

    const currentList = Array.slice(indexOfFirstPage, indexOfLastPost);
    setCurrentList(currentList);
  };

  const handleClick = (number) => {
    setCurrent_page(number);
  };

  if (window.sessionStorage.getItem("banner") !== "true") {
    history.goBack();
    return null;
  }
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />
        <div className="row">
          {/* Navbar Mobile */}
          <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
            <NavbarMobileV2 />
          </div>
        </div>
        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 soinsy">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Bannières</h1>
                  </div>
                </div>
                <div className="parent-table-container">
                  <div className="py-3 text-center">
                    <div className="add-btn-container">
                      <NavLink
                        to="ajout-banniere"
                        style={{ textDecoration: "none" }}
                      >
                        <span>
                          <IconlyPack.Plus
                            set="light"
                            className="icon-type-prestation linkable"
                          />
                        </span>
                        <div>
                          <span className="text-category linkable">
                            Ajouter une bannière
                          </span>
                        </div>
                      </NavLink>
                    </div>
                  </div>
                  <div className="py-4">
                    <span className="sous-titre-categorie">
                      Liste des bannières
                    </span>
                  </div>
                  {banners.isLoading && <BannerSkeleton />}
                  {!banners.isLoading &&
                    (currentList?.length ? (
                      currentList.map((item) => (
                        <div className="row pb-3" key={item?.id}>
                          <div className="col-xl-8 col-lg-8 col-md-10 pb-3">
                            <div className="shadow-category p-3">
                              <div className="bloc-img-prestation bloc-category-inline">
                                <img
                                  src={`${baseUrl}${item?.image}`}
                                  alt={`${item.id}`}
                                  className="type_presta_image"
                                  style={{
                                    width: "125px",
                                    height: "115px",
                                  }}
                                />
                              </div>
                              <div className="bloc-category-inline">
                                <div className="bloc-liste pl-3">
                                  <span className="titre-category-prestation pb-4">
                                    {item?.nom}
                                  </span>
                                </div>
                                <div className="sub-categories-container pl-3">
                                  {item?.statut === "publie" && (
                                    <span className="sub-category-item easypm-badge-success">
                                      Publiée
                                    </span>
                                  )}

                                  {item?.statut === "non_publie" && (
                                    <span className="sub-category-item easypm-badge-danger">
                                      Non publiée
                                    </span>
                                  )}
                                  <span className="sub-category-item ">
                                    Publiée du{" "}
                                    <b>
                                      {moment(item?.date_debut).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </b>{" "}
                                    au{" "}
                                    <b>
                                      {moment(item?.date_fin).format(
                                        "DD/MM/YYYY"
                                      )}{" "}
                                    </b>
                                  </span>
                                </div>
                                <div className="sub-categories-container pl-3">
                                  <span className="banner-position">
                                    Position: {item?.position}
                                  </span>
                                  <span className="banner-clic">
                                    Nombre de clic: {item?.nb_clic}
                                  </span>
                                  {item?.lien ? (
                                    <a
                                      href={item?.lien}
                                      target="blank"
                                      className="sub-category-item"
                                      style={{
                                        background: "#001455",
                                        textDecoration: "none",
                                      }}
                                    >
                                      Lien
                                    </a>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-2 col-lg-2 col-md-4 pt-2 pb-3">
                            <div className="d-flex">
                              <NavLink
                                className="linkable"
                                to={{
                                  pathname: "/modifier-banniere",
                                  state: { banner: item },
                                }}
                                title="Modifier"
                              >
                                <span className="bloc-icon m-2">
                                  <EditIcon className="" />
                                </span>
                              </NavLink>
                              <div
                                className="linkable"
                                title="Supprimer"
                                onClick={() => onDelete(item.id)}
                              >
                                <span className="bloc-icon m-2">
                                  <FeatherIcon className="" icon="trash-2" />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div>
                        <div className="gfa-rowy mt-5 mb-5">
                          <div className="alert alert-warning gfa-warning">
                            Aucune bannière disponible pour le moment.
                          </div>
                        </div>
                      </div>
                    ))}
                  {!banners.isLoading && banners.data?.length ? (
                    <PaginationComponent
                      per_page={per_page}
                      current_page={current_page}
                      total_data={total_data}
                      handleClick={handleClick}
                    />
                  ) : null}
                  {/* <GestionEvenementTable /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GestionBanner;
