import React, { useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import { getData } from "../../../../../utils/fetchData";
import { DataContext } from "../../../../../redux/GlobalState";
import Marketing from "../../../../Services/Marketing";

function useEditMarketingForm() {
  const { state, dispatch } = useContext(DataContext);
  const { auth } = state;
  const history = useHistory();
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        titre: yup.string().required("Le titre est requis"),
        //   image: yup.mixed().required("L'image est requise."),
        message: yup.string().required("Le message est requis."),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    watch,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmitForm = async (formValues, e) => {
    let token = window.sessionStorage.getItem("userToken");
    setIsSuccessfullySubmitted(true);

    var fd = new FormData();
    fd.append("titre", formValues?.titre);
    fd.append("message", formValues?.message);
    fd.append("admin", auth?.user?.id);

    if (formValues?.image) {
      fd.append("image", formValues?.image);
    }
    var request = Marketing.onEditMarketing(formValues?.id, fd, requestConfig);
    await request
      .then(async (resp) => {
        console.log("resp marketing add", resp);
        await getData("marketing", token)
          .then((response) => {
            // console.log("response banners", response);
            dispatch({
              type: "LIST_MARKETING",
              payload: {
                isLoading: false,
                data: response.results.sort((a, b) => b?.id - a?.id),
              },
            });
            Swal.fire({
              icon: "success",
              title: "Modification réussie!",
              showConfirmButton: false,
              timer: 1500,
            });
            setIsSuccessfullySubmitted(false);
            e.target.reset();
            reset();
            setTimeout(() => {
              history.push("/gestion-marketing");
            }, 1500);
          })
          .catch((error) => {
            console.log("error banners", error?.response);
          });
      })
      .catch((error) => {
        if (error?.response?.status === 500) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée au serveur est survenue veuillez réessayer plus tard",
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (error?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée à la requête est survenue, veuillez contacter l'administrateur.",
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur est survenue, veuillez contacter réessayer plus tard.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
        setIsSuccessfullySubmitted(false);
      });
  };
  return {
    register,
    errors,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    watch,
    reset,
    setValue,
    onSubmit: handleSubmit(onSubmitForm),
  };
}

export default useEditMarketingForm;
