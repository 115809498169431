import React, { useContext, useEffect } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import GestionEvenementTableData from "./GestionEvenementTableData";
import GestionEvenementTableSkeleton from "./GestionEvenementTableSkeleton";
import Profil from "../../../images/profil.png";

import * as IconlyPack from "react-iconly";
import { Link } from "react-router-dom";
import { DataContext } from "../../../redux/GlobalState";
import moment from "moment";
import Swal from "sweetalert2";
import { deleteData, getData } from "../../../utils/fetchData";
var GestionEvenementTable = () => {
  const { state, dispatch } = useContext(DataContext);
  const { events } = state;
  let token = window.sessionStorage.getItem("userToken");
  //   var [isPageLoading, setIsPageLoading] = React.useState(true);
  // eslint-disable-next-line
  //   var [evenement, setEvenement] = React.useState(GestionEvenementTableData);

  //   useEffect(() => {
  //     setTimeout(() => {
  //       setIsPageLoading(false);
  //     }, 5000);
  //   }, [evenement]);

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };
  const selectRowProp = {
    mode: "checkbox",
  };

  const onDelete = (id) => {
    Swal.fire({
      title: "Êtes vous sûr de vouloir Supprimer cet événement?",
      icon: "warning",
      //   customClass: {
      //     confirmButton: "btn-theme-plus",
      //     closeButton: "btn btn-danger",
      //   },
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return deleteData(`event/${id}/`, token)
          .then((response) => {
            console.log("res", response);
            getData("event", token)
              .then((response) => {
                // console.log("response routes", response);
                dispatch({
                  type: "LIST_EVENTS",
                  payload: {
                    isLoading: false,
                    data: response.results.sort((a, b) => b?.id - a?.id),
                  },
                });
              })
              .catch((error) => {
                console.log("error events", error?.response);
              });
            return response;
          })
          .catch((error) => {
            console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("res", result);
        Swal.fire({
          icon: "success",
          title: "Evénement supprimé avec succés.",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };

  const profilFormatter = (cell, row) => {
    //  let beneficiaire = cell.beneficiaire
    return (
      <div className="nameFormatter">
        <img src={Profil} className="photo-assures" />
        {cell}
      </div>
    );
  };

  const dateFormater = (cell, row) => {
    return moment(cell).format("DD/MM/YYYY");
  };

  const routeFormater = (cell, row) => {
    return cell?.name;
  };

  const actionFormatter = (cell, row, id) => {
    return (
      <div className="table-actions-btn-container-assurance">
        <Link
          className="action-btn edit-btn"
          title="Traitement"
          to={{ pathname: `/details-evenement`, state: { event: row } }}
        >
          <IconlyPack.Show
            set="light"
            primaryColor="#27AE60"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target="#GenererFactureClientModal"
          />
        </Link>
        <button className="action-btn edit-btn" title="Details">
          <IconlyPack.Delete
            set="light"
            primaryColor="#EB5757"
            className="action-btn-icon action-edit-icon"
            onClick={() => onDelete(row?.id)}
          />
        </button>
      </div>
    );
  };
  const date = {
    date: "20/02/2021",
  };
  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {events?.isLoading && <GestionEvenementTableSkeleton />}
            {!events?.isLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={events?.data}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  selectRow={selectRowProp}
                  trClassName="table-listing-tr table-listing-tr-salaries-table"
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#356FC2",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#356FC2",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    hidden={true}
                    dataFormat={(cell, row) => profilFormatter(cell, row)}
                  >
                    Profil
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#356FC2",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="125"
                    dataAlign="center"
                    dataField="created_at"
                    dataFormat={dateFormater}
                    filter={{
                      type: "DateFilter",
                      defaultValue: {
                        comparator: "=",
                      },
                    }}
                  >
                    Date
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#356FC2",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="70"
                    dataField="route"
                    dataFormat={routeFormater}
                    filter={{ type: "TextFilter" }}
                    filterValue={routeFormater}
                  >
                    Routes
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#356FC2",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="180"
                    dataField="commentaire"
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                  >
                    Problème
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#356FC2",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="100"
                    dataField="id"
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionEvenementTable;
