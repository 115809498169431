import React, { useContext } from "react";
import * as IconlyPack from "react-iconly";
import { NavLink } from "react-router-dom";
import { DataContext } from "../../../redux/GlobalState";
import "./navbarMobileV2.css";

function NavbarMobileV2() {
  const { state } = useContext(DataContext);
  const { auth } = state;
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white">
      <button
        className="navbar-toggler ml-sm-3"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse bg-theme" id="navbarNavDropdown">
        <div className="py-4">
          <div className="mobile-menu-container">
            <div className="mobile-menu-content">
              <div id="MobileMenuDivWrapper">
                <ul className="mobile-menu-ul">
                  {(window.sessionStorage.getItem("dashboard") === "true" ||
                    auth?.user?.dashboard) && (
                    <li>
                      <NavLink
                        className="mobile nav-link nav-link-vertival-custom"
                        to="/dashboard"
                        activeClassName="actived"
                      >
                        <IconlyPack.Category
                          set="bold"
                          primaryColor="white"
                          className="icon-navbar-vertical"
                        />
                        <span className="mobile-navlink-label">Dashboard</span>
                      </NavLink>
                    </li>
                  )}

                  {(window.sessionStorage.getItem("route") === "true" ||
                    auth?.user?.route) && (
                    <li>
                      <NavLink
                        className="mobile nav-link nav-link-vertival-custom"
                        to="/gestion-des-routes"
                        activeClassName="actived"
                      >
                        <IconlyPack.Location
                          set="light"
                          primaryColor="white"
                          className="icon-navbar-vertical"
                        />
                        <span className="mobile-navlink-label">
                          Point d'interet
                        </span>
                      </NavLink>
                    </li>
                  )}

                  {(window.sessionStorage.getItem("direction") === "true" ||
                    auth?.user?.direction) && (
                    <li>
                      <NavLink
                        className="mobile nav-link nav-link-vertival-custom"
                        to="/gestion-des-directions"
                        activeClassName="actived"
                      >
                        <IconlyPack.Discovery
                          set="light"
                          primaryColor="white"
                          className="icon-navbar-vertical"
                        />
                        <span className="mobile-navlink-label">Directions</span>
                      </NavLink>
                    </li>
                  )}
                  <li>
                    <NavLink
                      className="mobile nav-link nav-link-vertival-custom"
                      to="/gestion-transversales"
                      activeClassName="actived"
                    >
                      <IconlyPack.Location
                        set="light"
                        primaryColor="white"
                        className="icon-navbar-vertical"
                      />
                      <span className="mobile-navlink-label">
                        Transversales
                      </span>
                    </NavLink>
                  </li>
                  {(window.sessionStorage.getItem("evenement") === "true" ||
                    auth?.user?.evenement) && (
                    <li>
                      <NavLink
                        className="mobile nav-link nav-link-vertival-custom"
                        to="/gestion-des-evenement"
                        activeClassName="actived"
                      >
                        <IconlyPack.Calendar
                          set="light"
                          primaryColor="white"
                          className="icon-navbar-vertical"
                        />
                        <span className="mobile-navlink-label">Événements</span>
                      </NavLink>
                    </li>
                  )}
                  {(window.sessionStorage.getItem("banner") === "true" ||
                    auth?.user?.banner) && (
                    <li>
                      <NavLink
                        className="mobile nav-link nav-link-vertival-custom"
                        to="/gestion-des-banniers"
                        activeClassName="actived"
                      >
                        <IconlyPack.Image
                          set="light"
                          primaryColor="white"
                          className="icon-navbar-vertical"
                        />
                        <span className="mobile-navlink-label">Bannières</span>
                      </NavLink>
                    </li>
                  )}
                  {(window.sessionStorage.getItem("list_users") === "true" ||
                    auth?.user?.list_users) && (
                    <li>
                      <NavLink
                        className="mobile nav-link nav-link-vertival-custom"
                        to="/gestion-des-utilisateurs"
                        activeClassName="actived"
                      >
                        <IconlyPack.People
                          set="light"
                          primaryColor="white"
                          className="icon-navbar-vertical"
                        />
                        <span className="mobile-navlink-label">
                          Liste des utilisateurs
                        </span>
                      </NavLink>
                    </li>
                  )}
                  {(window.sessionStorage.getItem("list_admins") === "true" ||
                    auth?.user?.list_admins) && (
                    <li>
                      <NavLink
                        className="mobile nav-link nav-link-vertival-custom"
                        to="/parametre"
                        activeClassName="actived"
                      >
                        <IconlyPack.Setting
                          set="light"
                          primaryColor="white"
                          className="icon-navbar-vertical"
                        />
                        <span className="mobile-navlink-label">
                          Liste des administrateurs
                        </span>
                      </NavLink>
                    </li>
                  )}
                  {(window.sessionStorage.getItem("notif") === "true" ||
                    auth?.user?.notif) && (
                    <li>
                      <NavLink
                        className="mobile nav-link nav-link-vertival-custom"
                        to="/gestion-marketing"
                        activeClassName="actived"
                      >
                        <IconlyPack.Message
                          set="light"
                          primaryColor="white"
                          className="icon-navbar-vertical"
                        />
                        <span className="mobile-navlink-label">
                          Notifications
                        </span>
                      </NavLink>
                    </li>
                  )}
                  {(window.sessionStorage.getItem("contact") === "true" ||
                    auth?.user?.contact) && (
                    <li>
                      <NavLink
                        className="mobile nav-link nav-link-vertival-custom"
                        to="/gestion-des-contacts"
                        activeClassName="actived"
                      >
                        <IconlyPack.InfoSquare
                          set="light"
                          primaryColor="white"
                          className="icon-navbar-vertical"
                        />
                        <span className="mobile-navlink-label">Contacts</span>
                      </NavLink>
                    </li>
                  )}

                  {(window.sessionStorage.getItem("profil") === "true" ||
                    auth?.user?.profil) && (
                    <li>
                      <NavLink
                        className="mobile nav-link nav-link-vertival-custom"
                        to="/profil"
                        activeClassName="actived"
                      >
                        <IconlyPack.User
                          set="light"
                          primaryColor="white"
                          className="icon-navbar-vertical"
                        />
                        <span className="mobile-navlink-label">Mon profil</span>
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavbarMobileV2;
