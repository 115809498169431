import { ACTIONS } from "./Actions";

const reducers = (state, action) => {
  switch (action.type) {
    case ACTIONS.AUTH:
      return {
        ...state,
        auth: action.payload,
      };
    case ACTIONS.LIST_ROUTES:
      return {
        ...state,
        routes: action.payload,
      };
    case ACTIONS.LIST_EVENTS:
      return {
        ...state,
        events: action.payload,
      };
    case ACTIONS.LIST_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case ACTIONS.LIST_ADMINS:
      return {
        ...state,
        admins: action.payload,
      };
    case "TRANSVERSALES":
      return {
        ...state,
        transversales: action.payload,
      };
    case ACTIONS.LIST_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case ACTIONS.LIST_CONTACTS:
      return {
        ...state,
        contacts: action.payload,
      };
    case ACTIONS.LIST_DIRECTIONS:
      return {
        ...state,
        directions: action.payload,
      };
    case ACTIONS.LIST_BANNERS:
      return {
        ...state,
        banners: action.payload,
      };
    case ACTIONS.LIST_MARKETING:
      return {
        ...state,
        marketings: action.payload,
      };
    default:
      return state;
  }
};

export default reducers;
