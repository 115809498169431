import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { DataContext } from "../../../../redux/GlobalState";
import RequestPassword from "../../../Services/RequestPassword";

function ChangePasswordModal() {
  const { state, dispatch } = useContext(DataContext);
  const { auth } = state;
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    watch,
    clearErrors,
    reset,
  } = useForm();

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };

  useEffect(() => {
    register("new_password", {
      required: {
        value: true,
        message: "Le nouveau mot de passe est requis.",
      },
    });
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 5000);
    }
  }, [formState, formState?.errors, clearErrors, register]);

  const onSubmit = async (data, e) => {
    setIsLoading(true);
    delete data?.confirm_password;
    console.log("data", data);
    const request = RequestPassword.changePassword(data, requestConfig);
    await request
      .then((res) => {
        console.log("res", res);
        if (res.status === "success") {
          setIsLoading(false);
          Swal.fire({
            icon: "success",
            title: "Mot de passe modifié avec succés.",
            showConfirmButton: false,
            timer: 1000,
          });
          window.$(".change-password-modal").modal("hide");
          //   toast.success("Mot de passe modifié avec succés.");
          e.target.reset();
          reset();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (
          error?.response?.data?.old_password &&
          error?.response?.data?.old_password[0] === "Wrong password."
        ) {
          setError("Mot de passe incorrect");
          //  setIsLoading(false)
          setValue("old_password", "");

          setTimeout(() => {
            setError(null);
          }, 5000);
        } else {
          //   toast.error("Oops! Une erreur est survenue lors de la modification.");
          Swal.fire({
            icon: "error",
            title: "Oops! Une erreur est survenue lors de la modification.",
            showConfirmButton: false,
            timer: 3000,
          });
        }
      });
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-md">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AddingFamilyModal">
            Modifier mot de passe
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="row crud-row">
                <div className="col-md-12 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label className="label-input pb-2" htmlFor="old_password">
                      Ancien mot de passe
                    </label>
                    <input
                      {...register("old_password", {
                        required: "L'ancien mot de passe est requis.",
                      })}
                      type="password"
                      placeholder="Ancien mot de passe"
                      name="old_password"
                      className="form-control bloc-div"
                      id="old_password"
                    />
                    {formState?.errors && formState?.errors?.old_password && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState?.errors?.old_password?.message}
                      </div>
                    )}
                    {error && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {error}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-12 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label className="label-input pb-2" htmlFor="new_password">
                      Nouveau mot de passe
                    </label>
                    <input
                      {...register("new_password", {
                        required: "Le nouveau mot de passe est requis.",
                      })}
                      type="password"
                      placeholder="Nouveau mot de passe"
                      name="new_password"
                      className="form-control bloc-div"
                      id="new_password"
                    />
                    {formState?.errors && formState?.errors?.new_password && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState?.errors?.new_password?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-12 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label
                      className="label-input pb-2"
                      htmlFor="confirm_password"
                    >
                      Confirmer le nouveau mot de passe
                    </label>
                    <input
                      {...register("confirm_password", {
                        required: "La confirmation du mot de passe est requis.",
                        validate: (value) => {
                          return (
                            value === watch("new_password") ||
                            "Veuillez entrer deux mots de passe identiques."
                          );
                        },
                      })}
                      type="password"
                      placeholder="Confirmer le nouveau mot de passe"
                      name="confirm_password"
                      className="form-control bloc-div"
                      id="confirm_password"
                    />
                    {formState?.errors &&
                      formState?.errors?.confirm_password && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {formState?.errors?.confirm_password?.message}
                        </div>
                      )}
                  </div>
                </div>

                <div className="col-md-12 d-flex justify-content-center crud-form-action-btn-container">
                  {/* <button className="btn btn-theme-plus">Enregistrer</button> */}
                  {isLoading && (
                    <button className="btn-theme-plus" disabled>
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isLoading && (
                    <button className="btn-theme-plus">VALIDER</button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePasswordModal;
