import React, { useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import { DataContext } from "../../../../../redux/GlobalState";
import User from "../../../../Services/User";
import { getData } from "../../../../../utils/fetchData";

function UseEditUserForm() {
  const { dispatch } = useContext(DataContext);
  const history = useHistory();
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
      //   fullname: yup.string().required("Le nom est requis"),
      //   phone: yup.string().required("Le numéro de téléphone est requis."),
      //   email: yup.string().required("L'email est requis."),
      //   adresse: yup.string().required("L'adresse est requise."),
     }),
    []
  );

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    watch,
    reset,
    setValue,
    unregister,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmitForm = async (formValues, e) => {
    let token = window.sessionStorage.getItem("userToken");
    setIsSuccessfullySubmitted(true);
    console.log(formValues);
    // var fd = new FormData();
    // fd.append("fullname", formValues?.fullname);
    // fd.append("email", formValues?.email);
    // fd.append("phone", formValues?.phone);
    // fd.append("adresse", formValues?.adresse);

    // if (formValues?.avatar) {
    //   fd.append("avatar", formValues?.avatar);
    // }
    var request = User.onEditUser(formValues?.id, formValues, requestConfig);
    await request
      .then(async (resp) => {
        console.log("resp user add", resp);
        await getData("user", token)
          .then((response) => {
            // console.log("response users", response);
            dispatch({
              type: "LIST_USERS",
              payload: {
                isLoading: false,
                data: response.results
                  .filter((user) => user?.user_type !== "admin"  && user?.is_archived === false)
                  .sort((a, b) => b?.id - a?.id),
              },
            });
            Swal.fire({
              icon: "success",
              title: "Modification réussie!",
              showConfirmButton: false,
              timer: 1500,
            });
            setIsSuccessfullySubmitted(false);
            e.target.reset();
            window.$(`#EditStatusModalItem${formValues?.id}`).modal("hide")
            // reset();
            setTimeout(() => {
              // history.goBack();
            }, 1500);
          })
          .catch((error) => {
            console.log("error users", error?.response);
          });
      })
      .catch((error) => {
        console.error("error", error, error?.response);
        if (
          error?.response?.data?.email?.length &&
          error?.response?.data?.email[0] ===
            "user with this email already exists."
        ) {
          Swal.fire({
            icon: "error",
            title: "Cet email est déja associé à un compte.",
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (error?.response?.data?.status === 500) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée au serveur est survenue veuillez réessayer plus tard",
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (error?.response?.data?.status === 400) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée à la requête est survenue, veuillez contacter l'administrateur.",
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Veuillez vérifier votre connexion internet.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
        setIsSuccessfullySubmitted(false);
      });
  };
  return {
    unregister,
    register,
    errors,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    watch,
    reset,
    setValue,
    onSubmit: handleSubmit(onSubmitForm),
  };
}

export default UseEditUserForm;
