export const ACTIONS = {
  AUTH: "AUTH",
  LIST_ROUTES: "LIST_ROUTES",
  LIST_EVENTS: "LIST_EVENTS",
  LIST_USERS: "LIST_USERS",
  LIST_ADMINS: "LIST_ADMINS",
  LIST_NOTIFICATIONS: "LIST_NOTIFICATIONS",
  LIST_CONTACTS: "LIST_CONTACTS",
  LIST_DIRECTIONS: "LIST_DIRECTIONS",
  LIST_BANNERS: "LIST_BANNERS",
  LIST_MARKETING: "LIST_MARKETING",
};
