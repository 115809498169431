import React, { useEffect } from "react";
// import { NavLink } from 'react-router-dom'
// import isAuthenticate from './../modules/Secure/Secure'
import contentApi from "../Services/content";
import EmptyHeader from "./EmptyHeader";
import "./Login.css";

function Privacy() {
  const [data, setData] = React.useState(null);

  useEffect(() => {
    contentApi.get("cgu", {}).then((res) => setData(res));
  }, []);

  return (
    <div>
      <EmptyHeader />
      <div className="login-page-container">
        <section className="auth-page-content">
          <div className="row auth-page-row">
            <div className="col-md-8 offset-md-2 auth-page-col">
              <div className="row auth-form-row">
                <h1 className="auth-form-title m-b-90">
                  Conditions générales d'utilisation
                </h1>
                <div
                  className="col-md-12 auth-input-col m-b-20"
                  dangerouslySetInnerHTML={{
                    __html: data?.text ?? "Chargement...",
                  }}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Privacy;
