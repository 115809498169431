import React, { createContext, useEffect, useReducer } from "react";
import { getData } from "../utils/fetchData";
import reducers from "./Reducers";

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const initialState = {
    auth: {},
    routes: {
      isLoading: true,
      data: [],
    },
    events: {
      isLoading: true,
      data: [],
    },
    users: {
      isLoading: true,
      data: [],
    },
    admins: {
      isLoading: true,
      data: [],
    },
    transversales: {
      isLoading: true,
      data: [],
    },
    contacts: {
      isLoading: true,
      data: [],
    },
    notifications: {
      isLoading: true,
      data: [],
      noRead: [],
    },
    directions: {
      isLoading: true,
      data: [],
    },
    banners: {
      isLoading: true,
      data: [],
    },
    marketings: {
      isLoading: true,
      data: [],
    },
  };
  const [state, dispatch] = useReducer(reducers, initialState);
  const { auth } = state;

  let token = window.sessionStorage.getItem("userToken");

  useEffect(() => {
    if (token) {
      // let user = jwt_decode(token)
      getData(`meadmin/`, token)
        .then((res) => {
          // console.log('res', res)
          sessionStorage.setItem("userID", res?.data?.id);
          window.sessionStorage.setItem("dashboard", res?.data?.dashboard);
          window.sessionStorage.setItem("route", res?.data?.route);
          window.sessionStorage.setItem("direction", res?.data?.direction);
          window.sessionStorage.setItem("evenement", res?.data?.evenement);
          window.sessionStorage.setItem("banner", res?.data?.banner);
          window.sessionStorage.setItem("list_users", res?.data?.list_users);
          window.sessionStorage.setItem("list_admins", res?.data?.list_admins);
          window.sessionStorage.setItem("notif", res?.data?.notif);
          window.sessionStorage.setItem("contact", res?.data?.contact);
          window.sessionStorage.setItem("profil", res?.data?.profil);
          window.sessionStorage.setItem(
            "transversale",
            res?.data?.transversale
          );
          window.sessionStorage.setItem("userType", "admin");

          dispatch({
            type: "AUTH",
            payload: {
              token: token,
              user: res?.data,
            },
          });
        })
        .catch((e) => {
          return sessionStorage.removeItem("authToken");
        });
    }
  }, [token]);

  useEffect(() => {
    if (token || auth?.token) {
      getData("route", token)
        .then((response) => {
          // console.log("response routes", response);
          dispatch({
            type: "LIST_ROUTES",
            payload: {
              isLoading: false,
              data: response.results.sort((a, b) => b?.id - a?.id),
            },
          });
        })
        .catch((error) => {
          console.log("error routes", error?.response);
        });
      getData("direction", token)
        .then((response) => {
          // console.log("direction", response);
          dispatch({
            type: "LIST_DIRECTIONS",
            payload: {
              isLoading: false,
              data: response.results.sort((a, b) => b?.id - a?.id),
            },
          });
        })
        .catch((error) => {
          console.log("error direction", error?.response);
        });

      getData("event", token)
        .then((response) => {
          // console.log("response events", response);
          dispatch({
            type: "LIST_EVENTS",
            payload: {
              isLoading: false,
              data: response.results.sort((a, b) => b?.id - a?.id),
            },
          });
        })
        .catch((error) => {
          console.log("error events", error?.response);
        });

      getData("user", token)
        .then((response) => {
          dispatch({
            type: "LIST_USERS",
            payload: {
              isLoading: false,
              data: response.results
                .filter(
                  (user) =>
                    user?.user_type !== "admin" && user?.is_archived === false
                )
                .sort((a, b) => b?.id - a?.id),
            },
          });
        })
        .catch((error) => {
          console.log("error admins", error?.response);
        });
      getData("adminuser", token)
        .then((response) => {
          // console.log("adminuser", response);
          dispatch({
            type: "LIST_ADMINS",
            payload: {
              isLoading: false,
              data: response.results.sort((a, b) => b?.id - a?.id),
            },
          });
        })
        .catch((error) => {
          console.log("error admins", error?.response);
        });

      getData("transversale", token)
        .then((response) => {
          // console.log("adminuser", response);
          dispatch({
            type: "TRANSVERSALES",
            payload: {
              isLoading: false,
              data: response.results.sort((a, b) => b?.id - a?.id),
            },
          });
        })
        .catch((error) => {
          console.log("error TRANSVERSALES", error?.response);
        });
      getData("contact", token)
        .then((response) => {
          dispatch({
            type: "LIST_CONTACTS",
            payload: {
              isLoading: false,
              data: response.results.sort((a, b) => b?.id - a?.id),
            },
          });
        })
        .catch((error) => {
          console.log("error contact", error?.response);
        });

      getData(`notificationbyadmin`, token)
        .then((response) => {
          // console.log("notications", response);
          dispatch({
            type: "LIST_NOTIFICATIONS",
            payload: {
              isLoading: false,
              data: response.results.sort((a, b) => b?.id - a?.id),
              noRead: response.results
                .filter((item) => item?.read === false)
                .sort((a, b) => b?.id - a?.id),
            },
          });
        })
        .catch((error) => {
          console.log("error notification", error?.response);
        });
      getData(`banner`, token)
        .then((response) => {
          // console.log("banner", response);
          dispatch({
            type: "LIST_BANNERS",
            payload: {
              isLoading: false,
              data: response.results.sort((a, b) => b?.id - a?.id),
            },
          });
        })
        .catch((error) => {
          console.log("error banner", error?.response);
        });
      getData(`marketing`, token)
        .then((response) => {
          // console.log("marketing", response);
          dispatch({
            type: "LIST_MARKETING",
            payload: {
              isLoading: false,
              data: response.results.sort((a, b) => b?.id - a?.id),
            },
          });
        })
        .catch((error) => {
          console.log("error banner", error?.response);
        });
    }
    // return () => {

    // }
  }, [token, auth]);

  return (
    <DataContext.Provider value={{ state, dispatch }}>
      {children}
    </DataContext.Provider>
  );
};
