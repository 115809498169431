import { transversale } from "./Endpoints";
import axios from "axios";

export default {
  async onPost(data, config) {
    const response = await axios.post(
      transversale.onPost(),
      data,
      config
    );
    return response.data;
  },
  async onEdit(id, data, config) {
    const response = await axios.put(
      transversale.onEdit(id),
      data,
      config
    );
    return response.data;
  },
  async onDelete(id, config) {
    const response = await axios.delete(
      transversale.onDelete(id),
      config
    );
    return response.data;
  },
};
