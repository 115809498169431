import React from "react";
import Header from "../../../Navs/Header";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import * as IconlyPack from "react-iconly";
import GestionRoutesTable from "../../../Tables/GestionRoutesTable/GestionRoutesTable";
import "./GestionRoutes.css";
import { Link, useHistory } from "react-router-dom";
import NavbarMobileV2 from "../../navbarMobileV2/navbarMobileV2";

var GestionRoutes = () => {
  const history = useHistory();

  if (window.sessionStorage.getItem("route") !== "true") {
    history.goBack();
    return null;
  }
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />
        <div className="row">
          {/* Navbar Mobile */}
          <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
            <NavbarMobileV2 />
          </div>
        </div>
        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 soinsy">
            <div className="page-body-container">
              <div>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item active" aria-current="page">
                      Point d’interêt
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Liste des routes</h1>
                  </div>
                  <Link
                    to="/ajouter-point-interet"
                    className="adding-item-container linkable"
                  >
                    <span className="icon-container">
                      <IconlyPack.Plus set="light" primaryColor="gray" />
                    </span>
                    <span className="adding-item-label">
                      Ajouter un point d’interêt
                    </span>
                  </Link>
                </div>
                <div className="parent-table-container">
                  <GestionRoutesTable />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionRoutes;
