const ContactData = [
    {
        id: 1,
        message: 'Je suis un message ',
        statut: 'non lu',
        expediteur: {
            first_name: 'Abdou',
            last_name: 'Diop',
            avatar: ""
        },
        date: '26/10/2021',
        heure: '12:34',
        reponse: {
            id: 1,
            reponse: 'Je suis une réponse',
            expediteur: {
                first_name: 'Admin',
                last_name: 'Diop',
                avatar: ''
            },
        }
    },
    {
        id: 2,
        message: 'Je suis un autre message ',
        statut: 'lu',
        expediteur: {
            first_name: 'Soxna Ngoné',
            last_name: 'Diop',
            avatar: ""
        },
        date: '25/10/2021',
        heure: '18:34',
        reponse: {
            id: 1,
            reponse: 'Ipsum aliquam ac ultricies amet.',
            expediteur: {
                first_name: 'Admin',
                last_name: 'Diop',
                avatar: ''
            },
        }
    },
    {
        id: 3,
        message: 'Je suis un message ',
        statut: 'lu',
        expediteur: {
            first_name: 'Abdou',
            last_name: 'Diop',
            avatar: ""
        },
        date: '26/10/2021',
        heure: '12:34',
        reponse: {
            id: 1,
            reponse: 'Je suis une réponse',
            expediteur: {
                first_name: 'Admin',
                last_name: 'Diop',
                avatar: ''
            },
        }
    },
    {
        id: 4,
        message: 'Je suis un message ',
        statut: 'lu',
        expediteur: {
            first_name: 'Abdou',
            last_name: 'Diop',
            avatar: ""
        },
        date: '26/10/2021',
        heure: '12:34',
        reponse: {
            id: 1,
            reponse: 'Je suis une réponse',
            expediteur: {
                first_name: 'Admin',
                last_name: 'Diop',
                avatar: ''
            },
        }
    },
];

export default ContactData;
