import React, { useContext, useEffect, useState } from "react";
import * as IconlyPack from "react-iconly";
import Header from "../../../Navs/Header";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import DefaultAvatar from "../../../../images/others/default-profile-avatar.png";
import { useHistory, useLocation } from "react-router";
import User from "../../../Services/User";
import Swal from "sweetalert2";
import { getData } from "../../../../utils/fetchData";
import { DataContext } from "../../../../redux/GlobalState";
import baseUrl from "../../../../Http/backend-base-url";
import { NavLink } from "react-router-dom";

function UserDetails() {
  const { dispatch } = useContext(DataContext);
  const [user, setUser] = useState({});
  const [avatar, setAvatar] = React.useState(null);
  var [isLoad, setIsLoad] = React.useState(false);
  const location = useLocation();
  const history = useHistory();
  let token = window.sessionStorage.getItem("userToken");
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };

  useEffect(() => {
    if (location?.state?.user) {
      console.log("change");
      setUser(location.state.user);
    }
    return () => {
      setUser({});
    };
  }, [location]);

  const editPrenom = (fullname) => {
    Swal.fire({
      title: "Modifer votre nom",
      input: "text",
      inputValue: fullname,
      inputLabel: "Nom complet",
      customClass: {
        inputLabel: "label-input pb-2",
      },
      inputPlaceholder: "Nom complet",
      inputAttributes: {
        autocapitalize: "off",
        autocorrect: "off",
      },
      confirmButtonText: "Valider",

      showLoaderOnConfirm: true,
      preConfirm: (value) => {
        if (!value) {
          Swal.showValidationMessage(`Le nom est requis.`);
        } else {
          var request = User.onEditUser(
            user?.id,
            { fullname: value },
            requestConfig
          );

          return request
            .then((resp) => {
              console.log("resp", resp);
              setUser(resp);
              history.push({
                pathname: "",
                state: { user: resp },
              });
              getData("user", token)
                .then((response) => {
                  dispatch({
                    type: "LIST_USERS",
                    payload: {
                      isLoading: false,
                      data: response.results
                        .filter(
                          (user) =>
                            user?.user_type !== "admin" &&
                            user?.is_archived === false
                        )
                        .sort((a, b) => b?.id - a?.id),
                    },
                  });
                })
                .catch((error) => {
                  console.log("error users", error?.response);
                });
              return resp;
              // toast.success("Modification réussie!");
            })
            .catch((error) => {
              Swal.showValidationMessage(`Request failed: ${error}`);
            });
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "success",
          title: "Modification réussie!",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };

  const editAdresse = (adresse) => {
    Swal.fire({
      title: "Modifer votre adresse",
      input: "text",
      inputValue: adresse,
      inputLabel: "adresse",
      inputPlaceholder: "HLM Grand yoff",
      inputAttributes: {
        autocapitalize: "off",
        autocorrect: "off",
      },
      confirmButtonText: "Valider",

      showLoaderOnConfirm: true,
      preConfirm: (value) => {
        if (!value) {
          Swal.showValidationMessage(`L'adresse est requise.`);
        } else {
          var request = User.onEditUser(
            user?.id,
            { adresse: value },
            requestConfig
          );

          return request
            .then((resp) => {
              console.log("resp", resp);
              setUser(resp);
              history.push({
                pathname: "",
                state: { user: resp },
              });
              getData("user", token)
                .then((response) => {
                  dispatch({
                    type: "LIST_USERS",
                    payload: {
                      isLoading: false,
                      data: response.results
                        .filter(
                          (user) =>
                            user?.user_type !== "admin" &&
                            user?.is_archived === false
                        )
                        .sort((a, b) => b?.id - a?.id),
                    },
                  });
                })
                .catch((error) => {
                  console.log("error users", error?.response);
                });

              return resp;
              // toast.success("Modification réussie!");
            })
            .catch((error) => {
              Swal.showValidationMessage(`Request failed: ${error}`);
            });
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "success",
          title: "Modification réussie!",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };
  const editEmail = (email) => {
    Swal.fire({
      title: "Modifer votre email",
      input: "email",
      inputValue: email,
      inputLabel: "email",
      inputPlaceholder: "Votre email",
      inputAttributes: {
        autocapitalize: "off",
        autocorrect: "off",
      },
      confirmButtonText: "Valider",

      showLoaderOnConfirm: true,
      preConfirm: (value) => {
        if (!value) {
          Swal.showValidationMessage(`L'email est requis.`);
        } else {
          var request = User.onEditUser(
            user?.id,
            { email: value },
            requestConfig
          );

          return request
            .then((resp) => {
              console.log("resp", resp);
              setUser(resp);
              history.push({
                pathname: "",
                state: { user: resp },
              });
              getData("user", token)
                .then((response) => {
                  dispatch({
                    type: "LIST_USERS",
                    payload: {
                      isLoading: false,
                      data: response.results
                        .filter(
                          (user) =>
                            user?.user_type !== "admin" &&
                            user?.is_archived === false
                        )
                        .sort((a, b) => b?.id - a?.id),
                    },
                  });
                })
                .catch((error) => {
                  console.log("error users", error?.response);
                });
              return resp;
              // toast.success("Modification réussie!");
            })
            .catch((error) => {
              if (
                error?.response?.data?.email?.length &&
                error?.response?.data?.email[0] ===
                  "user with this email already exists."
              ) {
                Swal.showValidationMessage(
                  `Erreur: Cet email est déja associé à un compte`
                );
              } else {
                Swal.showValidationMessage(`Erreur: ${error}`);
              }
            });
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "success",
          title: "Modification réussie!",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };
  const editTelephone = (phone) => {
    Swal.fire({
      title: "Modifer votre numéro de téléphone",
      input: "text",
      inputValue: phone,
      inputLabel: "téléphone",
      inputPlaceholder: "Votre numéro de téléphone",
      inputAttributes: {
        autocapitalize: "off",
        autocorrect: "off",
      },
      confirmButtonText: "Valider",

      showLoaderOnConfirm: true,
      preConfirm: (value) => {
        if (!value) {
          Swal.showValidationMessage(`Le numéro de téléphone est requis.`);
        } else {
          var request = User.onEditUser(
            user?.id,
            { phone: value },
            requestConfig
          );

          return request
            .then((resp) => {
              console.log("resp", resp);
              setUser(resp);
              history.push({
                pathname: "",
                state: { user: resp },
              });
              getData("user", token)
                .then((response) => {
                  dispatch({
                    type: "LIST_USERS",
                    payload: {
                      isLoading: false,
                      data: response.results
                        .filter(
                          (user) =>
                            user?.user_type !== "admin" &&
                            user?.is_archived === false
                        )
                        .sort((a, b) => b?.id - a?.id),
                    },
                  });
                })
                .catch((error) => {
                  console.log("error users", error?.response);
                });
              return resp;
              // toast.success("Modification réussie!");
            })
            .catch((error) => {
              Swal.showValidationMessage(`Request failed: ${error}`);
            });
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "success",
          title: "Modification réussie!",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };

  const changeAvatar = (e) => {
    const file = e.target.files[0];

    if (
      file?.type !== "image/jpeg" &&
      file?.type !== "image/png" &&
      file?.type !== "image/jpg"
    ) {
      Swal.fire({
        icon: "error",
        title: "Veuiller uploader une image de type jpg ou jpeg ou png",
        showConfirmButton: false,
        timer: 2000,
      });
    }
    // console.log('file', file)

    // setData({ ...data, avatar: file })
    const fd = new FormData();
    fd.append("avatar", file);
    setAvatar(file);
    setIsLoad(true);
    var request = User.onEditUser(user?.id, fd, requestConfig);

    request
      .then(async (resp) => {
        setUser(resp);
        console.log("res", resp);
        getData("user", token)
          .then((response) => {
            dispatch({
              type: "LIST_USERS",
              payload: {
                isLoading: false,
                data: response.results
                  .filter(
                    (user) =>
                      user?.user_type !== "admin" && user?.is_archived === false
                  )
                  .sort((a, b) => b?.id - a?.id),
              },
            });
          })
          .catch((error) => {
            console.log("error users", error?.response);
          });
        // Swal.fire({
        //   icon: "success",
        //   title: "Modification réussie!",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
        history.push({
          pathname: "/details-utilisateur",
          state: { user: resp },
        });
        setIsLoad(false);
        setAvatar(null);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oupss! Une erreur est survenue lors de la modification.",
          showConfirmButton: false,
          timer: 2000,
        });
        setIsLoad(false);
      });
  };
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 soinsy mb-3">
            <div className="page-body-container">
              <div>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <NavLink
                        to="/gestion-des-utilisateurs"
                        className="nav-prev"
                      >
                        Utilisateurs
                      </NavLink>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      {user?.fullname}
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Détails utilisateur</h1>
                  </div>
                </div>
                <div className="parent-table-container">
                  <div>
                    <div>
                      <div className="d-flex align-items-center flex-column ">
                        <div>
                          <div className="profile-avatar-container">
                            <img
                              src={
                                avatar
                                  ? URL.createObjectURL(avatar)
                                  : user?.avatar &&
                                    !user?.avatar?.includes("default.png")
                                  ? baseUrl + user?.avatar
                                  : DefaultAvatar
                              }
                              className="profile-avatar img-profil-app"
                              alt="Profile"
                            />
                            {isLoad && (
                              <span className="charge">
                                <i
                                  className="fas fa-spin fa-spinner"
                                  style={{ fontSize: "20px" }}
                                ></i>
                              </span>
                            )}

                            <span>
                              <i className="fas fa-camera"></i>
                              <p>Changer</p>
                              <input
                                type="file"
                                name="avatar"
                                id="file_up"
                                accept="image/*"
                                onChange={changeAvatar}
                              />
                            </span>
                          </div>
                          {/* <img
                            src={
                              user?.avatar &&
                              !user?.avatar?.includes("default.png")
                                ? baseUrl + user?.avatar
                                : DefaultAvatar
                            }
                            alt="Image profil utilisateur"
                            className="img-profil-app"
                          /> */}
                        </div>
                        <div>
                          <h2 className="nom-profil-user pb-3">
                            {user?.fullname}
                          </h2>
                          {/* <p className="text-profil-user">Admin</p> */}
                        </div>
                      </div>
                      <div className="py-3">
                        <p className="label-profil-user">
                          Informations personnelles
                        </p>
                      </div>
                      <form className="row d-flex align-items-center mb-3">
                        <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                          <div className="form-group position-relative">
                            <label className="label-input pb-2">
                              Nom complet
                            </label>
                            <input
                              readOnly
                              type="text"
                              name="fullname"
                              value={user?.fullname}
                              // {...register("fullname")}
                              className="bloc-div form-control"
                              placeholder="Nom Complet"
                            />
                            <span className="icon-edit-profil">
                              <IconlyPack.Edit
                                set="light"
                                primaryColor="#AEAEAE"
                                onClick={() => editPrenom(user?.fullname)}
                              />
                            </span>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                          <div className="form-group">
                            <label className="label-input pb-2">Adresse</label>
                            <input
                              readOnly
                              type="text"
                              name="adresse"
                              value={user?.adresse}
                              // {...register("adresse")}
                              className="bloc-div form-control"
                              placeholder="Adresse"
                            />
                            <span className="icon-edit-profil">
                              <IconlyPack.Edit
                                set="light"
                                primaryColor="#AEAEAE"
                                onClick={() => editAdresse(user?.adresse)}
                              />
                            </span>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                          <div className="form-group">
                            <label className="label-input pb-2">Email</label>
                            <input
                              readOnly
                              type="email"
                              name="email"
                              value={user?.email}
                              // {...register("email")}
                              className="bloc-div form-control"
                              placeholder="Email"
                            />
                            <span className="icon-edit-profil">
                              <IconlyPack.Edit
                                set="light"
                                primaryColor="#AEAEAE"
                                onClick={() => editEmail(user?.email)}
                              />
                            </span>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 py-3">
                          <div className="form-group">
                            <label className="label-input pb-2">
                              Numéro de téléphone
                            </label>
                            <input
                              readOnly
                              type="text"
                              name="phone"
                              value={user?.phone}
                              // readOnly
                              // {...register("phone")}
                              className="bloc-div form-control"
                              placeholder="Numéro de téléphone"
                            />
                            <span className="icon-edit-profil">
                              <IconlyPack.Edit
                                set="light"
                                primaryColor="#AEAEAE"
                                onClick={() => editTelephone(user?.phone)}
                              />
                            </span>
                          </div>
                        </div>

                        {/* <div className="col-md-3 offset-md-9 crud-form-action-btn-container d-flex justify-content-end">
                          {isSuccessfullySubmitted && (
                            <button className="btn-theme-plus" disabled>
                              En cours &nbsp;
                              <i className="fas fa-spin fa-spinner"></i>
                            </button>
                          )}
                          {!isSuccessfullySubmitted && (
                            <button className="btn-theme-plus">MODIFIER</button>
                          )}
                        </div> */}
                      </form>
                    </div>
                  </div>
                  {/* Modal */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserDetails;
