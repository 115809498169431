import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { NavLink, useHistory } from "react-router-dom";
import baseUrl from "../../../../Http/backend-base-url";
import Danger from "../../../../images/icons/danger.png";
import Direction from "../../../../images/icons/direction.png";
import Usager from "../../../../images/icons/usager.png";
import Users from "../../../../images/icons/users.png";
import { DataContext } from "../../../../redux/GlobalState";
import { getData } from "../../../../utils/fetchData";
import Header from "../../../Navs/Header";
import LineChart from "../../LineChart/LineChart";
import NavbarMobileV2 from "../../navbarMobileV2/navbarMobileV2";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import "./Dashboard.css";

var Dashboard = () => {
  const { state } = useContext(DataContext);
  const { notifications } = state;
  const history = useHistory();
  var [isPageLoading, setIsPageLoading] = React.useState(true);
  const [dashboard, setDashboard] = React.useState({});

  let token = window.sessionStorage.getItem("userToken");

  useEffect(() => {
    if (token) {
      getData("dashboard", token)
        .then((response) => {
          // console.log("response", response);
          setDashboard(response);
          setIsPageLoading(false);
        })
        .catch((error) => {
          console.log("error ", error?.response);
        });
    }
  }, [token]);

  const CardSkeleton = () => {
    const renderModules = Array(3)
      .fill()
      .map((item, index) => (
        <div
          className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2"
          key={index}
        >
          <div className="card">
            <Skeleton
              variant="rect"
              style={{ borderRadius: "10px" }}
              width={332}
              height={100}
            />
          </div>
        </div>
      ));
    return <React.Fragment>{renderModules}</React.Fragment>;
  };

  if (window.sessionStorage.getItem("dashboard") !== "true") {
    history.goBack();
    return null;
  }

  return (
    <div className="container-fluid body-background-dashboard">
      <Header />
      <div className="row">
        {/* Navbar Mobile */}
        <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
          <NavbarMobileV2 />
        </div>
      </div>
      <div className="row">
        {/* Navbar Desktop  */}
        <div className="nav-container no-view-mobile">
          <NavBarVerticalV2 />
        </div>

        <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-7 col-lg-7 col-md-7 d-flex flex-column">
                <div className="row mb-3 px-2">
                  {isPageLoading && <CardSkeleton />}
                  {!isPageLoading && (
                    <React.Fragment>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                        <div className="card-statistics card-statistics-color">
                          <div className="stat-conteny">
                            <div className="icon-statistic-1">
                              <img src={Direction} />
                            </div>
                            <p className="label-stat-counter pt-3">
                              {dashboard?.nb_routes}
                            </p>
                            <p className="child-label-card-statistics">
                              Nombre de points d’interêts
                            </p>
                            {/* <div className="d-flex pt-3">
                              <MovingIcon className="icon-moving-color-1" />
                              <p className="text-moving-color-1 pl-3">1.8%</p>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                        <div className="card-statistics card-statistics-color">
                          <div className="stat-conteny">
                            <div className="icon-statistic-2">
                              <img src={Danger} />
                            </div>
                            <p className="label-stat-counter pt-3">
                              {dashboard?.nb_events}
                            </p>
                            <p className="child-label-card-statistics">
                              Événements signalés
                            </p>
                            {/* <div className="d-flex pt-3">
                              <MovingIcon className="icon-moving-color-2" />
                              <p className="text-moving-color-2 pl-3">1.8%</p>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
                        <div className="card-statistics card-statistics-color">
                          <div className="stat-conteny">
                            <div className="icon-statistic-3">
                              <img src={Users} />
                            </div>
                            <p className="label-stat-counter pt-3">
                              {dashboard?.nb_users}
                            </p>
                            <p className="child-label-card-statistics">
                              Utilisateurs
                            </p>
                            {/* <div className="d-flex pt-3">
                              <MovingIcon className="icon-moving-color-3" />
                              <p className="text-moving-color-3 pl-3">1.8%</p>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </div>
                <div className="row">
                  <div className="col-xl-12 m-b-5">
                    <div className="card-dashboard m-b-15">
                      {isPageLoading === false ? (
                        <LineChart />
                      ) : (
                        <Skeleton width={`100%`} height={500} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-5 d-flex">
                <div>
                  <div className="bg-white p-3" id="list-dashboard">
                    <div>
                      <div className="page-first-row-container">
                        <div className="page-title-container">
                          <h1 className="page-title-dashboard">
                            Activités récentes
                          </h1>
                        </div>
                        <div className="adding-item-container">
                          <span className="icon-container">
                            <MoreVertIcon />
                          </span>
                        </div>
                      </div>
                      <div className="pt-3">
                        <ul class="list-group list-group-flush">
                          {notifications?.isLoading && <ActivitiesSkeleton />}
                          {!notifications?.isLoading &&
                            (notifications?.data?.length ? (
                              notifications?.data.slice(0, 10).map((item) => (
                                <li class="list-group-item" key={item?.id}>
                                  <div className="row align-items-center">
                                    <div className="col-xl-1 col-lg-1 col-md-1">
                                      <img
                                        src={
                                          item?.event?.user?.avatar &&
                                          !item?.event?.user?.avatar?.includes(
                                            "default.png"
                                          )
                                            ? baseUrl +
                                              item?.event?.user?.avatar
                                            : Usager
                                        }
                                        width="30"
                                        height="30"
                                        style={{ borderRadius: "100%" }}
                                      />
                                    </div>
                                    <div className="col-xl-11 col-lg-11 col-md-11">
                                      <div>
                                        <span className="color-list">
                                          {item?.message}“{" "}
                                          <NavLink
                                            to={{
                                              pathname: "details-evenement",
                                              state: { event: item?.event },
                                            }}
                                            className="color-theme-list"
                                          >
                                            {item?.event?.route?.name}{" "}
                                          </NavLink>
                                          “
                                        </span>
                                      </div>
                                      <div>
                                        <span className="text-time-list">
                                          {moment(item?.created_at).isSame(
                                            new Date(),
                                            "day"
                                          )
                                            ? "Aujourd’hui - "
                                            : moment()
                                                .subtract(1, "days")
                                                .isSame(item?.created_at, "day")
                                            ? "Hier - "
                                            : "Le " +
                                              moment(item?.created_at).format(
                                                "DD/MM/YYYY"
                                              ) +
                                              " - "}
                                          {moment(item?.created_at).format(
                                            "hh:mm"
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))
                            ) : (
                              <div className="gfa-rowy">
                                <div className="alert alert-warning gfa-warning">
                                  Aucune activité récente pour le moment
                                </div>
                              </div>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

const ActivitiesSkeleton = () => {
  const renderModules = Array(6)
    .fill()
    .map((item, index) => (
      <li class="list-group-item" key={index}>
        <tr className="row align-items-center d-flex">
          <td className="col-xl-1 col-lg-1 col-md-1">
            <Skeleton circle={true} width={35} height={35} />
          </td>
          <td className="col-xl-11 col-lg-11 col-md-11">
            <div>
              <span className="color-list">
                <Skeleton width={250} />
                <span className="color-theme-list ml-1">
                  <Skeleton width={50} />
                </span>
              </span>
            </div>
            <div className="text-left" style={{ marginLeft: ".8rem" }}>
              <span className="text-time-list">
                <Skeleton width={50} />
              </span>
            </div>
          </td>
        </tr>
      </li>
    ));
  return <React.Fragment>{renderModules}</React.Fragment>;
};
