import { marketing } from "./Endpoints";
import axios from "axios";

export default {
  async onPostMarketing(data, config) {
    const response = await axios.post(
      marketing.onPostMarketing(),
      data,
      config
    );
    return response.data;
  },
  async onEditMarketing(id, data, config) {
    const response = await axios.put(
      marketing.onEditMarketing(id),
      data,
      config
    );
    return response.data;
  },
  async onDeleteMarketing(id, config) {
    const response = await axios.delete(
      marketing.onDeleteMarketing(id),
      config
    );
    return response.data;
  },
};
