import React, { useContext } from "react";
import {
  BootstrapTable,
  ClearSearchButton,
  TableHeaderColumn,
} from "react-bootstrap-table";

import * as IconlyPack from "react-iconly";
import Swal from "sweetalert2";
import { DataContext } from "../../../redux/GlobalState";
import { getData } from "../../../utils/fetchData";
import TransversaleModal from "../../Modals/TransversaleModal";
import Transversale from "../../Services/Transversale";
import TransversaleTableSkelaton from "./TransversaleTableSkelaton";

var TransversaleTable = () => {
  const { state, dispatch } = useContext(DataContext);
  const { transversales } = state;
  let token = window.sessionStorage.getItem("userToken");
  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };

  const onDelete = (id) => {
    Swal.fire({
      title: "Êtes vous sûr de vouloir supprimer cette transversale ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        const requestConfig = {
          headers: {
            Authorization: `Bearer ${window.sessionStorage.getItem(
              "userToken"
            )}`,
          },
        };
        return Transversale.onDelete(id, requestConfig)
          .then((response) => {
            console.log("res", response);
            getData("transversale", token)
              .then((response) => {
                // console.log("adminuser", response);
                dispatch({
                  type: "TRANSVERSALES",
                  payload: {
                    isLoading: false,
                    data: response.results.sort((a, b) => b?.id - a?.id),
                  },
                });
              })
              .catch((error) => {
                console.log("error admins", error?.response);
              });
            return response;
          })
          .catch((error) => {
            console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "success",
          title: "Transversale supprimée avec succés.",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };

  const actionFormatter = (cell, row) => {
    const modalId = `UpdateTrans${row.id}`;
    return (
      <div className="d-flex align-items-center">
        <button className="action-btn delete-btn">
          <IconlyPack.Delete
            set="bold"
            primaryColor="#EB5757"
            className="action-btn-icon action-delete-icon"
            onClick={() => onDelete(row?.id)}
          />
        </button>

        <button className="action-btn edit-btn" title="Modifier">
          <IconlyPack.EditSquare
            set="bold"
            primaryColor="#2F80ED"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target={`#${modalId}`}
          />
        </button>

        {/* Edit Modal */}
        <div
          class={`modal fade easypm-modal`}
          id={`${modalId}`}
          tabIndex="-1"
          aria-labelledby="EditAdminModalItemLabel"
          aria-hidden="true"
        >
          <TransversaleModal item={row} modalId={modalId} />
        </div>
      </div>
    );
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {transversales?.isLoading && <TransversaleTableSkelaton />}
            {!transversales?.isLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={transversales?.data}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    isKey={true}
                    dataField="id"
                    width="50"
                  >
                    N°
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="name"
                    width="200"
                  >
                    Nom
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                      padding: 15,
                    }}
                    width="150"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransversaleTable;
