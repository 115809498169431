import React, { useContext, useEffect } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import AdminsData from "./AdminsData";
import AdminsTableSkeleton from "./AdminsTableSkeleton";

import * as IconlyPack from "react-iconly";
import { DataContext } from "../../../redux/GlobalState";
import EditAdminModal from "../../Modals/EditAdminModal";
import Swal from "sweetalert2";
import { deleteData, getData, putData } from "../../../utils/fetchData";
import DetailsAdmin from "../../Modals/DetailsAdmin";

var AdminsTable = () => {
  const { state, dispatch } = useContext(DataContext);
  const { admins } = state;
  let token = window.sessionStorage.getItem("userToken");
  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };

  const onDelete = (id) => {
    Swal.fire({
      title: "Êtes vous sûr de vouloir archiver cet administrateur?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return putData(
          `user/${id}/`,
          { is_archived: true, is_active: false },
          token
        )
          .then((response) => {
            console.log("res", response);
            getData("adminuser", token)
              .then((response) => {
                // console.log("adminuser", response);
                dispatch({
                  type: "LIST_ADMINS",
                  payload: {
                    isLoading: false,
                    data: response.results.sort((a, b) => b?.id - a?.id),
                  },
                });
              })
              .catch((error) => {
                console.log("error admins", error?.response);
              });
            return response;
          })
          .catch((error) => {
            console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("res", result);
        Swal.fire({
          icon: "success",
          title: "Administrateur archiver avec succés.",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };

  const onDesarchive = (id) => {
    Swal.fire({
      title: "Êtes vous sûr de vouloir désarchiver cet administrateur?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return putData(
          `user/${id}/`,
          { is_archived: false, is_active: true },
          token
        )
          .then((response) => {
            console.log("res", response);
            getData("adminuser", token)
              .then((response) => {
                // console.log("adminuser", response);
                dispatch({
                  type: "LIST_ADMINS",
                  payload: {
                    isLoading: false,
                    data: response.results.sort((a, b) => b?.id - a?.id),
                  },
                });
              })
              .catch((error) => {
                console.log("error admins", error?.response);
              });
            return response;
          })
          .catch((error) => {
            console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("res", result);
        Swal.fire({
          icon: "success",
          title: "Administrateur désarchivé avec succés.",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="d-flex align-items-center">
        {row?.is_archived ? (
          <button className="action-btn delete-btn">
            <IconlyPack.CloseSquare
              set="light"
              primaryColor="#FF0000"
              className="action-btn-icon action-delete-icon"
              onClick={() => onDesarchive(row?.id)}
            />
          </button>
        ) : (
          <button className="action-btn delete-btn">
            <IconlyPack.Delete
              set="bold"
              primaryColor="#EB5757"
              className="action-btn-icon action-delete-icon"
              onClick={() => onDelete(row?.id)}
            />
          </button>
        )}

        <button className="action-btn edit-btn" title="Modifier">
          <IconlyPack.Show
            set="bold"
            primaryColor="#27AE60"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target={`#DetailsAdminModalItem${row?.id}`}
          />
        </button>

        <button className="action-btn edit-btn" title="Modifier">
          <IconlyPack.EditSquare
            set="bold"
            primaryColor="#2F80ED"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target={`#EditAdminModalItem${row?.id}`}
          />
        </button>

        {/* Edit Modal */}
        <div
          class={`modal fade easypm-modal edit-admin-modal${row?.id}`}
          id={`EditAdminModalItem${row?.id}`}
          tabIndex="-1"
          aria-labelledby="EditAdminModalItemLabel"
          aria-hidden="true"
        >
          <EditAdminModal admin={row} />
        </div>
        {/* Details Modal */}
        <div
          class={`modal fade easypm-modal details-admin-modal${row?.id}`}
          id={`DetailsAdminModalItem${row?.id}`}
          tabIndex="-1"
          aria-labelledby="DetailsAdminModalItemLabel"
          aria-hidden="true"
        >
          <DetailsAdmin admin={row} />
        </div>
      </div>
    );
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {admins?.isLoading && <AdminsTableSkeleton />}
            {!admins?.isLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={admins?.data}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    isKey={true}
                    dataField="id"
                    width="50"
                  >
                    N°
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="fullname"
                    width="200"
                  >
                    Prénom & Nom
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    dataField="phone"
                  >
                    Téléphone
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    dataField="email"
                  >
                    Adresse Mail
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="150"
                    dataField="adresse"
                  >
                    Adresse
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                      padding: 15,
                    }}
                    width="150"
                    dataField="id"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminsTable;
