import React, { useEffect, useState } from "react";
import Header from "../../../Navs/Header";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import * as IconlyPack from "react-iconly";
import { Link, NavLink, useLocation } from "react-router-dom";
import DefaultImage from "../../../../images/NoImage.png";
import Profil from "../../../../images/profil.png";

import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { useParams } from "react-router";
import GestionEvenementTableData from "../../../Tables/GestionEvenementTable/GestionEvenementTableData";
import baseUrl from "../../../../Http/backend-base-url";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import NavbarMobileV2 from "../../navbarMobileV2/navbarMobileV2";

moment.updateLocale("fr", {
  relativeTime: {
    future: "in %s",
    past: "%s ans",
    s: "1 secondes",
    ss: "%d secondes",
    m: "1 minute",
    mm: "%d minutes",
    h: "1 heure",
    hh: "%d heures",
    d: "1 jour",
    dd: "%d jours",
    w: "1 semaine",
    ww: "%d semaines",
    M: "1 mois",
    MM: "%d mois",
    y: "1 an",
    yy: "%d ans",
  },
});
var DetailsEvenement = () => {
  const location = useLocation();
  const [event, setEvent] = useState({});
  const [isLoad, setIsLoad] = useState(true);
  let detail = [];
  let detailInfo = [];
  let evenements = GestionEvenementTableData;

  const { id } = useParams();
  evenements.forEach((item) => {
    if (item.id === parseInt(id)) {
      console.log(item);
      // setDetail(item)
      detail.push(item);
    }
  });
  detailInfo = detail[0];
  console.log(detailInfo, "item");
  useEffect(() => {
    if (location?.state?.event) {
      setEvent(location.state.event);
      setTimeout(() => {
        setIsLoad(false);
      }, 5000);
    }
    return () => {
      setEvent({});
    };
  }, [location]);
  //   console.log(detailInfo, "item");
  useEffect(() => {}, [id]);

  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />
        <div className="row">
          {/* Navbar Mobile */}
          <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
            <NavbarMobileV2 />
          </div>
        </div>
        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 soinsy">
            <div className="page-body-container">
              <div>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <a href="/gestion-des-evenement" className="nav-prev">
                        Évènements
                      </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      {event?.route?.name}
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="page-content-container">
                    <div className="parent-table-container">
                      <div className="page-first-row-container">
                        <div className="page-title-container">
                          <h1 className="page-title">Événements</h1>
                        </div>
                      </div>
                      <div className="pt-4">
                        <div>
                          {!isLoad ? (
                            <img
                              src={
                                event?.photo
                                  ? baseUrl + event?.photo
                                  : DefaultImage
                              }
                              className="w-full"
                              height="300"
                              style={{ objectFit: "fill" }}
                            />
                          ) : (
                            <Skeleton className="w-full" height={300} />
                          )}
                        </div>
                        <div className="d-flex pt-3">
                          <div>
                            <ThumbUpAltOutlinedIcon className="icon-like" />
                            <span className="like-nombre pl-2">
                              {event?.dislikes?.length}
                            </span>
                          </div>
                          <div className="pl-3">
                            <ThumbDownAltOutlinedIcon className="icon-like" />
                            <span className="like-nombre pl-2">
                              {event?.likes?.length}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="page-content-container">
                    <div className="parent-table-container">
                      <div className="py-4">
                        <div className="menu-bloc">
                          <div className="w-full">
                            <div className="row align-items-center">
                              <div className="col-xl-2 col-lg-2 col-md-2">
                                <img
                                  src={
                                    event?.user?.avatar &&
                                    !event?.user?.avatar?.includes(
                                      "default.png"
                                    )
                                      ? baseUrl + event?.user?.avatar
                                      : Profil
                                  }
                                  width="50"
                                  height="50"
                                  alt="profile"
                                  style={{ borderRadius: "50%" }}
                                />
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-6">
                                <p className="name-profil-comment">
                                  {event?.user?.fullname}
                                </p>
                              </div>
                              <div className="col-xl-4 col-lg-4 col-md-4">
                                <p className="date-profil-comment">
                                  {moment(event?.created_at)
                                    .locale("fr")
                                    .fromNow(true)}
                                </p>
                              </div>
                            </div>
                            <div className="row py-4">
                              <div className="col-xl-6 col-lg-6 col-md-6 pl-0">
                                <ul>
                                  <li className="route-list">
                                    Route{" "}
                                    <span className="route-list-value">
                                      {event?.route?.name}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-6">
                                <ul>
                                  <li className="route-list">
                                    Direction{" "}
                                    <span className="route-list-value">
                                      {event?.direction?.name}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div class="dropdown">
                              <button
                                class="btn dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <MoreVertOutlinedIcon className="icon-like" />
                              </button>
                              <div
                                class="dropdown-menu dropdown-menu-content-comment"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <NavLink
                                  class="dropdown-item list-menu-comment"
                                  to={{
                                    pathname: `/details-utilisateur`,
                                    state: { user: event?.user },
                                  }}
                                >
                                  Voir cet utilisateur
                                </NavLink>
                                <NavLink
                                  class="dropdown-item list-menu-comment"
                                  to={{
                                    pathname: `/gestion-des-utilisateurs`,
                                    state: { user: event?.user },
                                  }}
                                >
                                  Supprimer cet utilisateur?
                                </NavLink>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div>
                            <p className="trafic-title">Trafic</p>
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="progress progress-trafic-width">
                                  <div
                                    className={
                                      "progress-bar" +
                                      (event?.trafic === "fluide"
                                        ? " bg-fluid"
                                        : event?.trafic === "moyen"
                                        ? " bg-moyen"
                                        : event?.trafic === "sature"
                                        ? " bg-sature"
                                        : "")
                                    }
                                    role="progressbar"
                                    style={{ width: "100%" }}
                                    aria-valuenow="100"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                              </div>
                              <div>
                                <p className="statut-trafic pl-3">
                                  {event?.trafic}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="py-4">
                          <p className="text-comments-trafic">
                            {event?.commentaire}
                          </p>
                        </div>
                        <div className="d-flex py-3 border-top border-bottom">
                          <div>
                            <ThumbUpAltOutlinedIcon className="icon-like" />
                            <span className="like-nombre pl-2">
                              {event?.dislikes?.length}
                            </span>
                          </div>
                          <div className="pl-3">
                            <ThumbDownAltOutlinedIcon className="icon-like" />
                            <span className="like-nombre pl-2">
                              {event?.likes?.length}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsEvenement;
