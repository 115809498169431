import React, { useMemo, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "./Login.css";
import { Link, NavLink, useHistory } from "react-router-dom";
import * as IconlyPack from "react-iconly";
import Header from "../Navs/Header";
import requestPassword from "../Services/RequestPassword";
import Swal from "sweetalert2";

function MotDePasseOblier() {
  const history = useHistory();
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email("l'email n'est pas valide")
          .required("L'email est requis"),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    watch,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  const onSubmit = async (data, e) => {
    console.log("data", data);
    setIsSuccessfullySubmitted(true);
    var request = requestPassword.postRequestPassword(data);

    await request
      .then((res) => {
        console.log(res);
        setIsSuccessfullySubmitted(false);

        Swal.fire({
          icon: "success",
          title:
            "Veuillez vérifier votre courrier électronique, nous venons de vous envoyer un message pour finaliser la modification de votre mot de passe.",
          showConfirmButton: true,
          allowOutsideClick: false,
          // timer: 1000,
        }).then((result) => {
          if (result.isConfirmed) {
            history.push({
              pathname: "/modifier-mon-mot-de-passe",
              email: data?.email,
            });
            e.target.reset();
            reset();
          }
        });
      })
      .catch((e) => {
        setIsSuccessfullySubmitted(false);
        console.log(e?.response);
        if (e?.response?.data?.status === "failure") {
          //   toast.error(error?.response?.data);
          Swal.fire({
            icon: "error",
            title: e?.response?.data?.message,
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title:
              "Oupsss! Une erreur est survenue. Veuillez réessayer plus tard.",
            showConfirmButton: false,
            timer: 3000,
          });
        }
      });
  };
  return (
    <div className="connexion-page">
      <Header />
      <div className="login-page-container">
        <section className="auth-page-content">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row auth-page-row">
              <div className="col-md-4 offset-md-4 auth-page-col">
                <div className="row auth-form-row">
                  <h1 className="auth-form-title m-b-90">
                    Mot de passe oublié
                  </h1>
                  <div className="col-md-6 offset-md-3 m-b-20">
                    <p className="text-modifier-password">
                      Veuillez entrer votre email Nous vous enverrons un lien
                      pour modifier le mot de passe
                    </p>
                  </div>
                  <div className="col-md-12 auth-input-col m-b-20">
                    <div className="form-group auth-form-group position-relative">
                      <span className="icon-idenfiant">
                        {" "}
                        <IconlyPack.Message set="bold" primaryColor="#C4C4C4" />
                      </span>
                      <input
                        name="email"
                        type="email"
                        {...register("email")}
                        className="form-control auth-form-control pl-5"
                        id="mail"
                        placeholder="Identifiant"
                      />
                    </div>
                    {formState.errors && formState.errors.email && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.email?.message}
                      </div>
                    )}
                  </div>
                  <div className="col-md-6 offset-md-3 d-flex justify-content-center auth-input-col m-b-5">
                    {isSuccessfullySubmitted === false ? (
                      <button className="btn btn-primary auth-submit-btn">
                        ENVOYER
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary auth-submit-btn"
                        disabled
                      >
                        En cours &nbsp;
                        <i className="fas fa-spin fa-spinner"></i>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </div>
    </div>
  );
}

export default MotDePasseOblier;
