import React, { useEffect, useState } from "react"
import UseEditUserForm from "../../Backend/Speero/GestionUtilisateur/requestUser/UseEditUserForm";


const EditStatusUser = ({ user }) => {
    const {
        register,
        onSubmit,
        formState,
        isSuccessfullySubmitted,
        setValue,
        reset,
        clearErrors,
    } = UseEditUserForm();

    useEffect(() => {
        if (user?.id) {
            register("is_active")
            setValue("is_active", user?.is_active)
            register("id")
            setValue("id", user?.id)
        }
    }, [user])

    return (
        <div className="modal-dialog easypm-modal-dialog modal-md">
            <div className="modal-content easypm-modal-content">
                <div className="modal-header easypm-modal-header">
                    <h5 className="modal-title easypm-modal-title" id="AddingFamilyModal">
                        Modifier de statut
                    </h5>
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body easypm-modal-body">
                    <div className="add-family-form-container">
                        <form className="crud-form" onSubmit={onSubmit}>
                            <div className="row crud-row pb-1">
                                <div className="col-md-12 crud-col-item">
                                    <div className="form-group crud-form-group">
                                        <label className="d-flex" for="first_name">Action</label>
                                        <div className="filter-item-container">
                                            <select name="is_active"
                                                {...register("is_active")} className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form" aria-label="Default select example">
                                                <option>Choisir une action</option>
                                                <option value={true}>Activer</option>
                                                <option value={false}>Désactiver</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 offset-md-12 crud-form-action-btn-container">
                                    <button
                                        className="btn btn-default cancelleb-btn"
                                        data-dismiss="modal"
                                    >
                                        Retour
                                    </button>
                                    {/* <button className="btn btn-theme-plus">Enregistrer</button> */}
                                    {isSuccessfullySubmitted && (
                                        <button className="btn-theme-plus" disabled>
                                            En cours &nbsp;
                                            <i className="fas fa-spin fa-spinner"></i>
                                        </button>
                                    )}
                                    {!isSuccessfullySubmitted && (
                                        <button className="btn-theme-plus">Enregistrer</button>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditStatusUser