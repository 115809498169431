import React, { useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import { getData } from "../../../../../utils/fetchData";
import { DataContext } from "../../../../../redux/GlobalState";
import Route from "../../../../Services/Route";

function UseCreateFormRoute() {
  const { dispatch } = useContext(DataContext);
  const history = useHistory();
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required("Le nom est requis"),
        photo: yup.mixed().required("La photo est requise."),
        description: yup.string().required("La description est requise."),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    watch,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmitForm = async (formValues, e) => {
    let token = window.sessionStorage.getItem("userToken");
    setIsSuccessfullySubmitted(true);

    var fd = new FormData();
    fd.append("name", formValues?.name);
    fd.append("description", formValues?.description);

    if (formValues?.photo) {
      fd.append("photo", formValues?.photo);
    }
    var request = Route.onPostRoute(fd, requestConfig);
    await request
      .then(async (resp) => {
        console.log("resp route add", resp);
        await getData("route", token)
          .then((response) => {
            // console.log("response routes", response);
            dispatch({
              type: "LIST_ROUTES",
              payload: {
                isLoading: false,
                data: response.results.sort((a, b) => b?.id - a?.id),
              },
            });
            Swal.fire({
              icon: "success",
              title: "Ajout réussie!",
              showConfirmButton: false,
              timer: 1500,
            });
            setIsSuccessfullySubmitted(false);
            e.target.reset();
            reset();
            setTimeout(() => {
              history.goBack();
            }, 1500);
          })
          .catch((error) => {
            console.log("error routes", error?.response);
          });
      })
      .catch((error) => {
        if (
          error?.response?.status === 400 &&
          error?.response?.data?.name?.length &&
          error?.response?.data?.name[0] ===
            "route with this name already exists."
        ) {
          Swal.fire({
            icon: "error",
            title: "Ce nom est déja relié à un point d'interêt.",
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (error?.response?.status === 500) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée au serveur est survenue veuillez réessayer plus tard",
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (
          error?.response?.status === 400 &&
          !error?.response?.data?.name?.length
        ) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée à la requête est survenue, veuillez contacter l'administrateur.",
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur est survenue, veuillez contacter réessayer plus tard.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
        setIsSuccessfullySubmitted(false);
      });
  };
  return {
    register,
    errors,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    watch,
    reset,
    setValue,
    onSubmit: handleSubmit(onSubmitForm),
  };
}

export default UseCreateFormRoute;
