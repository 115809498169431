import axios from "axios";
import api from "../../Http/global-vars";
const contentApi = {
  async get(type, config) {
    const finded = await axios.get(`${api}${type}/`, config);
    return finded.data[0];
  },
  async onPostCgu(text, config) {
    const finded = await axios.get(`${api}cgu/`, config);
    const item = finded.data[0];
    if (item) {
      return axios
        .put(`${api}conditions/${item.slug}/`, { text }, config)
        .then((res) => res.data);
    } else {
      return axios
        .post(`${api}conditions/`, { text, type: "CGU" }, config)
        .then((res) => res.data);
    }
  },
  async onPostPc(text, config) {
    const finded = await axios.get(`${api}pc/`, config);
    const item = finded.data[0];
    if (item) {
      return axios
        .put(`${api}conditions/${item.slug}/`, { text }, config)
        .then((res) => res.data);
    } else {
      return axios
        .post(`${api}conditions/`, { text, type: "PC" }, config)
        .then((res) => res.data);
    }
  },
};
export default contentApi;
