const GestionEvenementTableData = [
    {
        id: 1,
        date: '20/02/2021',
        route: 'Fidel Castro',
        trafic: "sature",
        user: {
            id: 1,
            first_name: "Abdou",
            last_name: "Diop",
            avatar: "http://localhost:3000/static/media/event.fa5dd78f.png"
        },
        direction: 'Kaloum',
        probleme: 'Route bloquée car un camion est tombée sur la chaussée. Aucune autre déviation possible. Mieux vaut prende l’axe sud-ouest de Fidel Castro pour ne pas passer par là et pouvoir sort ....',
    },
    {
        id: 2,
        date: '20/02/2021',
        route: 'Fidel Castro',
        trafic: "fluide",
        user: {
            id: 1,
            first_name: "Abdou",
            last_name: "Diop",
            // avatar: "../../../../images/profil.png"
        },
        direction: 'Kaloum',
        probleme: 'Route bloquée car un camion est tombée sur la chaussée. Prudence!',
    },
    {
        id: 3,
        date: '20/02/2021',
        route: 'Fidel Castro',
        user: {
            id: 1,
            first_name: "Abdou",
            last_name: "Diop",
            // avatar: "../../../../images/profil.png"
        },
        direction: 'Kaloum',
        probleme: 'Route bloquée car un camion est tombée sur la chaussée. Prudence!',
    },
    {
        id: 4,
        date: '20/02/2021',
        route: 'Fidel Castro',
        trafic: "moyen",
        user: {
            id: 1,
            first_name: "Abdou",
            last_name: "Diop",
            // avatar: "../../../../images/profil.png"
        },
        direction: 'Kaloum',
        probleme: 'Route bloquée car un camion est tombée sur la chaussée. Prudence!',
    },
    {
        id: 5,
        date: '20/02/2021',
        route: 'Fidel Castro',
        trafic: "sature",
        user: {
            id: 1,
            first_name: "Abdou",
            last_name: "Diop",
            // avatar: "../../../../images/profil.png"
        },
        direction: 'Kaloum',
        probleme: 'Route bloquée car un camion est tombée sur la chaussée. Prudence!',
    },
    {
        id: 6,
        date: '20/02/2021',
        route: 'Fidel Castro',
        trafic: "fluide",
        user: {
            id: 1,
            first_name: "Abdou",
            last_name: "Diop",
            avatar: "/images/profil.png"
        },
        direction: 'Kaloum',
        probleme: 'Route bloquée car un camion est tombée sur la chaussée. Prudence!',
    },
];

export default GestionEvenementTableData;
