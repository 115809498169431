import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import CRUDAdminModal from "../../../Modals/CRUDAdminModal";
import Header from "../../../Navs/Header";
import contentApi from "../../../Services/content";
import AdminsTable from "../../../Tables/Admins/AdminsTable";
import NavbarMobileV2 from "../../navbarMobileV2/navbarMobileV2";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import "./Parametre.css";

var Parametre = () => {
  const [active, setActive] = React.useState("admin");
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />
        <div className="row">
          {/* Navbar Mobile */}
          <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
            <NavbarMobileV2 />
          </div>
        </div>
        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 soinsy">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 30,
                      }}
                    >
                      <TabItem
                        active={active === "admin"}
                        title="Liste des administrateurs"
                        onPress={() => setActive("admin")}
                      />
                      <TabItem
                        active={active === "cgu"}
                        title="Conditions générales d’utilisation"
                        onPress={() => setActive("cgu")}
                      />
                      <TabItem
                        active={active === "pc"}
                        title="Politique de confidentialité"
                        onPress={() => setActive("pc")}
                      />
                    </div>
                  </div>
                </div>
                <div className="parent-table-container">
                  {active === "admin" && <ListAdmin />}
                  {active === "pc" && <CguContent type="pc" />}
                  {active === "cgu" && <CguContent type="cgu" />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Parametre;

function ListAdmin() {
  return (
    <div>
      <div className="easypm-tabs-item">
        <div className="row adding-row">
          <div className="col-md-4 offset-md-8 adding-col-offseting">
            <button
              className=" btn"
              data-toggle="modal"
              data-target=".add-admin-modal"
            >
              <AddIcon className="icon-add-user" />
              <span className="text-btn-add pl-2">
                Ajouter un administrateur
              </span>
            </button>
          </div>
        </div>
        <AdminsTable />
      </div>

      {/* Modal */}
      <div
        class="modal fade easypm-modal add-admin-modal"
        id="AddModalItem"
        tabIndex="-1"
        data-backdrop="static"
        aria-labelledby="AddModalItemLabel"
        aria-hidden="true"
      >
        <CRUDAdminModal />
      </div>
    </div>
  );
}

function TabItem({ title, active, onPress }) {
  return (
    <span
      onClick={() => {
        onPress();
      }}
      style={{
        color: active ? "#033985" : "#000",
        borderBottom: active ? "2px solid #033985" : "0",
        cursor: "pointer",
      }}
    >
      {title}
    </span>
  );
}

function CguContent({ type }) {
  const [data, setData] = useState(null);
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };
  useEffect(() => {
    contentApi.get(type, requestConfig).then((res) => setData(res));
  }, []);
  return (
    <div className="p-4">
      <div className="easypm-tabs-item">
        {data === null && <h1>Chargement...</h1>}
        {data !== null && <ContentEditor content={data?.text} type={type} />}
      </div>
    </div>
  );
}

function ContentEditor({ type, content }) {
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };
  const [data, setData] = useState(content || "");
  const [loading, setLoading] = useState(false);
  const onSubmit = async () => {
    setLoading(true);
    try {
      if (type === "cgu") {
        await contentApi.onPostCgu(data, requestConfig);
      }
      if (type === "pc") {
        await contentApi.onPostPc(data, requestConfig);
      }
      Swal.fire({
        icon: "success",
        title: "Données ajouté avec succès",
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1000,
      });
    } catch (ex) {
      Swal.fire({
        icon: "error",
        title: "Une erreur est survenue lors de l'ajout",
        showConfirmButton: false,
        timer: 3000,
      });
    }
    setLoading(false);
  };
  return (
    <div>
      <HtmlInput
        name={`conten_${type}`}
        data={data}
        onChange={(data) => setData(data)}
      />
      <div className="my-2">
        {!loading && (
          <button className="btn" onClick={() => onSubmit()}>
            <EditIcon className="icon-add-user" />
            <span className="text-btn-add pl-2">Mettre à jour</span>
          </button>
        )}
        {loading && (
          <button className="btn">
            <EditIcon className="icon-add-user" />
            <span className="text-btn-add pl-2">Chargement...</span>
          </button>
        )}
      </div>
    </div>
  );
}

export function HtmlInput({ onChange, data = "", name = "description" }) {
  const editor = useRef();
  useEffect(() => {
    if (!editor.current && window.ClassicEditor?.create) {
      editor.current = { setData: () => null, getData: () => null };
      // @ts-ignore
      window.ClassicEditor.create(document.querySelector(`#${name}`), {
        initialData: data,
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "indent",
            "outdent",
            "|",
            "imageUpload",
            "blockQuote",
            "mediaEmbed",
            "undo",
            "redo",
          ],
        },
        language: "en",
        image: {
          toolbar: [
            "imageTextAlternative",
            "imageStyle:full",
            "imageStyle:side",
          ],
        },
        licenseKey: "",
      })
        .then((ed) => {
          const modelDocument = ed.model.document;
          modelDocument.on("change:data", (event) => {
            const data = ed.getData();
            onChange(data);
          });
          editor.current = ed;
        })
        .catch((err) => {
          console.error(err.stack);
        });
    }
  }, [editor.current]);

  useEffect(() => {
    if (editor.current && data) {
      // @ts-ignore
      const ed = editor.current.getData();
      if (!ed && data) {
        // @ts-ignore
        editor.current.setData(data);
      }
    }
  }, [editor.current, data]);

  return <div id={name} />;
}
