import React, { useEffect, useState } from "react";
import Header from "../../../Navs/Header";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";

import ImageLabel from "../../../../assets/images/image_label.png";
import DocsLabel from "../../../../assets/images/image_label.png";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";

import { getDroppedOrSelectedFiles } from "html5-file-selector";
import { useLocation } from "react-router";
import baseUrl from "../../../../Http/backend-base-url";
import UseEditFormRoute from "./requestRoute/UseEditFormRoute";
import { NavLink } from "react-router-dom";

function EditPointInteret() {
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    setValue,
    unregister,
    clearErrors,
  } = UseEditFormRoute();

  const [image, setImage] = useState(null);

  const location = useLocation();

  useEffect(() => {
    if (location?.state?.route) {
      register("name");
      setValue("name", location.state.route?.name);
      register("description");
      setValue("description", location.state.route?.description);
      register("id");
      setValue("id", location.state.route?.id);
    }
  }, [location]);

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);
  const getUploadParams = ({ meta }) => {
    // console.log(typeof meta);
    return { url: "https://httpbin.org/post" };
  };
  const handleChangeStatus = ({ meta, file }, status) => {
    file.height = meta.height;
    // console.log(file);
  };
  const getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };
  const InputChooseFile = ({ accept, onFiles, files, getFilesFromEvent }) => {
    // console.log("files", files);
    const text = files.length === 0 && (
      // 'Ajouter encore des photos' :
      <div className="start-label">
        <div className="label-img-container">
          <img src={ImageLabel} className="label-image" alt="Label" />
        </div>
        <span className="first_label">
          Déposez une image ici ou sélectionnez un fichier.
        </span>
        <span className="second-label">
          Format acceptés : JPG, PNG, GIF, TIFF ou BMP, inférieurs à 2Mo.
        </span>
      </div>
    );
    let buttonStartStyle = {
      background: "rgb(255, 152, 0)",
      cursor: "pointer",
      padding: 15,
      borderRadius: 30,
      marginTop: 10,
      textAlign: "center",
      maxWidth: "30%",
      marginLeft: "35%",
      color: "#fff",
      fontWeight: 700,
    };

    if (files.length <= 0) {
      buttonStartStyle = {
        // color: '#ff9800',
        cursor: "pointer",
        padding: 15,
        borderRadius: 30,
        marginTop: 10,
        textAlign: "center",
      };
    }

    return (
      <div
        className="row upload-dropzone-row"
        onClick={() => window.$("#fileOpen").click()}
      >
        <div style={buttonStartStyle} className="col-md-6 offset-md-3">
          {text}
          <input
            style={{ opacity: 0 }}
            className="file-input"
            type="file"
            id="fileOpen"
            accept={accept}
            multiple
            onChange={(e) => {
              getFilesFromEvent(e).then((chosenFiles) => {
                onFiles(chosenFiles);
              });
            }}
          />
        </div>
      </div>
    );
  };
  return (
    <div className="component-add-salarie">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 beny">
            <div className="page-body-container">
              <div>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <NavLink to="/gestion-des-routes" className="nav-prev">
                        Point d’interêt
                      </NavLink>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Modification d’un point d’interêt
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="page-content-container">
                <div className="page-first-row-container pd-page">
                  <div className="page-title-container">
                    <h1 className="page-title">
                      Modification d’un point d’interêt
                    </h1>
                  </div>
                </div>
                <div className="parent-table-container">
                  <div className="pt-4">
                    <form onSubmit={onSubmit}>
                      <div className="row d-flex align-items-center">
                        <div className="col-xl-6 col-lg-6 col-md-6 pb-3">
                          <div className="form-group">
                            <label className="label-input">
                              Nom du point d’interêt{" "}
                            </label>
                            <input
                              name="name"
                              {...register("name")}
                              type="text"
                              className="bloc-div form-control"
                              placeholder="Nom du point d'interêt"
                            />
                          </div>
                          {formState.errors && formState.errors.name && (
                            <div className="alert alert-danger gfa-alert-danger">
                              {formState.errors.name?.message}
                            </div>
                          )}
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 pb-3">
                          <div className="form-group">
                            <label className="label-input">Description </label>
                            {/* <input
                              name="description"
                              {...register("description")}
                              type="text"
                              className="bloc-div form-control"
                              placeholder="Description"
                            /> */}
                            <textarea
                              id="description"
                              name="description"
                              rows="3"
                              cols="50"
                              {...register("description")}
                              className="bloc-div form-control"
                              placeholder="Description"
                            ></textarea>
                          </div>
                          {formState.errors && formState.errors.description && (
                            <div className="alert alert-danger gfa-alert-danger">
                              {formState.errors.description?.message}
                            </div>
                          )}
                        </div>
                        <div className="col-md-6 py-3 w-full py-3">
                          <div className="form-group">
                            <label className="label-input">Photo </label>
                            <div className="input-file">
                              <Dropzone
                                getUploadParams={getUploadParams}
                                onChangeStatus={handleChangeStatus}
                                // onSubmit={handleSubmit}
                                accept="image/*"
                                autoUpload
                                styles={{
                                  dropzoneReject: {
                                    borderColor: "#F19373",
                                    backgroundColor: "#F1BDAB",
                                  },
                                  inputLabel: (files, extra) =>
                                    extra.reject ? { color: "#A02800" } : {},
                                }}
                                maxFiles={1}
                                // inputWithFilesContent={(files) => {}}
                                inputWithFilesContent={(files) => {
                                  register("photo");
                                  setValue("photo", files[0]?.file);
                                  setImage(files[0]?.file);
                                }}
                                InputComponent={InputChooseFile}
                                getFilesFromEvent={getFilesFromEvent}
                                classNames
                              />
                            </div>
                            {formState.errors && formState.errors.photo && (
                              <div className="alert alert-danger gfa-alert-danger">
                                {formState.errors.photo?.message}
                              </div>
                            )}
                          </div>
                        </div>
                        {/* Images */}
                        <div className="col-md-6 py-3 w-full py-3">
                          <div className="form-group d-flex justify-content-center">
                            <img
                              src={
                                image
                                  ? URL.createObjectURL(image)
                                  : baseUrl + location?.state?.route?.photo
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row my-5 d-flex justify-content-end">
                        <div className="col-xl-2 col-lg-2 col-md-2">
                          {!isSuccessfullySubmitted && (
                            <button className="btn-theme-plus">
                              ENREGISTRER
                            </button>
                          )}

                          {isSuccessfullySubmitted && (
                            <button className="btn-theme-plus">
                              En cours &nbsp;
                              <i className="fas fa-spin fa-spinner"></i>
                            </button>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditPointInteret;
