import React, { useEffect } from "react";
import "react-phone-input-2/lib/bootstrap.css";
import useTransversaleForm from "../Backend/Speero/Parametres/requestAdmin/useTransversaleForm";
// import * as IconlyPack from 'react-iconly';

var TransversaleModal = ({ item, modalId }) => {
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    reset,
    clearErrors,
  } = useTransversaleForm(item, modalId);

  useEffect(() => {
    if (formState?.errors) {
      setTimeout(() => {
        Object?.entries(formState?.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  const handleReset = () => {
    if (!item) {
      reset();
    }
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AddingFamilyModal">
            {item ? "Modifier" : "Ajouter"} une transversale
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={onSubmit}>
              <div className="row crud-row">
                <div className="col-md-12">
                  <div className="form-group crud-form-group">
                    <label for="fullname">Nom</label>
                    <input
                      type="text"
                      placeholder="Nom"
                      name="name"
                      {...register("name")}
                      className="form-control crud-form-control"
                      id="name"
                    />
                    {formState.errors && formState.errors.name && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.name?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn"
                    data-dismiss="modal"
                    onClick={() => handleReset()}
                  >
                    Retour
                  </button>
                  {isSuccessfullySubmitted && (
                    <button className="btn-theme-plus" disabled>
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isSuccessfullySubmitted && (
                    <button className="btn-theme-plus">Enregistrer</button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransversaleModal;
