import React, { useEffect } from "react";
// import { NavLink } from 'react-router-dom'
import FeatherIcon from "feather-icons-react";
import { ToastContainer } from "react-toastify";
// import isAuthenticate from './../modules/Secure/Secure'
import ResetPasswordModal from "./ResetPasswordModal";
import EmptyHeader from "./EmptyHeader";
import "./Login.css";
import { Link, NavLink } from "react-router-dom";
import * as IconlyPack from "react-iconly";
import UseLoginForm from "./UseLoginForm";

function Connexion() {
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    clearErrors,
    setIsFormRequestError,
    isFormRequestError,
    watch,
    reset,
    setValue,
  } = UseLoginForm();

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);
  return (
    <div className="connexion-page">
      <EmptyHeader />
      <div className="login-page-container">
        <section className="auth-page-content">
          <form onSubmit={onSubmit}>
            <div className="row auth-page-row">
              <div className="col-md-4 offset-md-4 auth-page-col">
                <div className="row auth-form-row">
                  <h1 className="auth-form-title m-b-90">Connectez-vous</h1>
                  <div className="col-md-12 auth-input-col m-b-20">
                    <div className="form-group auth-form-group position-relative">
                      <span className="icon-idenfiant">
                        {" "}
                        <IconlyPack.User set="bold" primaryColor="#C4C4C4" />
                      </span>
                      <input
                        name="email"
                        type="email"
                        {...register("email")}
                        className="form-control auth-form-control pl-5"
                        id="identifiant"
                        placeholder="Identifiant"
                      />
                      {formState.errors && formState.errors.email && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.email?.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 auth-input-col m-b-5">
                    <div className="form-group auth-form-group position-relative">
                      <span className="icon-password">
                        <IconlyPack.Lock set="bold" primaryColor="#C4C4C4" />
                      </span>
                      <input
                        name="password"
                        type="password"
                        {...register("password")}
                        className="form-control auth-form-control pl-5"
                        id="password"
                        placeholder="Mot de passe"
                      />
                      {formState.errors && formState.errors.password && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.password?.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 auth-input-col m-b-5 py-3">
                    <div className="form-group auth-form-group">
                      <Link
                        to="/mot-de-passe-oublier"
                        className="btn  auth-reset-password-btn"
                      >
                        Mot de passe oublié ?
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-6 offset-md-3 d-flex justify-content-center auth-input-col m-b-5">
                    {isSuccessfullySubmitted === false ? (
                      <button className="btn btn-primary auth-submit-btn">
                        Connexion
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary auth-submit-btn"
                        disabled
                      >
                        Connexion en cours &nbsp;
                        <i className="fas fa-spin fa-spinner"></i>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </div>
    </div>
  );
}

export default Connexion;
