import { route } from "./Endpoints";
import axios from "axios";

export default {
  async onPostRoute(data, config) {
    const response = await axios.post(route.onPostRoute(), data, config);
    return response.data;
  },
  async onEditRoute(id, data, config) {
    const response = await axios.put(route.onEditRoute(id), data, config);
    return response.data;
  },
  async onDeleteRoute(id, config) {
    const response = await axios.delete(route.onDeleteRoute(id), config);
    return response.data;
  },
};
