import React, { useContext, useEffect, useState } from "react";
import Header from "../../../Navs/Header";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import Skeleton from "react-loading-skeleton";
import * as IconlyPack from "react-iconly";
import { useHistory } from "react-router-dom";
import Profil from "../../../../images/others/default-profile-avatar.png";
import "./GestionContact.css";
import ContactData from "./ContactData";
import DetailsMessage from "./DetailsMessage";
import { DataContext } from "../../../../redux/GlobalState";
import moment from "moment";
var GestionConatct = () => {
  const { state, dispatch } = useContext(DataContext);
  const { contacts, auth } = state;
  const history = useHistory();
  var [isPageLoading, setIsPageLoading] = React.useState(true);

  var [messageContact, setMessageContact] = React.useState(ContactData);
  var statut = messageContact.statut;
  var [message, setMessage] = useState({});
  useEffect(() => {
    if (contacts?.data?.length && !message?.id) {
      setMessage(contacts.data[0]);
    }
  }, [contacts]);
  const onReadMessage = (item) => {
    setMessage(item);
    // console.log(item, "setttt");
  };

  if (window.sessionStorage.getItem("contact") !== "true") {
    history.goBack();
    return null;
  }

  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 soinsy">
            <div className="page-body-container ">
              <div className="page-first-row-container">
                <div className="page-title-container">
                  <h1 className="page-title">Contacts</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-6">
                  <div className="page-content-container pr-0 pt-0">
                    <div className="parent-table-container">
                      <ul class="list-group list-group-flush">
                        {contacts?.isLoading && <ActivitiesSkeleton />}
                        {!contacts?.isLoading &&
                          contacts?.data?.map((item, index) => (
                            <li
                              className={`list-group-item linkable ${
                                item?.id === message?.id ? "active" : ""
                              }`}
                              key={item?.id}
                              onClick={() => onReadMessage(item)}
                            >
                              <div>
                                <div className="bloc-profil-contact">
                                  <div className="d-flex align-items-center w-70">
                                    <img
                                      src={Profil}
                                      className="img-messge-contact"
                                      alt="image profil contact"
                                    />
                                    <p
                                      className={`nom-contact pl-3 ${
                                        item?.id === message?.id ? "active" : ""
                                      }`}
                                      style={{
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {item?.email}{" "}
                                    </p>
                                  </div>
                                  <div>
                                    <p className="time-message-contact">
                                      {moment(item?.created_at).format(
                                        "DD/MM/YYYY, hh:mm"
                                      )}
                                    </p>
                                  </div>
                                </div>
                                <div className="bloc-message-contact pt-2">
                                  <div className="w-85">
                                    <p
                                      className="contenu-message-contact pl-5 linkable"
                                      style={{
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {item?.message}
                                    </p>
                                  </div>
                                  <div className="">
                                    <span className="">
                                      {item?.status === "traité" ? (
                                        <span className="">
                                          <IconlyPack.TickSquare
                                            set="light"
                                            primaryColor="#2ED47A"
                                          />
                                        </span>
                                      ) : item?.status === "new" ? (
                                        <span className="">
                                          <IconlyPack.InfoSquare
                                            set="light"
                                            primaryColor="#FF3C5F"
                                          />
                                        </span>
                                      ) : (
                                        <span className="">
                                          <IconlyPack.TimeSquare
                                            set="light"
                                            primaryColor="#272C96"
                                          />
                                        </span>
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-8 col-md-6">
                  <DetailsMessage message={message} user={auth?.user} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionConatct;

const ActivitiesSkeleton = () => {
  const renderModules = Array(6)
    .fill()
    .map((item, index) => (
      <li class="list-group-item" key={index}>
        <tr>
          <td className="bloc-profil-contact">
            <div className="d-flex align-items-center w-70">
              <Skeleton
                circle={true}
                width={35}
                height={35}
                className="img-messge-contact"
              />
              <Skeleton width={230} className="nom-contact pl-3" />
            </div>
          </td>
          <td className="bloc-message-contact pt-2 ml-3">
            <div className="w-85">
              <Skeleton width={250} height={30} />
            </div>
          </td>
        </tr>
      </li>
    ));
  return <React.Fragment>{renderModules}</React.Fragment>;
};
