import { direction } from "./Endpoints";
import axios from "axios";

export default {
  async onPostDirection(data, config) {
    const response = await axios.post(
      direction.onPostDirection(),
      data,
      config
    );
    return response.data;
  },
  async onEditDirection(id, data, config) {
    const response = await axios.put(
      direction.onEditDirection(id),
      data,
      config
    );
    return response.data;
  },
  async onDeleteDirection(id, config) {
    const response = await axios.delete(
      direction.onDeleteDirection(id),
      config
    );
    return response.data;
  },
};
