import React from "react";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import isAuthenticate from "./../src/Components/Modules/Secure/Secure";

import Connexion from "./Components/Auth/Connexion";
import Privacy from "./Components/Auth/Privacy";
import ModifierMotDePasse from "./Components/Auth/ModifierMotDePasse";
import MotDePasseOblier from "./Components/Auth/MotDePasseOblier";
import ResetPassword from "./Components/Auth/ResetPassword";
import Dashboard from "./Components/Backend/Speero/Dashboard/Dashboard";
import GestionConatct from "./Components/Backend/Speero/GestionContact/GestionContact";
import DetailsEvenement from "./Components/Backend/Speero/GestionEvenement/DetailsEvenement";
import GestionEvenement from "./Components/Backend/Speero/GestionEvenement/GestionEvenement";
import AjouterPointInteret from "./Components/Backend/Speero/GestionRoutes/AjouterPointInteret";
import DetailsPointInteret from "./Components/Backend/Speero/GestionRoutes/DetailsPointInteret";
import GestionRoutes from "./Components/Backend/Speero/GestionRoutes/GestionRoutes";
import GestionUtilisateur from "./Components/Backend/Speero/GestionUtilisateur/GestionUtilisateur";
import MonProfil from "./Components/Backend/Speero/MonProfil/MonProfil";
import Parametre from "./Components/Backend/Speero/Parametre/Parametre";

import { Toaster } from "react-hot-toast";
import AjoutBanner from "./Components/Backend/Speero/GestionBanner/AjoutBanner";
import EditBanner from "./Components/Backend/Speero/GestionBanner/EditBanner";
import GestionBanner from "./Components/Backend/Speero/GestionBanner/GestionBanner";
import AjoutDirection from "./Components/Backend/Speero/GestionDirection/AjoutDirection";
import EditDirection from "./Components/Backend/Speero/GestionDirection/EditDirection";
import GestionDirection from "./Components/Backend/Speero/GestionDirection/GestionDirection";
import AjoutMarketing from "./Components/Backend/Speero/GestionMarketing/AjoutMarketing";
import DetailsMarketing from "./Components/Backend/Speero/GestionMarketing/DetailsMarketing";
import EditMarketing from "./Components/Backend/Speero/GestionMarketing/EditMarketing";
import GestionMarketing from "./Components/Backend/Speero/GestionMarketing/GestionMarketing";
import EditPointInteret from "./Components/Backend/Speero/GestionRoutes/EditPointInteret";
import UserDetails from "./Components/Backend/Speero/GestionUtilisateur/UserDetails";
import GestionTransversales from "./Components/Backend/Speero/Parametre/GestionTransversales";

function App() {
  var isLogInExpired = isAuthenticate();
  const token = window.sessionStorage.getItem("userToken");

  return (
    <Router>
      <div className="router-container">
        <Toaster />
        <Switch>
        <Route path="/privacy">
            <Privacy />
          </Route>
          <Route path="/dashboard">
            {isLogInExpired || !token ? <Redirect to="/" /> : <Dashboard />}
          </Route>
          <Route exact path="/">
            {token ? <Redirect to="/dashboard" /> : <Connexion />}
          </Route>
          <Route
            path="/mot-de-passe-oublier"
            component={MotDePasseOblier}
          ></Route>
          <Route
            path="/modifier-password"
            component={ModifierMotDePasse}
          ></Route>

          <Route
            path="/modifier-mon-mot-de-passe"
            component={ResetPassword}
          ></Route>
          <Route path="/gestion-des-directions">
            {isLogInExpired || !token ? (
              <Redirect to="/" />
            ) : (
              <GestionDirection />
            )}
          </Route>
          <Route path="/ajouter-direction">
            {isLogInExpired || !token ? (
              <Redirect to="/" />
            ) : (
              <AjoutDirection />
            )}
          </Route>

          <Route path="/modifier-direction">
            {isLogInExpired || !token ? <Redirect to="/" /> : <EditDirection />}
          </Route>

          <Route path="/gestion-des-routes">
            {isLogInExpired || !token ? <Redirect to="/" /> : <GestionRoutes />}
          </Route>
          <Route path="/ajouter-point-interet">
            {isLogInExpired || !token ? (
              <Redirect to="/" />
            ) : (
              <AjouterPointInteret />
            )}
          </Route>
          <Route path="/details-point-interet">
            {" "}
            {isLogInExpired || !token ? (
              <Redirect to="/" />
            ) : (
              <DetailsPointInteret />
            )}
          </Route>
          <Route path="/modifier-point-interet">
            {isLogInExpired || !token ? (
              <Redirect to="/" />
            ) : (
              <EditPointInteret />
            )}
          </Route>
          <Route path="/gestion-des-evenement">
            {isLogInExpired || !token ? (
              <Redirect to="/" />
            ) : (
              <GestionEvenement />
            )}
          </Route>
          <Route path="/details-evenement">
            {isLogInExpired || !token ? (
              <Redirect to="/" />
            ) : (
              <DetailsEvenement />
            )}
          </Route>
          <Route path="/gestion-des-contacts">
            {isLogInExpired || !token ? (
              <Redirect to="/" />
            ) : (
              <GestionConatct />
            )}
          </Route>

          <Route path="/gestion-des-utilisateurs">
            {isLogInExpired || !token ? (
              <Redirect to="/" />
            ) : (
              <GestionUtilisateur />
            )}
          </Route>
          <Route path="/details-utilisateur">
            {isLogInExpired || !token ? <Redirect to="/" /> : <UserDetails />}
          </Route>
          <Route path="/gestion-des-banniers">
            {isLogInExpired || !token ? <Redirect to="/" /> : <GestionBanner />}
          </Route>
          <Route path="/ajout-banniere">
            {isLogInExpired || !token ? <Redirect to="/" /> : <AjoutBanner />}
          </Route>
          <Route path="/modifier-banniere">
            {isLogInExpired || !token ? <Redirect to="/" /> : <EditBanner />}
          </Route>
          <Route path="/gestion-marketing">
            {isLogInExpired || !token ? (
              <Redirect to="/" />
            ) : (
              <GestionMarketing />
            )}
          </Route>
          <Route path="/gestion-transversales">
            {isLogInExpired || !token ? (
              <Redirect to="/" />
            ) : (
              <GestionTransversales />
            )}
          </Route>
          <Route path="/envoi-notification">
            {isLogInExpired || !token ? (
              <Redirect to="/" />
            ) : (
              <AjoutMarketing />
            )}
          </Route>
          <Route path="/modifier-notification">
            {isLogInExpired || !token ? <Redirect to="/" /> : <EditMarketing />}
          </Route>
          <Route path="/details-notification">
            {isLogInExpired || !token ? (
              <Redirect to="/" />
            ) : (
              <DetailsMarketing />
            )}
          </Route>
          <Route path="/profil">
            {isLogInExpired || !token ? <Redirect to="/" /> : <MonProfil />}
          </Route>
          <Route path="/parametre">
            {isLogInExpired || !token ? <Redirect to="/" /> : <Parametre />}
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
