import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const BannerSkeleton = () => {
  const renderSkeleton = Array(8)
    .fill()
    .map((item, index) => (
      <div className="row pb-3 skeleton-row" key={`skel${index}${item}`}>
        <div className="col-xl-8 col-lg-8 col-md-10 pb-3">
          <div className="shadow-category p-3">
            <div className="bloc-img-prestation bloc-category-inline">
              <Skeleton height={120} width={200} />
            </div>
            <div className="bloc-category-inline">
              <div className="bloc-liste pl-3">
                <span className="titre-category-prestation pb-4">
                  {" "}
                  <Skeleton width={150} />{" "}
                </span>
              </div>
              <div className="sub-categories-container pl-3">
                <Skeleton width={60} />
                &nbsp;&nbsp;
                <Skeleton width={60} />
                &nbsp;&nbsp;
                <Skeleton width={60} />
                &nbsp;&nbsp;
                <Skeleton width={60} />
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-2 col-lg-2 col-md-4 pt-2 pb-3">
          <div className="d-flex">
            <div>
              <Skeleton circle={true} height={40} width={40} />
            </div>
            &nbsp;&nbsp;
            <div>
              <Skeleton circle={true} height={40} width={40} />
            </div>
          </div>
        </div>
      </div>
    ));

  return (
    <SkeletonTheme color="#E0E4E2" highlightColor="#D1D5D3">
      {renderSkeleton}
    </SkeletonTheme>
  );
};

export default BannerSkeleton;
