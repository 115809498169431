import React from "react";
import Header from "../../../Navs/Header";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import * as IconlyPack from "react-iconly";
import GestionUtilisateurTable from "../../../Tables/GestionUtilisateurTable/GestionUtilisateurTable";
import "./GestionUtilisateur.css";
import { Link, useHistory } from "react-router-dom";
import NavbarMobileV2 from "../../navbarMobileV2/navbarMobileV2";

var GestionUtilisateur = () => {
  const history = useHistory();

  if (window.sessionStorage.getItem("list_users") !== "true") {
    history.goBack();
    return null;
  }
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />
        <div className="row">
          {/* Navbar Mobile */}
          <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
            <NavbarMobileV2 />
          </div>
        </div>
        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 soinsy">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Liste des utilisateurs</h1>
                  </div>
                </div>
                <div className="parent-table-container">
                  <GestionUtilisateurTable />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionUtilisateur;
