import React from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/ie9";
import ReactDOM from "react-dom";
import App from "./App";
// import './index.scss';
import "./index.css";
import { DataProvider } from "./redux/GlobalState";
import * as serviceWorker from "./serviceWorker";

// import ReactGA from 'react-ga';
// ReactGA.initialize('UA-184919919-1');
// ReactGA.pageview(window.location.pathname + window.location.search);

function noop() {}

ReactDOM.render(
  <DataProvider>
    <App />
  </DataProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
