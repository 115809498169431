import React, { useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import UseCreateAdminForm from "../Backend/Speero/Parametres/requestAdmin/UseCreateAdminForm";
// import * as IconlyPack from 'react-iconly';

var CRUDAdminModal = () => {
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    setValue,
    reset,
    clearErrors,
    isSuccess,
  } = UseCreateAdminForm();
  const [phone, setPhone] = React.useState("");

  const handleSetPhone = (val) => {
    setPhone(val);
    if (val && val?.length > 5) {
      register("phone");
      setValue("phone", "+" + val);
    } else {
      setValue("phone", "");
    }
    // console.log("val", val);
  };

  useEffect(() => {
    if (formState?.errors) {
      setTimeout(() => {
        Object?.entries(formState?.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  useEffect(() => {
    if (isSuccess) {
      setPhone("+221");
    }
  }, [isSuccess]);

  const handleReset = () => {
    setPhone("+221");
    reset();
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AddingFamilyModal">
            Ajouter un administrateur
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={onSubmit}>
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label for="fullname">Nom Complet</label>
                    <input
                      type="text"
                      placeholder="Nom complet"
                      name="fullname"
                      {...register("fullname")}
                      className="form-control crud-form-control"
                      id="fullname"
                    />
                    {formState.errors && formState.errors.fullname && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.fullname?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label for="email">Email</label>
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      {...register("email")}
                      className="form-control crud-form-control"
                      id="email"
                    />
                    {formState.errors && formState.errors.email && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.email?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label for="telephone">Téléphone</label>
                    {/* <input
                      type="tel"
                      placeholder="Téléphone"
                      name="phone"
                      {...register("phone")}
                      className="form-control crud-form-control"
                      id="telephone"
                    /> */}
                    <PhoneInput
                      masks={{ sn: ".. ... .. .." }}
                      inputClassNclassName="form-control crud-form-control"
                      country={"sn"}
                      // onlyCountries={['sn']}
                      inputProps={{
                        name: "telephone",
                        required: true,
                        autoFocus: true,
                      }}
                      // disableDropdown={true}
                      enableAreaCodes={true}
                      prefix="+"
                      value={phone}
                      countryCodeEditable={false}
                      onChange={(phone) => handleSetPhone(phone)}
                      inputStyle={{
                        width: "100%",
                        paddingBottom: "22px",
                        borderRadius: "10px",
                      }}
                    />
                    {formState.errors && formState.errors.phone && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.phone?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label for="last_name">Adresse</label>
                    <input
                      type="text"
                      placeholder="Adresse"
                      name="adresse"
                      {...register("adresse")}
                      className="form-control crud-form-control"
                      id="last_name"
                    />
                    {formState.errors && formState.errors.adresse && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.adresse?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-12 crud-col-item">
                  <div className="form-group crud-form-group ">
                    <label for="adresse">Permissions</label>
                    <div className="row">
                      <div className="col-md-4 test">
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            name="dashboard"
                            {...register("dashboard")}
                            id="customControlValidation1"
                          />
                          <label
                            className="custom-control-label"
                            for="customControlValidation1"
                          >
                            Accés au tableau de bord
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4 test">
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            name="route"
                            {...register("route")}
                            id="customControlValidation2"
                          />
                          <label
                            className="custom-control-label"
                            for="customControlValidation2"
                          >
                            Accés aux points d'intérêts
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4 test">
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            name="direction"
                            {...register("direction")}
                            id="customControlValidation3"
                          />
                          <label
                            className="custom-control-label"
                            for="customControlValidation3"
                          >
                            Accés aux directions
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4 test">
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            name="evenement"
                            {...register("evenement")}
                            // onChange={(e) => handleChange(e)}
                            // value="permission4"
                            id="customControlValidation4"
                          />
                          <label
                            className="custom-control-label"
                            for="customControlValidation4"
                          >
                            Accés aux événements
                          </label>
                        </div>
                      </div>

                      <div className="col-md-4 test">
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            name="banner"
                            {...register("banner")}
                            // onChange={(e) => handleChange(e)}
                            // value="permission4"
                            id="customControlValidation9"
                          />
                          <label
                            className="custom-control-label"
                            for="customControlValidation9"
                          >
                            Accés aux bannieres
                          </label>
                        </div>
                      </div>

                      <div className="col-md-4 test">
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            name="list_users"
                            {...register("list_users")}
                            // onChange={(e) => handleChange(e)}
                            // value="permission4"
                            id="customControlValidation10"
                          />
                          <label
                            className="custom-control-label"
                            for="customControlValidation10"
                          >
                            Accés aux utilisateurs
                          </label>
                        </div>
                      </div>

                      <div className="col-md-4 test">
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            name="list_admins"
                            {...register("list_admins")}
                            // onChange={(e) => handleChange(e)}
                            // value="permission4"
                            id="customControlValidation11"
                          />
                          <label
                            className="custom-control-label"
                            for="customControlValidation11"
                          >
                            Accés aux administrateurs
                          </label>
                        </div>
                      </div>

                      <div className="col-md-4 test">
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            name="contact"
                            {...register("contact")}
                            // onChange={(e) => handleChange(e)}
                            // value="permission4"
                            id="customControlValidation8"
                          />
                          <label
                            className="custom-control-label"
                            for="customControlValidation8"
                          >
                            Accés aux messages
                          </label>
                        </div>
                      </div>

                      <div className="col-md-4 test">
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            name="profil"
                            {...register("profil")}
                            // onChange={(e) => handleChange(e)}
                            // value="permission4"
                            defaultChecked={true}
                            id="customControlValidation12"
                          />
                          <label
                            className="custom-control-label"
                            for="customControlValidation12"
                          >
                            Accés à son profil
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn"
                    data-dismiss="modal"
                    onClick={() => handleReset()}
                  >
                    Retour
                  </button>
                  {/* <button className="btn btn-theme-plus">Enregistrer</button> */}
                  {isSuccessfullySubmitted && (
                    <button className="btn-theme-plus" disabled>
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isSuccessfullySubmitted && (
                    <button className="btn-theme-plus">Enregistrer</button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CRUDAdminModal;
