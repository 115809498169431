import React, { useEffect, useState } from "react";
import Header from "../../../Navs/Header";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import * as IconlyPack from "react-iconly";
import { NavLink, useLocation } from "react-router-dom";
import Img from "../../../../images/route.png";
import baseUrl from "../../../../Http/backend-base-url";
import NavbarMobileV2 from "../../navbarMobileV2/navbarMobileV2";

function DetailsMarketing() {
  const [marketing, setMarketing] = useState({});
  const location = useLocation();

  useEffect(() => {
    if (location?.state?.marketing) {
      setMarketing(location.state.marketing);
    }
    return () => {
      setMarketing({});
    };
  }, [location]);
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />
        <div className="row">
          {/* Navbar Mobile */}
          <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
            <NavbarMobileV2 />
          </div>
        </div>

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 soinsy">
            <div className="page-body-container">
              <div>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <NavLink to="/gestion-marketing" className="nav-prev">
                        Notifications
                      </NavLink>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      {marketing?.titre}
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="page-content-container">
                <div className="parent-table-container">
                  <div className="page-first-row-container">
                    <div className="page-title-container">
                      <h1 className="page-title">Détails</h1>
                    </div>
                  </div>
                  <div className="list-marge-right pt-4">
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item pl-0">
                        <div className="list-detail-point-interet">
                          <div className="mr-5">
                            <p className="list-libele">Titre</p>
                          </div>
                          <div>
                            <p className="list-contenu">{marketing?.titre}</p>
                          </div>
                          <div>
                            {/* <button className="list-btn btn">Modifier</button> */}
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item pl-0">
                        <div className="list-detail-point-interet">
                          <div className="mr-5">
                            <p className="list-libele">Message</p>
                          </div>
                          <div>
                            <p className="list-contenu">{marketing?.message}</p>
                          </div>
                          <div>
                            {/* <button className="list-btn btn">Modifier</button> */}
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item pl-0">
                        <div className="list-detail-point-interet">
                          <div className="mr-5">
                            <p className="list-libele">Envoyée par</p>
                          </div>
                          <div>
                            <p className="list-contenu">
                              {marketing?.admin?.fullname}
                            </p>
                          </div>
                          <div>
                            {/* <button className="list-btn btn">Modifier</button> */}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="pt-5">
                    <div className="row">
                      <div className="col-xl-4 col-lg-4 col-md-4">
                        <div>
                          <p className="list-libele">Image</p>
                          <div className="d-flex pt-4">
                            <img src={baseUrl + marketing?.image} />
                            <div className="pt-3 pl-3">
                              {/* <button className="btn list-btn">Modifier</button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsMarketing;
