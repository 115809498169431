import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Select from "react-select";
import { DataContext } from "../../../../redux/GlobalState";
import Header from "../../../Navs/Header";
import NavbarMobileV2 from "../../navbarMobileV2/navbarMobileV2";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import useCreateDirectionForm from "./requestDirection/useCreateDirectionForm";

function AjoutDirection() {
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    setValue,
    reset,
    clearErrors,
  } = useCreateDirectionForm();
  const { state, dispatch } = useContext(DataContext);
  const { routes } = state;
  const [options, setOptions] = useState([]);
  const [route, setRoute] = useState("");

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  useEffect(() => {
    let data = [];
    if (!routes?.isLoading && routes?.data?.length) {
      data = routes.data.map((item) => {
        return {
          label: item?.name,
          value: item?.id,
        };
      });
    }
    // console.log("data", data);
    setOptions(data);
  }, [routes]);

  const handleSelect = (option) => {
    setValue("route", option.value);
    setRoute(option);
  };
  return (
    <div className="component-add-salarie">
      <div className="container-fluid body-background-dashboard">
        <Header />
        <div className="row">
          {/* Navbar Mobile */}
          <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
            <NavbarMobileV2 />
          </div>
        </div>
        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 beny">
            <div className="page-body-container">
              <div>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <NavLink
                        to="/gestion-des-directions"
                        className="nav-prev"
                      >
                        Directions
                      </NavLink>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Ajout d’une direction
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="page-content-container">
                <div className="page-first-row-container pd-page">
                  <div className="page-title-container">
                    <h1 className="page-title">Ajout d’une direction</h1>
                  </div>
                </div>
                <div className="parent-table-container">
                  <div className="pt-4">
                    <form onSubmit={onSubmit}>
                      <div className="row d-flex align-items-center">
                        <div className="col-xl-6 col-lg-6 col-md-6 pb-3">
                          <div className="form-group">
                            <label className="label-input">
                              Nom de la direction{" "}
                            </label>
                            <input
                              name="name"
                              {...register("name")}
                              type="text"
                              className="bloc-div form-control"
                              placeholder="Nom du point d'interêt"
                            />
                          </div>
                          {formState.errors && formState.errors.name && (
                            <div className="alert alert-danger gfa-alert-danger">
                              {formState.errors.name?.message}
                            </div>
                          )}
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 pb-3">
                          <div className="form-group">
                            <label className="label-input">Route </label>

                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              placeholder="Selectionner la route"
                              isLoading={routes?.isLoading}
                              name="route"
                              value={route}
                              options={options}
                              onChange={handleSelect}
                            />

                            {formState.errors && formState.errors.route && (
                              <div className="alert alert-danger gfa-alert-danger">
                                {formState.errors.route?.message}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row my-5 d-flex justify-content-end">
                        <div className="col-xl-2 col-lg-2 col-md-2">
                          {!isSuccessfullySubmitted && (
                            <button className="btn-theme-plus">
                              ENREGISTRER
                            </button>
                          )}

                          {isSuccessfullySubmitted && (
                            <button className="btn-theme-plus">
                              En cours &nbsp;
                              <i className="fas fa-spin fa-spinner"></i>
                            </button>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjoutDirection;
