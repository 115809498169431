import React, { useContext } from "react";
import './Header.css'
import FeatherIcon from "feather-icons-react";
import * as IconlyPack from "react-iconly";
import userAvatar from "../../images/icons/userAvatar.png";
import NavBarMobileV2 from "../Backend/navbarMobileV2/navbarMobileV2";
import { NavLink, useHistory } from "react-router-dom";
import { DataContext } from "../../redux/GlobalState";
import ImgNotif from "../../images/profil.png";
import moment from "moment";
import { getData, putData } from "../../utils/fetchData";
import baseUrl from "../../Http/backend-base-url";

var Header = () => {
  const { state, dispatch } = useContext(DataContext);
  const { auth, notifications } = state;
  const history = useHistory();
  const token = sessionStorage.getItem("userToken");
  //   var [showActions, setShowActions] = React.useState(false);
  var [showNotifications, setShowNotifications] = React.useState(false);
  const [notifs, setNotifs] = React.useState([]);

  React.useEffect(() => {
    if (token || auth?.token) {
      getData("route", token)
        .then((response) => {
          // console.log("response routes", response);
          dispatch({
            type: "LIST_ROUTES",
            payload: {
              isLoading: false,
              data: response.results.sort((a, b) => b?.id - a?.id),
            },
          });
        })
        .catch((error) => {
          console.log("error routes", error?.response);
        });
      getData("direction", token)
        .then((response) => {
          // console.log("direction", response);
          dispatch({
            type: "LIST_DIRECTIONS",
            payload: {
              isLoading: false,
              data: response.results.sort((a, b) => b?.id - a?.id),
            },
          });
        })
        .catch((error) => {
          console.log("error direction", error?.response);
        });

      getData("event", token)
        .then((response) => {
          // console.log("response events", response);
          dispatch({
            type: "LIST_EVENTS",
            payload: {
              isLoading: false,
              data: response.results.sort((a, b) => b?.id - a?.id),
            },
          });
        })
        .catch((error) => {
          console.log("error events", error?.response);
        });

      getData("user", token)
        .then((response) => {
          dispatch({
            type: "LIST_USERS",
            payload: {
              isLoading: false,
              data: response.results
                .filter(
                  (user) =>
                    user?.user_type !== "admin" && user?.is_archived === false
                )
                .sort((a, b) => b?.id - a?.id),
            },
          });
        })
        .catch((error) => {
          console.log("error admins", error?.response);
        });
      getData("adminuser", token)
        .then((response) => {
          // console.log("adminuser", response);
          dispatch({
            type: "LIST_ADMINS",
            payload: {
              isLoading: false,
              data: response.results.sort((a, b) => b?.id - a?.id),
            },
          });
        })
        .catch((error) => {
          console.log("error admins", error?.response);
        });
      getData("contact", token)
        .then((response) => {
          dispatch({
            type: "LIST_CONTACTS",
            payload: {
              isLoading: false,
              data: response.results.sort((a, b) => b?.id - a?.id),
            },
          });
        })
        .catch((error) => {
          console.log("error contact", error?.response);
        });

      getData(`notificationbyadmin`, token)
        .then((response) => {
          // console.log("notications", response);
          dispatch({
            type: "LIST_NOTIFICATIONS",
            payload: {
              isLoading: false,
              data: response.results.sort((a, b) => b?.id - a?.id),
              noRead: response.results
                .filter((item) => item?.read === false)
                .sort((a, b) => b?.id - a?.id),
            },
          });
        })
        .catch((error) => {
          console.log("error notification", error?.response);
        });
      getData(`banner`, token)
        .then((response) => {
          // console.log("banner", response);
          dispatch({
            type: "LIST_BANNERS",
            payload: {
              isLoading: false,
              data: response.results.sort((a, b) => b?.id - a?.id),
            },
          });
        })
        .catch((error) => {
          console.log("error banner", error?.response);
        });
      getData(`marketing`, token)
        .then((response) => {
          // console.log("marketing", response);
          dispatch({
            type: "LIST_MARKETING",
            payload: {
              isLoading: false,
              data: response.results.sort((a, b) => b?.id - a?.id),
            },
          });
        })
        .catch((error) => {
          console.log("error banner", error?.response);
        });
    }
    // return () => {

    // }
  }, [token, auth]);

  React.useEffect(() => {
    if (notifications?.noRead?.length) {
      const groups = notifications?.noRead.reduce((groups, item) => {
        const key = moment(item?.created_at).format("DD/MM/YYYY");
        if (!groups[key]) {
          groups[key] = [];
        }
        groups[key].push(item);
        return groups;
      }, {});
      const data_grouped = Object.keys(groups).map((key) => {
        return {
          date: moment(new Date(key)).isSame(new Date(), "day")
            ? "Aujourd'hui"
            : moment().subtract(1, "days").format("DD/MM/YYYY") === key
            ? "Hier"
            : "Le " + key,
          data: groups[key]?.sort(
            (a, b) => new Date(b?.created_at) - new Date(a?.created_at)
          ),
        };
      });
      setNotifs(data_grouped);
      console.log("data_grouped", data_grouped);
    }
  }, [notifications]);

  var onShowAndHideNotification = () => {
    if (showNotifications === false) {
      setShowNotifications(true);
    } else {
      setShowNotifications(false);
    }
  };

  const onReadAll = () => {
    dispatch({
      type: "LIST_NOTIFICATIONS",
      payload: {
        isLoading: false,
        data: notifications?.data,
        noRead: [],
      },
    });
    setNotifs([]);
    setShowNotifications(false);
    notifications?.noRead.forEach((element) => {
      putData(`notification/${element?.id}/`, { read: true }, auth?.token)
        .then((resp) => {
          console.log("resp", resp);
        })
        .catch((error) => {
          console.log("error put notification", error?.response);
        });
    });
  };

  const onRead = (id) => {
    // setNotifs(notifs?.filter((item) => item?.date !== date))
    let data = notifications?.noRead?.filter((item) => item?.id !== id);
    dispatch({
      type: "LIST_NOTIFICATIONS",
      payload: {
        isLoading: false,
        data: notifications?.data,
        noRead: data,
      },
    });
    putData(`notification/${id}/`, { read: true }, auth?.token)
      .then((resp) => {
        console.log("resp", resp);
        getData(`notificationbyadmin`, token)
          .then((response) => {
            // console.log("notications", response);
            dispatch({
              type: "LIST_NOTIFICATIONS",
              payload: {
                isLoading: false,
                data: response.results.sort((a, b) => b?.id - a?.id),
                noRead: response.results
                  .filter((item) => item?.read === false)
                  .sort((a, b) => b?.id - a?.id),
              },
            });
          })
          .catch((error) => {
            console.log("error notification", error?.response);
          });
      })
      .catch((error) => {
        console.log("error put notification", error?.response);
      });
  };

  const onLogout = (e) => {
    e.preventDefault();
    dispatch({ type: "AUTH", payload: {} });
    window.sessionStorage.removeItem("userToken");
    window.sessionStorage.removeItem("userType");
    window.sessionStorage.clear();
    window.location = "/";
  };

  return (
    <>
      <nav className="empty-fluidy alling-header navbar navbar-expand-md navbar-dark fixed-top">
        <div className="headering">
          <div className="logo-item">
            <span className="logo-firsty">SPEERO</span>&nbsp;
            {/* <span className="logo-secondy">PM</span> */}
          </div>

          <div className="header-others-items">
            <div className="search-form-container">
              <div className="search-form-content">
                <input name="search" className="form-control search-input" />
                <label>
                  <IconlyPack.Search
                    set="light"
                    primaryColor="black"
                    className="search-icony"
                  />
                </label>
              </div>
            </div>

            <div
              className="notification-item-container linkable"
              onClick={() => onShowAndHideNotification()}
            >
              <div className="notification-item-content">
                <IconlyPack.Notification set="light" primaryColor="black" />
                <span className="notification-counter">
                  {notifications?.noRead?.length}
                </span>
              </div>
            </div>
            <div className="connected-user-container">
              <div className="connected-user-content ml-auto no-view-mobile">
                <div className="dropdown dis-flex">
                  <button
                    className="user-acount-dashboard-header"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="user-acount-dashboard-header-display">
                      <img
                        src={
                          auth?.user?.id
                            ? "https://ui-avatars.com/api/?name=" +
                              (auth?.user?.fullname).replace(/ /g, "+")
                            : userAvatar
                        }
                        width="40"
                        className="user-avatar"
                        alt="User Avatar"
                      />
                      <FeatherIcon
                        className="icon-user-acount-dashboard"
                        icon="chevron-down"
                      />
                    </div>
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-custom m-t-10"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <NavLink
                      type="button"
                      className="btn linkable"
                      to="/profil"
                    >
                      {auth?.user?.fullname}
                    </NavLink>
                    <div className="dropdown-divider"></div>
                    <span
                      className="dropdown-item item-dropdown-custom"
                      onClick={(e) => onLogout(e)}
                    >
                      {" "}
                      Deconnexion
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="hiddenable-nav-mobile-container no-view-desktop">
            {/* Navbar Mobile */}
            <div className="hiddenable-nav-mobile-content">
              <NavBarMobileV2 />
            </div>
          </div>

          {showNotifications && (
            <div className={"header-hiddenable-notification-section"}>
              <div className="header-hiddenable-notification-title">
                Notifications ({notifications?.noRead?.length})
              </div>
              <div className="header-notif justify-content-end">
                {notifs?.length ? (
                  <button
                    className="btn list-btn"
                    style={{ fontSize: 10 }}
                    onClick={() => onReadAll()}
                  >
                    Marquer tout comme lu
                  </button>
                ) : null}
              </div>
              <ul className="header-hiddenable-notification-ul">
                {notifs?.length ? (
                  notifs.map((notif) => (
                    <React.Fragment>
                      <div className="header-notif ml-2" key={notif?.date}>
                        <p className="text-notif">{notif?.date}</p>
                      </div>
                      {notif?.data?.map((item) => (
                        <li
                          className="header-hiddenable-notification-li"
                          onClick={() => onRead(item?.id)}
                        >
                          <NavLink
                            to={{
                              pathname: "details-evenement",
                              state: { event: item?.event },
                            }}
                            className="notification-item-link"
                            key={item?.id}
                          >
                            <div className="bloc-notif align-items-center">
                              <div className="d-flex">
                                <div>
                                  <img
                                    // src={ImgNotif}
                                    src={
                                      item?.event?.user?.avatar &&
                                      !item?.event?.user?.avatar?.includes(
                                        "default.png"
                                      )
                                        ? baseUrl + item?.event?.user?.avatar
                                        : ImgNotif
                                    }
                                    width="40"
                                    height="40"
                                    style={{ borderRadius: "100%" }}
                                    alt="image notification"
                                  />
                                  <div className="notif-recent ml-3 mt-2"></div>
                                </div>
                                <div>
                                  <p className="pl-3 pt-2">
                                    {item?.event?.user?.fullname}{" "}
                                    <span className="pl-2">
                                      {item?.message?.replace(
                                        item?.event?.user?.fullname,
                                        ""
                                      )}
                                    </span>
                                  </p>
                                </div>
                              </div>
                              <div>
                                <span>
                                  {moment(item?.created_at).format("hh:mm")}
                                </span>
                              </div>
                            </div>
                          </NavLink>
                        </li>
                      ))}
                    </React.Fragment>
                  ))
                ) : (
                  <li className="header-hiddenable-notification-li">
                    <p>Aucune Notification</p>
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
      </nav>
    </>
  );
};

export default Header;
