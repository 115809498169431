import React, { useEffect, useState } from "react";
import Header from "../../../Navs/Header";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";

import ImageLabel from "../../../../assets/images/image_label.png";
import DocsLabel from "../../../../assets/images/image_label.png";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import NavbarMobileV2 from "../../navbarMobileV2/navbarMobileV2";
import { NavLink } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import useCreateBannerForm from "./requestBanner/useCreateBannerForm";
registerLocale("fr", fr);

function AjoutBanner() {
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    setValue,
    reset,
    clearErrors,
  } = useCreateBannerForm();
  const [isValid, setIsValid] = React.useState(true);
  var [startDate, setStartDate] = React.useState(new Date());
  var [endDate, setEndDate] = React.useState(new Date());

  useEffect(() => {
    register("date_debut");
    setValue("date_debut", moment(startDate).format("YYYY-MM-DD"));
    register("date_fin");
    setValue("date_fin", moment(endDate).format("YYYY-MM-DD"));
  }, []);

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  const handleDateDebut = (date) => {
    setStartDate(date);
    register("date_debut");
    setValue("date_debut", moment(date).format("YYYY-MM-DD"));
    if (endDate && date > endDate) {
      setIsValid(false);
    }
  };

  const handleDateFin = (date) => {
    setEndDate(date);
    if (date < startDate) {
      setIsValid(false);
    } else {
      setIsValid(true);
      register("date_fin");
      setValue("date_fin", moment(date).format("YYYY-MM-DD"));
    }
  };

  const getUploadParams = ({ meta }) => {
    // console.log(typeof meta);
    return { url: "https://httpbin.org/post" };
  };
  const handleChangeStatus = ({ meta, file }, status) => {
    file.height = meta.height;
    // console.log(file);
  };
  const getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };
  const InputChooseFile = ({ accept, onFiles, files, getFilesFromEvent }) => {
    // console.log("files", files);
    const text = files.length === 0 && (
      // 'Ajouter encore des photos' :
      <div className="start-label">
        <div className="label-img-container">
          <img src={ImageLabel} className="label-image" alt="Label" />
        </div>
        <span className="first_label">
          Déposez une image ici ou sélectionnez un fichier.
        </span>
        <span className="second-label">
          Format acceptés : JPG, PNG, GIF, TIFF ou BMP, inférieurs à 2Mo.
        </span>
      </div>
    );
    let buttonStartStyle = {
      background: "rgb(255, 152, 0)",
      cursor: "pointer",
      padding: 15,
      borderRadius: 30,
      marginTop: 10,
      textAlign: "center",
      maxWidth: "30%",
      marginLeft: "35%",
      color: "#fff",
      fontWeight: 700,
    };

    if (files.length <= 0) {
      buttonStartStyle = {
        // color: '#ff9800',
        cursor: "pointer",
        padding: 15,
        borderRadius: 30,
        marginTop: 10,
        textAlign: "center",
      };
    }

    return (
      <div
        className="row upload-dropzone-row"
        onClick={() => window.$("#fileopen").click()}
      >
        <div style={buttonStartStyle} className="col-md-6 offset-md-3 d-block">
          {text}
          <input
            style={{ opacity: 0 }}
            className="file-input"
            type="file"
            accept={accept}
            id="fileopen"
            multiple
            onChange={(e) => {
              getFilesFromEvent(e).then((chosenFiles) => {
                onFiles(chosenFiles);
              });
            }}
          ></input>
        </div>
      </div>
    );
  };
  return (
    <div className="component-add-salarie">
      <div className="container-fluid body-background-dashboard">
        <Header />
        <div className="row">
          {/* Navbar Mobile */}
          <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
            <NavbarMobileV2 />
          </div>
        </div>
        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 beny">
            <div className="page-body-container">
              <div>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <NavLink to="/gestion-des-banniers" className="nav-prev">
                        Bannières
                      </NavLink>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Ajout d’une bannière
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="page-content-container">
                <div className="page-first-row-container pd-page">
                  <div className="page-title-container">
                    <h1 className="page-title">Ajout d’une bannière</h1>
                  </div>
                </div>
                <div className="parent-table-container">
                  <div className="pt-4">
                    <form onSubmit={onSubmit}>
                      <div className="row d-flex align-items-center">
                        <div className="col-xl-6 col-lg-6 col-md-6 pb-3">
                          <div className="form-group">
                            <label className="label-input">
                              Nom de la bannière{" "}
                            </label>
                            <input
                              name="nom"
                              {...register("nom")}
                              type="text"
                              className="bloc-div form-control"
                              placeholder="Nom de la bannière"
                            />
                          </div>
                          {formState.errors && formState.errors.nom && (
                            <div className="alert alert-danger gfa-alert-danger">
                              {formState.errors.nom?.message}
                            </div>
                          )}
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 pb-3">
                          <div className="form-group">
                            <label className="label-input">Position </label>
                            <input
                              name="position"
                              {...register("position")}
                              min={1}
                              type="number"
                              className="bloc-div form-control"
                              placeholder="position"
                            />
                          </div>
                          {formState.errors && formState.errors.position && (
                            <div className="alert alert-danger gfa-alert-danger">
                              {formState.errors.position?.message}
                            </div>
                          )}
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 pb-3">
                          <div className="form-group">
                            <label className="label-input" htmlFor="link">
                              Date de début
                            </label>
                            <DatePicker
                              className="form-control crud-form-control category-name-input"
                              selected={startDate}
                              placeholderText="Sélectionner une date"
                              dateFormat="dd/MM/yyyy"
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              locale="fr"
                              onChange={(date) => handleDateDebut(date)}
                            />
                          </div>
                          {formState?.errors &&
                            formState?.errors?.date_debut && (
                              <div className="alert alert-danger epm-alert-danger closer m-t-10">
                                {formState?.errors?.date_debut?.message}
                              </div>
                            )}
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 pb-3">
                          <div className="form-group">
                            <label className="label-input" htmlFor="link">
                              Date de fin
                            </label>
                            <DatePicker
                              className="form-control crud-form-control category-name-input"
                              selected={endDate}
                              placeholderText="Sélectionner une date"
                              dateFormat="dd/MM/yyyy"
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              locale="fr"
                              onChange={(date) => handleDateFin(date)}
                            />
                          </div>
                          {formState?.errors && formState?.errors?.date_fin && (
                            <div className="alert alert-danger epm-alert-danger closer m-t-10">
                              {formState?.errors?.date_fin?.message}
                            </div>
                          )}
                          {!isValid && (
                            <div className="alert alert-danger epm-alert-danger closer m-t-10">
                              La date de fin ne doit pas être inférieure à la
                              date de début.
                            </div>
                          )}
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 pb-3">
                          <div className="form-group">
                            <label className="label-input">Lien </label>
                            <input
                              name="lien"
                              {...register("lien")}
                              type="url"
                              className="bloc-div form-control"
                              placeholder="lien"
                            />
                          </div>
                          {formState.errors && formState.errors.lien && (
                            <div className="alert alert-danger gfa-alert-danger">
                              {formState.errors.lien?.message}
                            </div>
                          )}
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 pb-3">
                          <div className="form-group ">
                            <label className="label-input" htmlFor="statut">
                              Statut
                            </label>
                            <select
                              name="statut"
                              {...register("statut")}
                              className="form-control crud-form-control form-select  ml-md-auto filter-select-item select-crud-form"
                              aria-label="Default select example"
                            >
                              <option>Choisir un statut</option>
                              <option value="publie">Publiée</option>
                              <option value="non_publie">Non publiée</option>
                              {/* <option value="inactif">Inactif</option> */}
                            </select>
                            {formState?.errors && formState?.errors?.statut && (
                              <div className="alert alert-danger epm-alert-danger closer m-t-10">
                                {formState?.errors?.statut?.message}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12 py-3 w-full py-3">
                          <div className="form-group">
                            <label className="label-input">Image </label>
                            <div className="input-file">
                              <Dropzone
                                getUploadParams={getUploadParams}
                                onChangeStatus={handleChangeStatus}
                                // onSubmit={handleSubmit}
                                accept="image/*"
                                autoUpload
                                styles={{
                                  dropzoneReject: {
                                    borderColor: "#F19373",
                                    backgroundColor: "#F1BDAB",
                                  },
                                  inputLabel: (files, extra) =>
                                    extra.reject ? { color: "#A02800" } : {},
                                }}
                                maxFiles={1}
                                // inputWithFilesContent={(files) => {}}
                                inputWithFilesContent={(files) => {
                                  register("image");
                                  setValue("image", files[0]?.file);
                                }}
                                // inputContent={<span>Drop 3 Files</span>}
                                InputComponent={InputChooseFile}
                                getFilesFromEvent={getFilesFromEvent}
                                classNames
                              />
                            </div>
                            {formState.errors && formState.errors.image && (
                              <div className="alert alert-danger gfa-alert-danger">
                                {formState.errors.image?.message}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row my-5 d-flex justify-content-end">
                        <div className="col-xl-2 col-lg-2 col-md-2">
                          {!isSuccessfullySubmitted && (
                            <button className="btn-theme-plus">
                              ENREGISTRER
                            </button>
                          )}

                          {isSuccessfullySubmitted && (
                            <button className="btn-theme-plus">
                              En cours &nbsp;
                              <i className="fas fa-spin fa-spinner"></i>
                            </button>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjoutBanner;
