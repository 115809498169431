import React, { useContext, useEffect } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import GestionRoutesTableData from "./GestionRoutesTableData";
import GestionRoutesTableSkeleton from "./GestionRoutesTableSkeleton";

import * as IconlyPack from "react-iconly";
import { Link, NavLink } from "react-router-dom";
import { DataContext } from "../../../redux/GlobalState";
import Swal from "sweetalert2";
import { deleteData, getData } from "../../../utils/fetchData";
var GestionRoutesTable = () => {
  const { state, dispatch } = useContext(DataContext);
  const { routes } = state;
//   var [isPageLoading, setIsPageLoading] = React.useState(true);
  let token = window.sessionStorage.getItem("userToken");
  // eslint-disable-next-line
  //   var [routes, setRoutes] = React.useState(GestionRoutesTableData);


  const onDelete = (id) => {
    Swal.fire({
      title: "Êtes vous sûr de vouloir Supprimer ce point d’interêt?",
      icon: "warning",
      //   customClass: {
      //     confirmButton: "btn-theme-plus",
      //     closeButton: "btn btn-danger",
      //   },
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return deleteData(`route/${id}/`, token)
          .then((response) => {
            console.log("res", response);
            getData("route", token)
              .then((response) => {
                // console.log("response routes", response);
                dispatch({
                  type: "LIST_ROUTES",
                  payload: {
                    isLoading: false,
                    data: response.results.sort((a, b) => b?.id - a?.id),
                  },
                });
              })
              .catch((error) => {
                console.log("error routes", error?.response);
              });
            return response;
          })
          .catch((error) => {
            console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("res", result);
        Swal.fire({
          icon: "success",
          title: "Point d’interêt supprimé avec succés.",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };
  const selectRowProp = {
    mode: "checkbox",
  };

  const actionFormatter = (cell, row, id) => {
    return (
      <div className="table-actions-btn-container-assurance">
        <Link
          className="action-btn edit-btn"
          title="Traitement"
          to={{
            pathname: `/details-point-interet`,
            state: { route: row },
          }}
        >
          <IconlyPack.Show
            set="light"
            primaryColor="#27AE60"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target="#GenererFactureClientModal"
          />
        </Link>
        <NavLink
          to={{
            pathname: `/modifier-point-interet`,
            state: { route: row },
          }}
          className="action-btn edit-btn"
          title="Modifier"
        >
          <IconlyPack.Edit
            set="light"
            primaryColor="#2F80ED"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target="#GestionDevis"
          />
        </NavLink>
        <button className="action-btn edit-btn" title="Supprimer">
          <IconlyPack.Delete
            set="light"
            primaryColor="#EB5757"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target="#factureAssureur"
            onClick={() => onDelete(row?.id)}
          />
        </button>
      </div>
    );
  };
  const description = {
    description: "Lorem ipsum delo...",
  };
  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {routes?.isLoading && <GestionRoutesTableSkeleton />}
            {!routes?.isLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={routes?.data}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  selectRow={selectRowProp}
                  trClassName="table-listing-tr table-listing-tr-salaries-table"
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#356FC2",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#356FC2",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="100"
                    dataField="name"
                    filter={{
                      type: "TextFilter",
                    }}
                  >
                    Nom
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#356FC2",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="80"
                    dataField="description"
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                  >
                    Description
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#356FC2",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    width="100"
                    dataField="id"
                    filter={{
                      type: "TextFilter",
                      style: { visibility: "hidden" },
                    }}
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionRoutesTable;
