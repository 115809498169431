import React, { useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
// import { DataContext } from "../store/GlobalState";
import Login from "../Services/Login";
// import { getData } from "../utils/fetchData";
import toast from "react-hot-toast";
import { DataContext } from "../../redux/GlobalState";
import { getData } from "../../utils/fetchData";
import { useHistory } from "react-router";
import Swal from "sweetalert2";

function UseLoginForm() {
  const { state, dispatch } = useContext(DataContext);
  //   const { auth } = state;
  const history = useHistory();
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email("l'email n'est pas valide")
          .required("L'email est requis"),
        password: yup.string().required("Le mot de passe est requis."),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    watch,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmitForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);
    var request = Login.onLogin(formValues);

    await request
      .then((resp) => {
        // console.log('resp', resp)
        getData("meadmin", resp?.token)
          .then((res) => {
            // console.log('res', res)
            if (res?.data?.user_type === "admin") {
              window.sessionStorage.setItem("userToken", resp?.token);
              window.sessionStorage.setItem("dashboard", res?.data?.dashboard);
              window.sessionStorage.setItem("route", res?.data?.route);
              window.sessionStorage.setItem("direction", res?.data?.direction);
              window.sessionStorage.setItem("evenement", res?.data?.evenement);
              window.sessionStorage.setItem("banner", res?.data?.banner);
              window.sessionStorage.setItem(
                "list_users",
                res?.data?.list_users
              );
              window.sessionStorage.setItem(
                "list_admins",
                res?.data?.list_admins
              );
              window.sessionStorage.setItem("notif", res?.data?.notif);
              window.sessionStorage.setItem("contact", res?.data?.contact);
              window.sessionStorage.setItem("profil", res?.data?.profil);
              window.sessionStorage.setItem("userType", "admin");
              dispatch({
                type: "AUTH",
                payload: { token: resp?.token, user: res?.data },
              });
              //   toast.success("Connexion réussie!");
              //   history.push("/dashboard");
              // Swal.fire({
              //   icon: "success",
              //   title: "Connexion réussie!",
              //   showConfirmButton: false,
              //   timer: 1500,
              // });

              setTimeout(() => {
                // window.location = "/dashboard";
                if (res?.data?.dashboard) {
                  Swal.fire({
                    icon: "success",
                    title: "Connexion réussie!",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  window.location = "/dashboard";
                } else if (res?.data?.route) {
                  Swal.fire({
                    icon: "success",
                    title: "Connexion réussie!",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  window.location = "/gestion-des-routes";
                } else if (res?.data?.direction) {
                  Swal.fire({
                    icon: "success",
                    title: "Connexion réussie!",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  window.location = "/gestion-des-directions";
                } else if (res?.data?.evenement) {
                  Swal.fire({
                    icon: "success",
                    title: "Connexion réussie!",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  window.location = "/gestion-des-evenement";
                } else if (res?.data?.banner) {
                  Swal.fire({
                    icon: "success",
                    title: "Connexion réussie!",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  window.location = "/gestion-des-banniers";
                } else if (res?.data?.avis_commentaire) {
                  Swal.fire({
                    icon: "success",
                    title: "Connexion réussie!",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  window.location = "/gestion-avis-comment";
                } else if (res?.data?.list_users) {
                  Swal.fire({
                    icon: "success",
                    title: "Connexion réussie!",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  window.location = "/gestion-des-utilisateurs";
                } else if (res?.data?.list_admins) {
                  Swal.fire({
                    icon: "success",
                    title: "Connexion réussie!",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  window.location = "/parametre";
                } else if (res?.data?.notif) {
                  Swal.fire({
                    icon: "success",
                    title: "Connexion réussie!",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  window.location = "/gestion-marketing";
                } else if (res?.data?.contact) {
                  Swal.fire({
                    icon: "success",
                    title: "Connexion réussie!",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  window.location = "/gestion-des-contacts";
                } else if (res?.data?.profil) {
                  Swal.fire({
                    icon: "success",
                    title: "Connexion réussie!",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  window.location = "/profil";
                } else {
                  Swal.fire({
                    icon: "error",
                    title: "Connexion non authorisée!",
                    showConfirmButton: false,
                    timer: 3000,
                  });
                  dispatch({ type: "AUTH", payload: {} });
                  window.sessionStorage.removeItem("userToken");
                  window.sessionStorage.removeItem("userType");
                  window.sessionStorage.clear();
                }
              }, 1500);
            } else {
              //   toast.error("Connexion non authorisée!");
              Swal.fire({
                icon: "error",
                title: "Connexion non authorisée!",
                showConfirmButton: false,
                timer: 3000,
              });
            }
            setIsSuccessfullySubmitted(false);
          })
          .catch((err) => {
            setIsSuccessfullySubmitted(false);
            console.log("err", err, err?.response);
            //toast.error("This didn't work.")
            if (err?.response === undefined) {
              Swal.fire({
                icon: "error",
                title: "Connexion non authorisée!",
                showConfirmButton: false,
                timer: 3000,
              });
            }
          });
      })
      .catch((error) => {
        console.error("error", error, error?.response);
        if (error?.response?.data?.non_field_errors) {
          //   toast.error("Email ou mot de passe incorrect.");
          Swal.fire({
            icon: "error",
            title: "Email ou mot de passe incorrect.",
            showConfirmButton: false,
            timer: 3000,
          });
        } else if (error?.response?.data?.status === "failure") {
          //   toast.error(error?.response?.data);
          Swal.fire({
            icon: "error",
            title: error?.response?.data?.message,
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          //   toast.error("Veuillez vérifier votre connexion internet.");
          Swal.fire({
            icon: "error",
            title: "Veuillez vérifier votre connexion internet.",
            showConfirmButton: false,
            timer: 3000,
          });
        }
        setIsSuccessfullySubmitted(false);
      });
  };
  return {
    register,
    errors,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    watch,
    reset,
    setValue,
    onSubmit: handleSubmit(onSubmitForm),
  };
}

export default UseLoginForm;
