import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import api from './LineChart.css'

import ApexCharts from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { getDataByID } from "../../../utils/fetchData";
import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {fr} from "date-fns/locale";

registerLocale("fr", fr);
moment.updateLocale("fr", {
  week: {
    dow: 1, // Monday is the first day of the week.
  },
});

function LineChart() {
  var [date, setDate] = React.useState(new Date());
  var [stats, setStats] = React.useState([0, 0, 0, 0, 0, 0, 0]);
  let token = window.sessionStorage.getItem("userToken");

  useEffect(() => {
    if (token && date) {
      let count = [0, 0, 0, 0, 0, 0, 0];
      getDataByID("eventbyweek", moment(date).format("DD-MM-YYYY"), token)
        .then(async (response) => {
          if (response?.data?.length) {
            await response?.data.forEach((element) => {
              count[moment(element?.day).day() - 1] = element?.total;
            });
            // console.log("count", count);
          }

          await setStats(count);
        })
        .catch((error) => console.log("error", error?.response));
    }
    // return () => {
    //   cleanup
    // }
  }, [token, date]);

  const handleChangeDate = (date) => {
    setDate(date);
  };

  const series = [
    {
      name: "Événements",
      data: stats,
    },
  ];
  const options = {
    chart: {
      height: 350,
      type: "bar",
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },

    xaxis: {
      categories: ["LUN", "MAR", "MER", "JEU", "VEN", "SAM", "DIM"],
      position: "bottom",
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#034194",
            colorTo: "#034194",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
      },
    },
    title: {
      text: "Nombre de  événements  signalés",
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        fontFamily: "Roboto",
        color: "#263238",
      },
    },
  };
  return (
    <div className="chart-item-container">
      <div className="row mb-2">
        <div className="col-md-6 mb-2">
          {/* <h2 className="chart-item-title">Chiffre du jour - <span className="fw-300"> Nombre d'apprenants par jour </span> </h2> */}
        </div>
        <div className="col-xl-4 offset-xl-1 col-lg-4  col-md-5 col-sm-6 mb-2 dis-flex">
          {/* <select
            className="form-select form-select-line-chart ml-md-auto"
            aria-label="Default select example"
          >
            <option selected>Semaine du 01/01/2021</option>
            <option value="1">Semaine du 07/01/2021</option>
            <option value="2">Semaine du 14/01/2021</option>
            <option value="3">Semaine du 21/01/2021</option>
          </select> */}
          <DatePicker
            dateFormat="dd/MM/yyyy"
            maxDate={new Date()}
            className="form-select form-select-line-chart ml-md-auto"
            selected={date}
            onChange={(date) => handleChangeDate(date)}
            locale="fr"
          />
        </div>
      </div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={350}
        />
      </div>
    </div>
  );
}

export default LineChart;
