import React, { useContext, useEffect, useState } from "react";
import * as IconlyPack from "react-iconly";
import { Link, NavLink } from "react-router-dom";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import ImgDetailMessage from "../../../../images/others/default-profile-avatar.png";
import ImgDetailReponse from "../../../../images/profil.png";
import moment from "moment";
import { getData, getDataByID, postData, putData } from "../../../../utils/fetchData";
import { useForm } from "react-hook-form";
import Skeleton from "react-loading-skeleton";
import { DataContext } from "../../../../redux/GlobalState";

var DetailsMessage = ({ message, user }) => {
  const { dispatch } = useContext(DataContext);
  const { register, handleSubmit, formState, clearErrors, reset, setValue } =
    useForm();
  var [isPageLoading, setIsPageLoading] = React.useState(true);
  let token = window.sessionStorage.getItem("userToken");
  const [responses, setResponses] = useState([]);

  useEffect(() => {
    if (message?.id) {
      setResponses([]);
      register("message");
      setValue("message", message?.id);
      register("admin");
      setValue("admin", user?.id);
      getResponses(message?.id);
    }
  }, [message]);

  const getResponses = (id) => {
    getDataByID("responsebycontact", id, token)
      .then((response) => {
        console.log("response", response);
        setResponses(response.sort((a, b) => b?.id - a?.id));
      })
      .catch((error) => console.log("error", error?.response));
  };

  const onSubmit = (data, e) => {
    console.log("response", data);
    postData("responsecontact/", data, token)
      .then((response) => {
        console.log("response contact", response);
        setResponses((prevState) => {
          return {
            ...prevState,
            response,
          };
        });
        e.target.reset();
        getResponses(message?.id);

        reset();
      })
      .catch((error) => console.log("error", error?.response));
  };

  const handleChange = (e) => {
    console.log("status", e.target.value);
    if (e?.target?.value) {
      putData(`contact/${message?.id}/`, { status: e.target.value }, token).then(
        (response) => {
          console.log("response", response);
          getData("contact", token)
            .then((response) => {
              dispatch({
                type: "LIST_CONTACTS",
                payload: {
                  isLoading: false,
                  data: response.results.sort((a, b) => b?.id - a?.id),
                },
              });
            })
            .catch((error) => {
              console.log("error contact", error?.response);
            });
        }
      ).catch((error) => console.log("error", error?.response))
    }
  };

  return (
    <div className="dashboard-item-container">
      <div className="page-body-container">
        <div className="bg-white">
          <div>
            <div className="row border-bottom p-3 align-items-center">
              <div className="col-xl-1 col-lg-2 col-sm-1 col-md-3">
                {!message?.id && (
                  <Skeleton circle={true} width={45} height={45} />
                )}
                {message?.id && (
                  <img
                    src={ImgDetailMessage}
                    className="image-profil-entete pr-0"
                    alt="Image prfil detail message"
                  />
                )}
              </div>
              <div className="col-xl-10 col-lg-8 col-md-9 col-sm-10 pl-0">
                <div className="d-flex align-items-center">
                  <p className="profil-detail-message" style={{ fontSize: 15 }}>
                    {message?.id ? (
                      message?.email
                    ) : (
                      <Skeleton width={230} height={20} />
                    )}
                  </p>
                  <div className="statut-expediteur ml-3"></div>
                </div>
              </div>
              <div class="col-xl-1 col-lg-2 col-md-2 col-sm-1 pr-1">
                <div>
                  <div class="dropdown">
                    <button
                      class="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <MoreVertOutlinedIcon className="icon-like" />
                    </button>
                    <div
                      class="dropdown-menu status-contact"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <option
                        value="en_cours"
                        class="dropdown-item list-menu-comment"
                        onClick={(e) => handleChange(e)}
                      >
                        Mettre en cours
                      </option>
                      <option
                        value="traité"
                        class="dropdown-item list-menu-comment"
                        onClick={(e) => handleChange(e)}
                      >
                        Traiter
                      </option>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bloc-details pb-5">
              <div>
                <div className="d-flex align-items-center pb-2">
                  {!message?.id && (
                    <Skeleton circle={true} width={25} height={25} />
                  )}

                  {message?.id && (
                    <img
                      src={ImgDetailMessage}
                      className="img-expediteur"
                      alt="Image prfil detail message"
                    />
                  )}

                  <p className="profil-detail-message pl-3">
                    {message?.id ? (
                      message?.email
                    ) : (
                      <Skeleton width={230} height={20} />
                    )}
                  </p>
                  <span className="time-detail-message pl-3">
                    {message?.id ? (
                      moment(message?.created_at).format("hh:mm")
                    ) : (
                      <Skeleton width={30} height={20} />
                    )}
                  </span>
                </div>
                {message?.id ? (
                  <div className="bloc-message-recu p-3">
                    <p className="text-message-recu">{message?.message}</p>
                  </div>
                ) : (
                  <Skeleton width={`80%`} height={45} />
                )}
              </div>
              {message?.id ? (
                responses?.length ? (
                  responses
                    .slice(0, 2)
                    .reverse()
                    .map((response) => (
                      <div className="py-3 bloc-reponse" key={response?.id}>
                        <div className="bloc-message-envoyer p-3">
                          <p className="text-message-envoyer">
                            {response?.reponse}
                          </p>
                        </div>
                        <div className="pt-2">
                          <div className="row justify-content-end align-items-center">
                            <img
                              src={ImgDetailReponse}
                              className="img-expediteur"
                              alt="Image prfil detail message"
                            />
                            <p className="profil-detail-message pl-3">
                              {response?.admin?.fullname}
                            </p>
                            <span className="time-detail-message px-3">
                              {moment(response?.created_at).format("hh:mm")}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))
                ) : null
              ) : (
                <div className="py-3 bloc-reponse">
                  <Skeleton width={500} height={45} />
                  <div className="pt-2">
                    <div className="row justify-content-end align-items-center">
                      <Skeleton circle={true} width={25} height={25} />
                      <p className="profil-detail-message pl-3">
                        <Skeleton width={100} height={15} />
                      </p>
                      <span className="time-detail-message px-3">
                        <Skeleton width={30} height={15} />
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="p-3 border-top">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-xl-11 col-lg-10 col-md-9">
                    <input
                      className="input-send-messge form-control"
                      placeholder="Type a message..."
                      name="reponse"
                      {...register("reponse", {
                        required: "required",
                      })}
                    />
                  </div>
                  <div className="col-xl-1 col-lg-2 col-md-3">
                    <button className="btn">
                      <IconlyPack.Send set="bold" primaryColor="#272C96" />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsMessage;
