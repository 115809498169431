import { banner } from "./Endpoints";
import axios from "axios";

export default {
  async onPostBanner(data, config) {
    const response = await axios.post(banner.onPostBanner(), data, config);
    return response.data;
  },
  async onEditBanner(id, data, config) {
    const response = await axios.put(banner.onEditBanner(id), data, config);
    return response.data;
  },
  async onDeleteBanner(id, config) {
    const response = await axios.delete(banner.onDeleteBanner(id), config);
    return response.data;
  },
};
