import api from "../../Http/global-vars";

export const authenticate = {
  onLogin() {
    return `${api}auth/get-token/`;
  },
};

export const requestPassword = {
  onPostRequest() {
    return `${api}request-password-reset/`;
  },
  onPostReset() {
    return `${api}reset-password/`;
  },
  onChangePassword() {
    return `${api}changepassword/`;
  },
};

export const user = {
  onEditUser(id) {
    return `${api}user/${id}/`;
  },
  onPostAdmin() {
    return `${api}adminuser/`;
  },
  onEditAdmin(id) {
    return `${api}adminuser/${id}/`;
  },
  onDeleteAdmin(id) {
    return `${api}adminuser/${id}/`;
  },
  onPostResponsable() {
    return `${api}user/`;
  },
  onEditResponsable(id) {
    return `${api}user/${id}/`;
  },
  onDeleteResponsable(id) {
    return `${api}user/${id}/`;
  },
};

export const route = {
  onPostRoute() {
    return `${api}route/`;
  },
  onEditRoute(id) {
    return `${api}route/${id}/`;
  },
  onDeleteRoute(id) {
    return `${api}route/${id}/`;
  },
};

export const direction = {
  onPostDirection() {
    return `${api}direction/`;
  },
  onEditDirection(id) {
    return `${api}direction/${id}/`;
  },
  onDeleteDirection(id) {
    return `${api}direction/${id}/`;
  },
};

export const transversale = {
  onPost() {
    return `${api}transversale/`;
  },
  onEdit(id) {
    return `${api}transversale/${id}/`;
  },
  onDelete(id) {
    return `${api}transversale/${id}/`;
  },
};

export const banner = {
  onPostBanner() {
    return `${api}banner/`;
  },
  onEditBanner(id) {
    return `${api}banner/${id}/`;
  },
  onDeleteBanner(id) {
    return `${api}banner/${id}/`;
  },
};

export const content_endpoint = {
  onPostBanner() {
    return `${api}condition/`;
  },
  onEditBanner(id) {
    return `${api}condition/${id}/`;
  },
};

export const marketing = {
  onPostMarketing() {
    return `${api}marketing/`;
  },
  onEditMarketing(id) {
    return `${api}marketing/${id}/`;
  },
  onDeleteMarketing(id) {
    return `${api}marketing/${id}/`;
  },
};
