import React, { useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import UseEditAdmin from "../Backend/Speero/Parametres/requestAdmin/UseEditAdmin";

const EditAdminModal = ({ admin }) => {
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    setValue,
    reset,
    clearErrors,
  } = UseEditAdmin();

  const [phone, setPhone] = React.useState("");

  const handleSetPhone = (val) => {
    setPhone(val);
    if (val && val?.length > 5) {
      register("phone");
      setValue("phone", "+" + val);
    } else {
      setValue("phone", "");
    }
    // console.log("val", val);
  };

  useEffect(() => {
    if (admin?.id) {
      // console.log("admin", admin);
      register("fullname");
      setValue("fullname", admin?.fullname);
      register("email");
      setValue("email", admin?.email);
      register("phone");
      setValue("phone", admin?.phone);
      setPhone(admin?.phone);
      register("adresse");
      setValue("adresse", admin?.adresse);
      register("dashboard");
      setValue("dashboard", admin?.dashboard);
      register("route");
      setValue("route", admin?.route);
      register("direction");
      setValue("direction", admin?.direction);
      register("evenement");
      setValue("evenement", admin?.evenement);
      register("banner");
      setValue("banner", admin?.banner);
      register("list_users");
      setValue("list_users", admin?.list_users);
      register("list_admins");
      setValue("list_admins", admin?.list_admins);
      register("contact");
      setValue("contact", admin?.contact);
      register("profil");
      setValue("profil", admin?.profil);
      register("id");
      setValue("id", admin?.id);
    }
  }, [admin]);

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AddingFamilyModal">
            Modifier un administrateur
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={onSubmit}>
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label for="fullname">Nom Complet</label>
                    <input
                      type="text"
                      placeholder="Nom complet"
                      name="fullname"
                      {...register("fullname")}
                      className="form-control crud-form-control"
                      id="fullname"
                    />
                    {formState.errors && formState.errors.fullname && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.fullname?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label for="email">Email</label>
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      {...register("email")}
                      className="form-control crud-form-control"
                      id="email"
                    />
                    {formState.errors && formState.errors.email && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.email?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label for="telephone">Téléphone</label>
                    <PhoneInput
                      masks={{ sn: ".. ... .. .." }}
                      inputClass="form-control crud-form-control phone-edit"
                      country={"sn"}
                      // onlyCountries={['sn']}
                      inputProps={{
                        name: "telephone",
                        required: true,
                        autoFocus: true,
                      }}
                      // disableDropdown={true}
                      enableAreaCodes={true}
                      prefix="+"
                      value={phone}
                      countryCodeEditable={false}
                      onChange={(phone) => handleSetPhone(phone)}
                      inputStyle={{
                        width: "100% !important",
                        paddingBottom: "22px !important",
                        borderRadius: "10px !important",
                      }}
                    />
                    {formState.errors && formState.errors.phone && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.phone?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label for="last_name">Adresse</label>
                    <input
                      type="text"
                      placeholder="Adresse"
                      name="adresse"
                      {...register("adresse")}
                      className="form-control crud-form-control"
                      id="last_name"
                    />
                    {formState.errors && formState.errors.adresse && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.adresse?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-12 crud-col-item">
                  <div className="form-group crud-form-group ">
                    <label>Permissions</label>
                    <div className="row">
                      <div className="col-md-4 test">
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            name="dashboard"
                            {...register("dashboard")}
                            id={`dashboard${admin?.id}`}
                          />
                          <label
                            className="custom-control-label"
                            for={`dashboard${admin?.id}`}
                          >
                            Accés au tableau de bord
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4 test">
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            name="route"
                            {...register("route")}
                            id={`route${admin?.id}`}
                          />
                          <label
                            className="custom-control-label"
                            for={`route${admin?.id}`}
                          >
                            Accés aux points d'intérêts
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4 test">
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            name="direction"
                            {...register("direction")}
                            id={`direction${admin?.id}`}
                          />
                          <label
                            className="custom-control-label"
                            for={`direction${admin?.id}`}
                          >
                            Accés aux directions
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4 test">
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            name="evenement"
                            {...register("evenement")}
                            id={`evenement${admin?.id}`}
                          />
                          <label
                            className="custom-control-label"
                            for={`evenement${admin?.id}`}
                          >
                            Accés aux événements
                          </label>
                        </div>
                      </div>

                      <div className="col-md-4 test">
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            name="banner"
                            {...register("banner")}
                            id={`banner${admin?.id}`}
                          />
                          <label
                            className="custom-control-label"
                            for={`banner${admin?.id}`}
                          >
                            Accés aux bannieres
                          </label>
                        </div>
                      </div>

                      <div className="col-md-4 test">
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            name="list_users"
                            {...register("list_users")}
                            id={`users${admin?.id}`}
                          />
                          <label
                            className="custom-control-label"
                            for={`users${admin?.id}`}
                          >
                            Accés aux utilisateurs
                          </label>
                        </div>
                      </div>

                      <div className="col-md-4 test">
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            name="list_admins"
                            {...register("list_admins")}
                            id={`admins${admin?.id}`}
                          />
                          <label
                            className="custom-control-label"
                            for={`admins${admin?.id}`}
                          >
                            Accés aux administrateurs
                          </label>
                        </div>
                      </div>

                      <div className="col-md-4 test">
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            name="contact"
                            {...register("contact")}
                            id={`contact${admin?.id}`}
                          />
                          <label
                            className="custom-control-label"
                            for={`contact${admin?.id}`}
                          >
                            Accés aux messages
                          </label>
                        </div>
                      </div>

                      <div className="col-md-4 test">
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            name="profil"
                            {...register("profil")}
                            // defaultChecked={true}
                            id={`profil${admin?.id}`}
                          />
                          <label
                            className="custom-control-label"
                            for={`profil${admin?.id}`}
                          >
                            Accés à son profil
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn"
                    data-dismiss="modal"
                  >
                    Retour
                  </button>
                  {/* <button className="btn btn-theme-plus">Enregistrer</button> */}
                  {isSuccessfullySubmitted && (
                    <button className="btn-theme-plus" disabled>
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isSuccessfullySubmitted && (
                    <button className="btn-theme-plus">Enregistrer</button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAdminModal;
